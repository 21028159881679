import { AvatarPicker } from '@components/avatar-picker';
import { ButtonSmall } from '@components/buttons/button-small';
import { Loader } from '@components/loader';
import { TextFieldForm } from '@components/text-fields/text-field-form';
import { PROMISES_AREA } from '@constants/promises-area';
import { Space } from '@styles/components/space';
import { getProfileSettingsFieldNames } from './profile-settings-form.constants';
import { useProfileSettingsForm } from './profile-settings-form.state';
import { StyledProfileSettingsForm as Styled } from './profile-settings-form.styles';
import { IProfileSettingsFormProps, TField } from './profile-settings-form.typings';

export const ProfileSettingsForm: React.FC<IProfileSettingsFormProps> = ({ onSubmit }) => {
  const { form, user, onAvatarChange, countryOption, isSubmitting } = useProfileSettingsForm({ onSubmit });

  return (
    <Styled.Form onSubmit={form.handleSubmit}>
      <AvatarPicker {...form.getFieldProps('avatar')} onChange={onAvatarChange} />

      <Space gap={15} isAlignStart>
        {getProfileSettingsFieldNames(user).map(({ name, ...field }) => (
          <TextFieldForm
            key={name}
            isDisabled={field.isDisabled}
            value={form.values[name as TField]}
            onBlur={form.handleBlur}
            placeholder={field.placeholder}
            onChange={form.handleChange}
            field={{ touched: form.touched[name as TField], errorMessage: form.errors[name as TField] }}
            selectedCountry={countryOption}
            name={name}
            isPhoneNumber={name === 'phoneNumber'}
          />
        ))}
        <Space direction="horizontal">
          <ButtonSmall isSubmit isDisabled={!form.isValid || isSubmitting}>
            Save
          </ButtonSmall>
          <Loader area={PROMISES_AREA.changeProfile} isNotWrapper isWithoutMargin size={20} />
        </Space>
      </Space>
    </Styled.Form>
  );
};
