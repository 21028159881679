import { alertError } from '@constants/alerts';
import { setToken, setUserInfo } from '@store/reducers/auth';
import { AppDispatch } from '@store/store';
import { IUserResponse } from 'typings/api.typings';

export const GET_COUNTRY_ENDPOINT = 'https://ipapi.co/json';

export const getPhoneNumberWithoutSpace = (phoneNumber: string) => phoneNumber.replace(/[\s]/g, '');

export const getPhoneNumberWithSpace = (countryCode: string, phoneNumber: string) =>
  countryCode + ' ' + phoneNumber.replace(/ /g, '');

export const saveUser = ({ accessToken, refreshToken, user }: IUserResponse, dispatch: AppDispatch) => {
  if (!accessToken || !refreshToken) {
    return alertError('Something wrong');
  }
  dispatch(setUserInfo(user));
  dispatch(setToken({ accessToken, refreshToken }));
};
