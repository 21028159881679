import { Route, Routes } from 'react-router-dom';

import { Layout } from '@components/layout';
import { hideHeaderPaths, ROUTES } from '@constants/routes';
import { useAppDispatch } from '@hooks/redux';
import { Auth } from '@screens/auth/auth';
import { LogIn } from '@screens/auth/log-in';
import { LogInExecutor } from '@screens/auth/log-in/log-in-executor';
import {
    ExecutorAccounts
} from '@screens/auth/log-in/log-in-executor/executor-accounts/executor-accounts';
import { LogInUser } from '@screens/auth/log-in/log-in-user';
import { SignUp } from '@screens/auth/sign-up';
import { LifeScripts } from '@screens/life-scripts';
import { Page404 } from '@screens/page-404';
import { ProfileSettings } from '@screens/profile-settings';
import { Shared } from '@screens/shared';
import { InactiveLink } from '@screens/shared/inactive-link';
import { SharedLifeScript } from '@screens/shared/shared-life-script';
import { SharedMemory } from '@screens/shared/shared-memory';
import { setInterceptor } from '@services/api-service';
import { PrivateRoute } from './private-route';

export const AppRouter: React.FC = () => {
  setInterceptor(useAppDispatch());

  return (
    <Routes>
      <Route path={ROUTES.root} element={<Layout hideHeaderPaths={hideHeaderPaths} />}>
        <Route path={ROUTES.auth} element={<Auth />}>
          <Route path={ROUTES.logIn} element={<LogIn />}>
            <Route path={ROUTES.logInUser} element={<LogInUser />} />
            <Route path={ROUTES.logInExecutor} element={<LogInExecutor />} />
            <Route path={ROUTES.logInExecutorChooseAccount} element={<ExecutorAccounts />} />
          </Route>

          <Route path={ROUTES.signUp} element={<SignUp />} />
        </Route>

        <Route
          index
          element={
            <PrivateRoute>
              <LifeScripts />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.lifeScripts}
          element={
            <PrivateRoute>
              <LifeScripts />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.profileSettings}
          element={
            <PrivateRoute>
              <ProfileSettings />
            </PrivateRoute>
          }
        />

        <Route path={ROUTES.shared} element={<Shared />}>
          <Route path={ROUTES.sharedLifeScript} element={<SharedLifeScript />} />
          <Route path={ROUTES.sharedMemory} element={<SharedMemory />} />
        </Route>
        <Route path={ROUTES.inactiveLink} element={<InactiveLink />} />

        <Route path={ROUTES.default} element={<Page404 />} />
      </Route>
    </Routes>
  );
};
