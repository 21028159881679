import SuperEllipse from 'react-superellipse';

import { ButtonIcon } from '@components/buttons/button-icon';
import { Icon } from '@components/icon';
import { ShareLifeScriptModal } from '@components/share';
import { useFolder } from './folder.state';
import { StyledFolder as Styled } from './folder.styles';
import { IFolderProps } from './folder.typings';

export const Folder: React.FC<IFolderProps> = (props) => {
  const { folder } = props;
  const { folderRef, onFolderClick, activeFolder, isOpenShareModal, openShareModal, closeShareModal } =
    useFolder(props);

  const isActive = activeFolder.id === folder.id;

  return (
    <div>
      {isOpenShareModal && <ShareLifeScriptModal folder={folder} onClose={closeShareModal} />}
      <SuperEllipse p1={4} p2={30}>
        <Styled.Folder ref={folderRef} isActive={isActive} onClick={onFolderClick(folder.id)}>
          <div>
            <Icon type="folder" />
            <span>{folder.title}</span>
          </div>
          <div>
            <ButtonIcon icon="share" onClick={openShareModal} />
            <Icon type={isActive ? 'crossFilled' : 'forward'} />
          </div>
        </Styled.Folder>
      </SuperEllipse>
    </div>
  );
};
