import { fetchData } from '@services/api-service';
import { IGetShared } from './shared.typings';

export const getSharedLifeScript = (payload: IGetShared) => {
  return fetchData({
    requestUrl: 'getSharedLifeScript',
    payload,
  });
};

export const getSharedMemory = (payload: IGetShared) => {
  return fetchData({
    requestUrl: 'getSharedMemory',
    payload,
  });
};
