import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import {
    ITextFieldPhoneFormValues
} from '@components/text-fields/text-field-phone-form/text-field-phone-form.typings';
import { alertError, handleRequestError } from '@constants/alerts';
import { PROMISES_AREA } from '@constants/promises-area';
import { useAuthContext } from '@hooks/useAuthContext';
import { sendExecutorOTP } from '@screens/auth/auth.api';
import { getPhoneNumberWithoutSpace } from '@screens/auth/auth.constants';

export const useLogInExecutor = () => {
  const {
    country: [selectedCountry, setSelectedCountry],
    code: [isWaitingCode, setIsWaitingCode],
  } = useAuthContext();
  const [userPhoneNumber, setUserPhoneNumber] = useState('');

  const onLogInExecutor = async ({ phoneNumber }: ITextFieldPhoneFormValues) => {
    try {
      setUserPhoneNumber(phoneNumber);
      const isExecutoExist = await trackPromise(
        sendExecutorOTP({ phoneNumber: getPhoneNumberWithoutSpace(phoneNumber) }),
        PROMISES_AREA.auth
      );
      if (!isExecutoExist) {
        return alertError('Executor with this phone number not found');
      }
      setIsWaitingCode(true);
    } catch (error) {
      handleRequestError(error);
    }
  };

  return {
    isWaitingCode,
    onLogInExecutor,
    setSelectedCountry,
    userPhoneNumber,
    selectedCountry,
    setIsWaitingCode,
  };
};
