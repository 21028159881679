import { useRef, useState } from 'react';

export const useTableViewMemory = () => {
  const playerInitialState = {
    isPlaying: true,
    isOpen: false,
    isMuted: false,
    ref: useRef<HTMLVideoElement>(null),
  };

  const [isOpen, setIsOpen] = useState(false);
  const [videoInfo, setVideoInfo] = useState(playerInitialState);

  const openPlayer = () => setIsOpen(true);
  const colsePlayer = () => setIsOpen(false);
  const toggleMute = () => setVideoInfo((prev) => ({ ...prev, isMuted: !prev.isMuted }));
  const setIsPlayingModal = (isPlaying: boolean) => setVideoInfo((prev) => ({ ...prev, isPlaying }));

  return {
    isOpen,
    videoInfo,
    openPlayer,
    colsePlayer,
    toggleMute,
    setIsPlayingModal,
  };
};
