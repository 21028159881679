import { useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';

import { handleRequestError } from '@constants/alerts';
import { PROMISES_AREA } from '@constants/promises-area';
import { useAppDispatch } from '@hooks/redux';
import { useAuth } from '@hooks/useAuth';
import { setUserInfo } from '@store/reducers/auth';
import { updateUser } from './profile-settings.api';

export const useProfileSettings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();

  const onSaveProfile = async (payload: FormData) => {
    try {
      const updatedUser = await trackPromise(updateUser(payload), PROMISES_AREA.changeProfile);
      dispatch(
        setUserInfo({
          ...user,
          firstName: updatedUser.firstName,
          lastName: updatedUser.lastName,
          avatar: updatedUser.avatar,
        })
      );
    } catch (error) {
      handleRequestError(error);
    }
  };

  useEffect(() => {
    user.isExecutor && goToPrevPage();
  }, []);

  const goToPrevPage = () => navigate(-1);

  return {
    isExecutor: user.isExecutor,
    goToPrevPage,
    onSaveProfile,
  };
};
