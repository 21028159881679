import { ILifeScriptFolder } from '@components/life-script-folders/life-script-folders.typings';
import { IMemoriesPreview } from '@components/memories/memories.typings';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMemoriesMeta } from 'typings/api.typings';
import { lifeScriptsInitialState } from './life-scripts.constants';
import { ISetIsItemLinkActivePayload, IState } from './life-scripts.typings';

export const lifeScriptsSlice = createSlice({
  name: 'lifeScripts',
  initialState: lifeScriptsInitialState,
  reducers: {
    setMemories: (state, action: PayloadAction<IState<IMemoriesPreview, IMemoriesMeta>>) => {
      state.memories = action.payload;
    },
    setLifeScripts: (state, action: PayloadAction<ILifeScriptFolder[]>) => {
      state.lifeScripts = action.payload;
    },
    clearMemories: (state) => {
      state.memories = lifeScriptsInitialState.memories;
    },
    setIsNeedToReverseScroll: (state, action: PayloadAction<boolean>) => {
      state.isNeedToReverseScroll = action.payload;
    },
    setSearchedMemoryPage: (state, action: PayloadAction<number>) => {
      state.searchedMemoryPage = action.payload;
    },
    setIsMemoryLinkActive: (state, action: PayloadAction<ISetIsItemLinkActivePayload>) => {
      const memoryIndex = state.memories.items.findIndex((memory) => memory.id === action.payload.id);
      state.memories.items[memoryIndex].isLinkActive = action.payload.isLinkActive;
    },
    setIsLifeScriptLinkActive: (state, action: PayloadAction<ISetIsItemLinkActivePayload>) => {
      const lifeScriptsIndex = state.lifeScripts.findIndex((lifeScript) => lifeScript.id === action.payload.id);
      state.lifeScripts[lifeScriptsIndex].isLinkActive = action.payload.isLinkActive;
    },
    clearLifeScripts: () => {
      return lifeScriptsInitialState;
    },
  },
});

const { actions, reducer } = lifeScriptsSlice;
export const {
  setMemories,
  clearMemories,
  setIsNeedToReverseScroll,
  setSearchedMemoryPage,
  setIsMemoryLinkActive,
  setLifeScripts,
  clearLifeScripts,
  setIsLifeScriptLinkActive,
} = actions;
export { reducer as lifeScriptsReducer };
