import styled from 'styled-components';

import { StyledButton } from '@styles/components/button';
import { IButtonSmallProps } from './button-small.typings';

export const StyledButtonSmall = styled(StyledButton)<IButtonSmallProps>`
  border: ${(props) => (props.isBordered ? '1px solid #7c7c7c' : 'none')};
  width: ${(props) => (props.isBordered ? '110px' : '86px')};
  color: ${(props) => (props.isBordered ? '#989898' : '#000')};
  background-color: ${(props) => (props.isBordered ? 'transparent' : '#fff')};
  letter-spacing: 0.2352px;
  height: 45px;
  font-weight: 500;
  border-radius: 12px;
`;
