import * as Yup from 'yup';

import { stringRequiredMatches } from '@constants/errors';

import { IUserInfo } from '@store/reducers/auth/auth.typings';
import { IProfileSettingsForm } from './profile-settings-form.typings';

export const getProfileSettingsInitialValues = (
  user: IUserInfo,
  phoneNumberWithoutCode: string
): IProfileSettingsForm => ({
  avatar: user.avatar,
  email: user.email!,
  firstName: user.firstName,
  lastName: user.lastName,
  phoneNumber: phoneNumberWithoutCode,
});

export const getProfileSettingsFieldNames = (user: IUserInfo) => {
  const fields = [
    { name: 'firstName', isDisabled: false, placeholder: 'First Name' },
    { name: 'lastName', isDisabled: false, placeholder: 'Last Name' },
  ];

  let index = 2;
  if (user.phoneNumber) {
    fields.splice(index, 0, { name: 'phoneNumber', isDisabled: true, placeholder: 'Phone Number' });
    index += 1;
  }

  if (user.email) {
    fields.splice(index, 0, { name: 'email', isDisabled: true, placeholder: 'Email' });
  }

  return fields;
};

export const profileSettingsValidationSchema = Yup.object({
  firstName: stringRequiredMatches('firstName'),
  lastName: stringRequiredMatches('lastName'),
  avatar: Yup.string(),
});

export const getPayload = (profileSettingsInfo: IProfileSettingsForm, avatarFile?: File) => {
  const payload = new FormData();
  avatarFile && payload.append('avatar', avatarFile);
  payload.append('firstName', profileSettingsInfo.firstName);
  payload.append('lastName', profileSettingsInfo.lastName);
  return payload;
};
