import { Dispatch, SetStateAction } from 'react';
import { useOutletContext } from 'react-router-dom';

interface IShareContext {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
}

export const useShareContext = () => {
  return useOutletContext<IShareContext>();
};
