import styled from 'styled-components';

import { media } from '@styles/media';

export const StyledProfileSettingsForm = {
  Form: styled.form`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    gap: 40px;

    ${media.md1} {
      gap: 20px;
    }
  `,
};
