import styled from 'styled-components';

import { media } from '@styles/media';

export const StyledAuthSocialButtons = {
  Social: styled.div`
    margin: 21px 0 20px;

    h4 {
      text-align: center;
      margin-bottom: 47px;
      ${media.md3} {
        margin-bottom: 17px;
      }
    }
  `,

  SocialWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;

    button {
      text-transform: capitalize;
    }
  `,
};
