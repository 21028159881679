import styled, { css } from 'styled-components';

export const StyledSwitch = {
  ToggleSwitch: styled.label`
    position: relative;
    display: inline-block;
    width: 41px;
    height: 13px;
    input[type='checkbox'] {
      display: none;
    }

    input[type='checkbox']:checked + span {
      background-color: #4f5e51;
    }
  `,

  LoaderWrapper: styled.div`
    position: relative;
    width: 21px;
    height: 21px;
    & > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-47%, -50%);
    }
  `,

  Circle: styled.div<{ isChecked: boolean; isDisabled?: boolean }>`
    position: absolute;
    left: 0px;
    top: -4px;
    width: 21px;
    height: 21px;
    background-color: #717171;
    border-radius: 50%;
    transition: transform 0.3s ease;
    cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};

    & > div {
      cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
    }

    ${(props) =>
      props.isChecked &&
      css`
        transform: translateX(20px);
        background-color: #76a77d;
      `}
  `,

  Switch: styled.span<{ isDisabled?: boolean }>`
    position: absolute;
    cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
    background-color: #2c2c2c;
    border-radius: 50px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  `,
};
