import styled from 'styled-components';

import { media } from '@styles/media';

export const StyledLogInExecutor = {
  Page: styled.div`
    max-width: 324px;
    margin: 0 auto;
    height: 60vh;
  `,

  Title: styled.h1`
    font-weight: 700;
    font-size: 36px;
    letter-spacing: 0.28px;
    margin: 50px 0 30px;

    ${media.mmd0} {
      margin: 100px 0 50px;
    }

    ${media.md3} {
      margin: 60px 0 30px;
      font-size: 30px;
    }
  `,

  PhoneNumber: styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.28px;
    color: #9a9a9a;
    margin-bottom: 14px;
  `,
};
