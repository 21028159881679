import { Dispatch, SetStateAction } from 'react';
import { useOutletContext } from 'react-router-dom';

import { IOption } from 'typings/common';

interface IAuthContext {
  country: [IOption, Dispatch<SetStateAction<IOption>>];
  code: [boolean, Dispatch<SetStateAction<boolean>>];
}

export const useAuthContext = () => {
  return useOutletContext<IAuthContext>();
};
