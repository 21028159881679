import { alertError } from '@constants/alerts';
import {
    fetchSignInMethodsForEmail, linkWithCredential, OAuthProvider, signInWithPopup
} from '@firebase/auth';
import { appleProvider, auth, facebookProvider, googleProvider } from '@screens/auth/auth-config';
import { FirebaseError } from 'firebase/app';
import { IAuthProvider, ISocialButton, TLogIn } from './auth-social-buttons.typings';

export const socialType = {
  [facebookProvider.providerId]: 'facebook',
  [appleProvider.providerId]: 'apple',
  [googleProvider.providerId]: 'google',
} as const;

export const provider = {
  facebook: facebookProvider,
  apple: appleProvider,
  google: googleProvider,
};

export const socialButtons: ISocialButton[] = [
  { type: 'google', color: 'white' },
  { type: 'facebook', color: 'blue' },
  { type: 'apple' },
];

export const CREDENTIALS_ERROR = 'auth/account-exists-with-different-credential';
export const POPUP_CLOSED = 'auth/popup-closed-by-user';
export const CANCELLED_POPUP_REQUEST = 'auth/cancelled-popup-request';
export const PROVIDER_IS_NOT_SUPPORTED = "Your account is linked to a provider that isn't supported.";

export const linkUserByErrorToOtherSocial = async (error: FirebaseError) => {
  const credential = OAuthProvider.credentialFromError(error)!;
  const email = error.customData?.email as string;

  if (error && email && error.code === CREDENTIALS_ERROR) {
    const providers = (await fetchSignInMethodsForEmail(auth, email)) as TLogIn[];
    const firstPopupProviderMethod = providers.find((p) => Object.keys(socialType).includes(p));

    if (!firstPopupProviderMethod) {
      return alertError(PROVIDER_IS_NOT_SUPPORTED);
    }

    const linkedProvider = socialType[firstPopupProviderMethod];
    const currentProvider: IAuthProvider = provider[linkedProvider];
    currentProvider.setCustomParameters({ login_hint: email });

    const userCredential = await signInWithPopup(auth, provider.google);
    linkWithCredential(userCredential.user, credential);

    return userCredential;
  }
};
