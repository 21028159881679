import SelectSearch, { DomProps, SelectedOption, ValueProps } from 'react-select-search';
import SuperEllipse from 'react-superellipse';

import { Icon } from '@components/icon';
import 'react-select-search/style.css';
import { useSearchBar } from './search-bar.state';
import { StyledSearchBar as Styled } from './search-bar.styles';
import { ISearchBarProps, ISearchOption } from './search-bar.typings';

export const SearchBar: React.FC<ISearchBarProps> = () => {
  const {
    searchTerm,
    isFocus,
    isSearching,
    isWithResult,
    onSearchedMemoryClick,
    isWithText,
    resetSearchBarState,
    onSearchClick,
    filterByQuery,
    optionsMemories,
    isBlockedForNothingFound, //the same as isSearching, but by default it is true (for first render)
  } = useSearchBar();

  const isWithoutNothingFound = isWithResult || isBlockedForNothingFound || !searchTerm || optionsMemories.length;

  const renderItem = (domProps: DomProps, optionProps: SelectedOption) => {
    const option = optionProps as ISearchOption;

    return (
      <SuperEllipse p1={1} p2={30} onClick={onSearchedMemoryClick(option)}>
        <Styled.Item>
          <SuperEllipse p1={1} p2={13}>
            <img src={option.posterUrl} alt="avatar" />
          </SuperEllipse>
          <span>{option.name}</span>
        </Styled.Item>
      </SuperEllipse>
    );
  };

  const renderInput = (props: ValueProps) => {
    //eslint-disable-next-line
    const { tabIndex, onKeyDown, onKeyPress, onKeyUp, onMouseDown, ...restProps } = props;
    return <Styled.Input {...restProps} onBlur={resetSearchBarState} onClick={onSearchClick} />;
  };

  const getEmptyMessage = () => {
    if (isWithoutNothingFound) {
      return;
    }
    return 'no results';
  };

  return (
    <Styled.SearchBar isFocus={isFocus} isWithResult={isWithResult || !isWithoutNothingFound} isWithText={isWithText}>
      <Icon type="search" />

      <SelectSearch
        placeholder={isFocus ? 'Type here...' : 'Search'}
        printOptions={isFocus ? 'always' : 'never'}
        renderValue={renderInput}
        renderOption={renderItem}
        emptyMessage={getEmptyMessage()}
        filterOptions={filterByQuery}
        options={optionsMemories}
        closeOnSelect
        search
      />

      <Styled.Loader isLoading={isSearching} isNotWrapper isWithoutArea size={16} />
    </Styled.SearchBar>
  );
};
