import styled from 'styled-components';

import { ButtonIcon } from '@components/buttons/button-icon';

export const StyledShareVideoModal = {
  Inner: styled.div`
    position: relative;
    background-color: #1a1a1a;
    padding: 36px 25px 50px 35px;
    color: #989898;
    width: 427px;
  `,

  CloseModal: styled(ButtonIcon)`
    height: 15px;
    position: absolute;
    svg {
      fill: #7c7c7c;
    }
    top: 31px;
    right: 33px;
  `,

  Header: styled.div`
    margin-bottom: 30px;
    h5 {
      text-align: center;
      align-self: flex-end;
      font-size: 16px;
    }
  `,

  SocialButton: styled.div`
    cursor: pointer;

    svg {
      height: 21px;
      transition: fill ${(props) => props.theme.transition};
      fill: #7c7c7c;
      &:hover {
        fill: #fff;
      }
    }
  `,

  Footer: styled.div`
    margin-bottom: 34px;
    height: 45px;
    display: flex;
    justify-content: space-between;
  `,

  SwitchActiveLink: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    label {
      margin-top: 5px;
    }

    p {
      white-space: nowrap;
      font-weight: 400;
      font-size: 11px;
      line-height: 150%;
    }
  `,

  SocialLink: styled.div`
    position: relative;
    background: #2a2a2a;
    border-radius: 12px;
    width: 274px;
    height: 45px;
    display: flex;
    align-items: center;
    overflow: hidden;
    box-shadow: 10px;
    -webkit-font-smoothing: subpixel-antialiased;
    backface-visibility: hidden;
    transform: translateZ(0);

    span {
      white-space: nowrap;
      margin-left: 15px;
      overflow: hidden;
      width: 70%;
      padding: 2px 0;

      &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        left: -75px;
        top: 0;
        background: linear-gradient(
          90deg,
          rgba(0, 212, 255, 0) 0%,
          rgba(42, 42, 42, 0.7936216723017332) 59%,
          rgba(42, 42, 42, 1) 94%
        );
      }
    }

    div {
      margin-top: 1px;
      background-color: #2a2a2a;
      font-weight: 500;
      font-size: 15px;
      cursor: pointer;
      height: 100%;
      width: 100px;
      display: flex;
      align-items: center;
      text-align: left;
      position: absolute;
      right: 0;
      top: 0;
      padding-left: 5px;
      transition: color ${(prop) => prop.theme.transition};
      &:hover {
        color: #fff;
      }
    }
  `,

  SocialWrapper: styled.div`
    span {
      line-height: 150%;
    }
  `,

  SocialButtons: styled.div`
    margin-top: 17px;
    display: flex;
    align-items: center;
    gap: 19px;
  `,
};
