import { useEffect, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';

import { fetchFolders, fetchMemories } from '@components/memories/memories.constants';
import { IMemoriesPayload, IMemoriesPreview } from '@components/memories/memories.typings';
import { PROMISES_AREA } from '@constants/promises-area';
import { ROUTES } from '@constants/routes';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useMatchMedia } from '@hooks/useMatchMedia';
import { useScrollbarWidth } from '@hooks/useScrollbarWidth';
import { setAllLifeScriptsAndMemories } from '@store/reducers/app';
import { setUserInfo } from '@store/reducers/auth';
import {
    clearMemories, setLifeScripts, setMemories, setSearchedMemoryPage
} from '@store/reducers/life-scripts';
import { getAllLifeScriptsAndMemories, getUser } from './life-scripts.api';
import { IGetMemoriesProps } from './life-scripts.typings';
import { useLifeScriptsLocationState } from './use-life-scripts-location-state';

export const useLifeScripts = () => {
  const {
    lifeScripts: { memories, isNeedToReverseScroll, searchedMemoryPage, lifeScripts: lifeScriptsFolders },
    app: { activeFolder },
    auth: { isSignedInBySocial },
  } = useAppSelector((store) => store);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isNewUserModalOpen, setIsNewUserModalOpen, isExecutorModalOpen, setIsExecutorModalOpen } =
    useLifeScriptsLocationState();

  const [isFetching, setIsFetching] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const onCloseNewUserModal = () => {
    setIsNewUserModalOpen(!isNewUserModalOpen);
    navigate(ROUTES.lifeScripts, { state: null });
  };

  const onCloseExecutorModal = () => {
    setIsExecutorModalOpen(!isExecutorModalOpen);
    navigate(ROUTES.lifeScripts, { state: null });
  };

  const getMemories = async (props?: IGetMemoriesProps) => {
    const isReversedScrolling = !!props?.page;
    let currentPayload: IMemoriesPayload = { page: props?.page || memories.page };
    if (activeFolder?.id) {
      currentPayload = { ...currentPayload, lifeScriptId: activeFolder.id };
    }

    let firstMemory: Element | null = null;
    if (isReversedScrolling) {
      firstMemory = document.querySelector('#firstMemory');
    }

    const receivedMemories = await fetchMemories(currentPayload);

    if (receivedMemories) {
      const currentItems: IMemoriesPreview[] = isReversedScrolling
        ? [...receivedMemories.items, ...memories.items]
        : [...memories.items, ...receivedMemories.items];
      dispatch(
        setMemories({
          page: isReversedScrolling ? memories.page : memories.page + 1,
          items: currentItems,
          meta: receivedMemories.meta,
        })
      );
    }

    if (isReversedScrolling) {
      setTimeout(() => {
        const container = containerRef!.current!;
        const top = firstMemory!.getBoundingClientRect().top - 111;
        container.scrollTo({ top });
      }, 0);
    }
  };

  useEffect(() => {
    if (memories.items.length) {
      setTimeout(() => {
        const videoObsorver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            const video = entry.target as HTMLVideoElement;
            if (video.currentTime === 0) {
              return;
            }
            if (entry.isIntersecting || entry.intersectionRatio <= 0.2) {
              video.pause();
            }
          });
        });

        const videos = document.querySelectorAll('video');
        videos.forEach((video) => videoObsorver.observe(video));
      }, 100);
    }
  }, [memories]);

  const getFolders = async () => {
    const receivedLifeScripts = await fetchFolders();
    if (receivedLifeScripts) {
      dispatch(setLifeScripts(receivedLifeScripts));
    }
  };

  const fetchLifeScriptsData = async () => {
    const allLifeScriptsAndMemories = await getAllLifeScriptsAndMemories();
    dispatch(setAllLifeScriptsAndMemories(allLifeScriptsAndMemories));
    const user = await getUser();
    dispatch(setUserInfo(user));
    await getFolders();
    await getMemories();
  };

  const fetchAll = async () => {
    setIsFetching(true);
    await trackPromise(fetchLifeScriptsData(), PROMISES_AREA.fetchLifeScriptsData);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchAll();
    return () => {
      dispatch(clearMemories());
    };
  }, []);

  const fetchMoreMemories = () => {
    getMemories();
  };

  const handleScrollMemoriesTop = async (e: Event) => {
    const prevPage = searchedMemoryPage - 1;
    const { target } = e;

    if (
      target &&
      isNeedToReverseScroll &&
      prevPage >= 1 &&
      memories.meta.hasPreviousPage &&
      (target as HTMLDivElement).scrollTop === 0
    ) {
      dispatch(setSearchedMemoryPage(prevPage));
      await trackPromise(getMemories({ page: prevPage }), PROMISES_AREA.fetchMemoriesScrollTop);
    }
  };

  return {
    constants: {
      lifeScriptsFolders,
      memories,
      isFetching,
      containerRef,
      isOpenNewUserModal: isNewUserModalOpen && !isFetching && !isSignedInBySocial,
      isOpenExecutorModal: isExecutorModalOpen && !isFetching,
      isFoldersReceived: !!lifeScriptsFolders?.length && !isFetching,
      isMemoriesReceived: !!memories.items?.length && !isFetching,
      selectedView: useAppSelector((store) => store.app.selectedView),
      isTablet: useMatchMedia().isTablet,
      scrollbarWidth: useScrollbarWidth(),
    },
    func: {
      fetchMoreMemories,
      handleScrollMemoriesTop,
      onCloseNewUserModal,
      onCloseExecutorModal,
    },
  };
};
