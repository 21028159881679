import styled, { css } from 'styled-components';

import { Logo } from '@components/logo';
import { media } from '@styles/media';

const btnStyles = css`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  cursor: pointer;

  svg {
    margin-right: 9px;
  }
  transition: filter ${(props) => props.theme.transition};
  &:hover {
    filter: brightness(0.7);
  }
  width: auto;
  border-radius: 14px;
  padding: 17px 24px;

  ${media.md5} {
    width: 100%;
    justify-content: center;
  }
`;

export const StyledShared = {
  Header: styled.div`
    display: flex;
    column-gap: 40px;
    justify-content: space-between;
    align-items: center;
    margin-top: 21px;
    margin-bottom: 39px;

    @media (max-width: 465px) {
      flex-direction: column;
      row-gap: 20px;
    }
  `,

  ButtonsWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    ${media.mmd5} {
      flex-basis: calc(50% - 20px);
    }
    ${media.md5} {
      row-gap: 16px;
      flex-wrap: wrap;
    }
  `,

  Logo: styled(Logo)`
    justify-self: start;
    margin: 0;
    span {
      font-size: 20px;
      line-height: 15.5px;
    }
  `,

  Link: styled.a`
    ${btnStyles}
    background-color: #1A1A1A;
    color: #fff;
  `,

  Button: styled.button`
    ${btnStyles}
    background-color: #fff;
    color: #000;
  `,

  SharedBy: styled.p`
    margin-top: 45px;
    margin-bottom: 22px;
    font-size: 14px;
    text-align: center;

    span {
      font-weight: bold;
    }
  `,
};
