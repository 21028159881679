import { trackPromise } from 'react-promise-tracker';

import { getErrorData } from '@constants/errors';
import { TPromisesArea } from '@constants/promises-area';
import { getFolders, getMemories } from './memories.api';
import { IMemoriesPayload } from './memories.typings';

export const fetchMemories = async (payload: IMemoriesPayload, area?: TPromisesArea) => {
  try {
    const res = await trackPromise(getMemories(payload), area);
    return res;
  } catch (error) {
    console.error(getErrorData(error));
  }
};

export const fetchFolders = async (area?: TPromisesArea) => {
  try {
    const res = await trackPromise(getFolders(), area);
    return res;
  } catch (error) {
    console.error(getErrorData(error));
  }
};
