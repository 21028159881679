import { useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import { handleRequestError } from '@constants/alerts';
import { PROMISES_AREA } from '@constants/promises-area';
import { useAppDispatch } from '@hooks/redux';
import { useAuth } from '@hooks/useAuth';
import useOnClickOutside from '@hooks/useOnClickOutside';
import { auth } from '@screens/auth/auth-config';
import { clearApp } from '@store/reducers/app';
import { logOut } from '@store/reducers/auth';
import { clearLifeScripts } from '@store/reducers/life-scripts';
import { signOut } from './profile-dropdown.api';
import { IProfileDropdownProps } from './profile-dropdown.typings';

export const useProfileDropdown = ({ isMobile, toggleDrawer }: IProfileDropdownProps) => {
  const [isOpen, setIsOpen] = useState(!!isMobile);
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const { user } = useAuth();

  const clearAppData = () => {
    dispatch(logOut());
    dispatch(clearApp());
    dispatch(clearLifeScripts());
  };

  useOnClickOutside(ref, () => {
    if (!isMobile) {
      setIsOpen(false);
    }
  });

  const toggleOpen = () => {
    toggleDrawer && toggleDrawer();
    if (!isMobile) {
      setIsOpen(!isOpen);
    }
  };

  const setActiveClassName = ({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '');

  const onSignOut = async () => {
    try {
      await trackPromise(signOut(), PROMISES_AREA.logOut);
      clearAppData();
      await auth.signOut();
    } catch (e) {
      clearAppData();
      handleRequestError(e);
    }
  };

  return {
    isOpen,
    ref,
    user,
    toggleOpen,
    onSignOut,
    setActiveClassName,
  };
};
