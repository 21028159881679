export const LINKS = {
  helpCenter:
    "https://digilooms.wixanswers.com/apps/widget/v1/digilooms/8acd496c-8865-4c27-b45c-10e44c09a6cf/view/en",
  privacyPolicy:
    "https://www.termsfeed.com/live/4bf35d5c-81bd-48b9-9370-c5a7662bb224",
  termsOfUse:
    "https://www.termsfeed.com/live/7dbfe01f-0dfe-479d-986f-4b3c47e7265d",
  defaultAvatar: "https://images2.imgbox.com/f5/16/STlfV3Fa_o.png",
  appStore: "https://apps.apple.com/ua/app/digilooms/id1577939335?l=ru",
  googlePlay: "https://play.google.com/store/apps/details?id=com.digilooms",
  logo: "https://i.postimg.cc/kMpzQ0RJ/Group-2461.png",
  timecapsule: "https://www.digilooms.com/",
  functionality: "https://www.digilooms.com/functions",
  aboutDigilooms: "https://www.digilooms.com/about-4",
  digiKnow: "https://www.digilooms.com/digi",
  stories: "",
  GDPR: "https://www.digilooms.com/gdpr",
  cookies:
    "https://www.termsfeed.com/live/32bbcbcc-3099-4702-8f10-fe5e0e3394e7",
};
