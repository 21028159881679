import { Icon } from '@components/icon';
import { useVideoPlayer } from './video-player.state';
import { StyledVideoPlayer as Styled } from './video-player.styles';
import { IVideoPlayerProps } from './video-player.typings';

export const VideoPlayer: React.FC<IVideoPlayerProps> = (props) => {
  const { videoUrl, posterUrl, isPlaying, setIsPlaying, videoRef, startTime, isMuted, onMuteChange } = props;

  const { handlers, constants } = useVideoPlayer({
    isPlaying,
    videoRef,
    setIsPlaying,
    startTime,
  });

  return (
    <Styled.Player>
      <video
        ref={videoRef}
        preload="none"
        onPause={handlers.onPause}
        src={videoUrl}
        poster={posterUrl}
        muted={isMuted}
        onTimeUpdate={handlers.handleOnTimeUpdate}
        onWaiting={handlers.handleLoadStart}
        onCanPlay={handlers.handleLoadEnd}
        onEnded={handlers.handleVideoEnd}
      />

      <Styled.Loader isLoading={constants.isVideoLoading} isNotWrapper isWithoutMargin />

      <Styled.Controls>
        <Styled.ControlsTop>
          <div onClick={handlers.handleAction}>
            {!constants.isPlaying ? (
              <Styled.ActionIcon>
                <Icon type="play" />
              </Styled.ActionIcon>
            ) : (
              <Styled.ActionIcon>
                <Icon type="pause" />
              </Styled.ActionIcon>
            )}
          </div>

          <div onClick={onMuteChange}>{!isMuted ? <Icon type="sound" /> : <Icon type="muted" />}</div>
        </Styled.ControlsTop>

        <Styled.RangeWrapper isVisible={constants.isVisible}>
          <div ref={constants.tooltipRef} />
          <Styled.Rage
            type="range"
            min="0"
            max="100"
            value={constants.progress}
            onChange={handlers.handleVideoProgress}
            onMouseDown={handlers.handleRangeMouseDown}
            onMouseUp={handlers.handleRangeMouseUp}
          />
        </Styled.RangeWrapper>
      </Styled.Controls>
    </Styled.Player>
  );
};
