import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AsideRoutes } from '@components/aside-routes';
import { ButtonIcon } from '@components/buttons/button-icon';
import { Icon } from '@components/icon';
import { ProfileDropdown } from '@components/profile-dropdown';
import { SearchBar } from '@components/search-bar';
import { ROUTES, TRoutes } from '@constants/routes';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useMatchMedia } from '@hooks/useMatchMedia';
import { setView } from '@store/reducers/app';
import { StyledHeader as Styled } from './header.styles';

const showSearchPaths: TRoutes[] = [ROUTES.root, ROUTES.lifeScripts];

export const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const { selectedView } = useAppSelector((store) => store.app);
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { isTablet } = useMatchMedia();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (!isTablet) {
      setIsOpen(false);
    }
  }, [isTablet]);

  const onViewListClick = () => dispatch(setView('list'));
  const onViewTableClick = () => dispatch(setView('table'));

  return (
    <header>
      <Styled.Drawer open={isOpen} onClose={toggleDrawer} direction="right">
        <ProfileDropdown isMobile toggleDrawer={toggleDrawer} />
        <AsideRoutes isMobile />
      </Styled.Drawer>

      <Styled.Layout>
        <Styled.Logo isWithText wight={30} height={35} isClickable />

        {showSearchPaths.includes(pathname as TRoutes) ? (
          <Styled.ContentContainer>
            <SearchBar />

            <Styled.SwitchView>
              <ButtonIcon icon="viewList" onClick={onViewListClick} isWithBg={selectedView === 'list'} />
              <ButtonIcon icon="viewTable" onClick={onViewTableClick} isWithBg={selectedView === 'table'} />
            </Styled.SwitchView>
          </Styled.ContentContainer>
        ) : (
          <i />
        )}

        {isTablet ? (
          <Styled.BurgerMenu onClick={toggleDrawer}>
            <Icon type="burger" />
          </Styled.BurgerMenu>
        ) : (
          <ProfileDropdown />
        )}
      </Styled.Layout>
    </header>
  );
};
