import { useLayoutEffect, useState } from 'react';

const queries = ['(max-width: 757px)', '(max-width: 1000px)', '(min-width: 1200px)'];

interface IUseMatchMediaResponse {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const useMatchMedia = (): IUseMatchMediaResponse => {
  const mediaQueryLists = queries.map((query) => matchMedia(query));

  const getValues = () => mediaQueryLists.map((list) => list.matches);

  const [values, setValues] = useState(getValues);

  useLayoutEffect(() => {
    const handler = () => setValues(getValues);
    mediaQueryLists.forEach((list) => list.addEventListener('change', handler));
    return () => mediaQueryLists.forEach((list) => list.removeEventListener('change', handler));
  }, []);

  return ['isMobile', 'isTablet', 'isDesktop'].reduce(
    (acc, screen, index) => ({
      ...acc,
      [screen]: values[index],
    }),
    {} as IUseMatchMediaResponse
  );
};
