import { useState } from 'react';

import { TInputBlur, TInputFocus } from 'typings/common';
import { TextField } from '../text-field';
import { TextFieldPhone } from '../text-field-phone';
import { StyledTextFieldForm as Styled } from './text-field-form.styles';
import { ITextFieldFormProps } from './text-field-form.typings';

export const TextFieldForm: React.FC<ITextFieldFormProps> = ({
  isPhoneNumber,
  field,
  onChangeCountry,
  selectedCountry,
  maxMenuHeight,
  ...restProps
}) => {
  const isError = field.touched && field.errorMessage;
  const [isFocus, setIsFocus] = useState(false);

  const onBlur = (event: TInputBlur) => {
    restProps.onBlur && restProps.onBlur(event);
    setIsFocus(false);
  };

  const onFocus = (event: TInputFocus) => {
    restProps.onFocus && restProps.onFocus(event);
    setIsFocus(true);
  };

  return (
    <Styled.FieldWrapper isError={isError}>
      {isPhoneNumber ? (
        <TextFieldPhone
          {...restProps}
          onFocus={onFocus}
          onBlur={onBlur}
          maxMenuHeight={maxMenuHeight}
          onChangeCountry={onChangeCountry!}
          selectedCountry={selectedCountry!}
        />
      ) : (
        <TextField {...restProps} onFocus={onFocus} onBlur={onBlur} />
      )}

      {isError && (
        <Styled.Error isFocus={isFocus} isPhoneNumber={isPhoneNumber}>
          {field.errorMessage}
        </Styled.Error>
      )}
    </Styled.FieldWrapper>
  );
};
