import { RefObject, useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { setMemoryIdScroll } from '@store/reducers/app';
import { IListViewMemoryProps, IProgressVideo, IVideoState } from './list-view-memory.typings';

export const useListViewMemory = ({ memory, isWithoutShare, containerRef }: IListViewMemoryProps) => {
  const { memoryIdScroll } = useAppSelector((store) => store.app);
  const memoryRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [openMemoryPhoto, setOpenMemoryPhoto] = useState('');

  const [isOpenShareModal, setIsOpenShareModal] = useState(false);

  const toggleOpenShareModal = () => {
    setIsOpenShareModal(!isOpenShareModal);
  };
  const [videoInfo, setVideoInfo] = useState<IVideoState>({
    video: {
      isPlaying: false,
      ref: useRef<HTMLVideoElement>(null),
    },
    modal: {
      isPlaying: false,
      isOpen: false,
      ref: useRef<HTMLVideoElement>(null),
    },
    currentTime: {
      time: 0,
      progress: 0,
    } as IProgressVideo,
    isMuted: false,
  });

  useEffect(() => {
    if (!isWithoutShare && memoryIdScroll === memory.id && containerRef) {
      executeScroll();
      dispatch(setMemoryIdScroll(''));
    }
  }, [memoryIdScroll]);

  useEffect(() => {
    return () => {
      const container = containerRef?.current;
      container?.scrollTo({ top: 0 });
    };
  }, []);

  const toggleMute = () => setVideoInfo((prev) => ({ ...prev, isMuted: !prev.isMuted }));
  const onMemoryPhotoClick = (memoryPhotoId: string) => () => setOpenMemoryPhoto(memoryPhotoId);
  const closeMemoryPhotoModal = () => setOpenMemoryPhoto('');
  const getMemoryPhotoURL = (id: string) => memory.memoryPhoto.find((item) => item.id === id)!.url!;

  const setCurrentTime = (ref: RefObject<HTMLVideoElement>) => ({
    progress: (ref.current!.currentTime / ref.current!.duration) * 100,
    time: ref.current!.currentTime,
  });

  const openMemoryVideoModal = () => {
    setVideoInfo((prev) => ({
      ...prev,
      modal: { ...prev.modal, isPlaying: true, isOpen: true },
      video: { ...prev.video, isPlaying: false },
      currentTime: setCurrentTime(prev.video.ref),
    }));
  };

  const closeMemoryVideoModal = () => {
    setVideoInfo((prev) => ({
      ...prev,
      modal: { ...prev.modal, isPlaying: false, isOpen: false },
      currentTime: setCurrentTime(prev.modal.ref),
    }));
  };

  const setIsPlayingModal = (isPlaying: boolean) => {
    setVideoInfo((prev) => ({ ...prev, modal: { ...prev.modal, isPlaying } }));
  };

  const setIsPlaying = (isPlaying: boolean) => {
    setVideoInfo((prev) => ({ ...prev, video: { ...prev.video, isPlaying } }));
  };

  const executeScroll = () => {
    const container = containerRef!.current!;
    const top = memoryRef.current!.offsetTop - 32;
    container.scrollTo({ top });
  };

  return {
    openMemoryPhoto,
    memoryRef,
    closeMemoryPhotoModal,
    getMemoryPhotoURL,
    onMemoryPhotoClick,
    openMemoryVideoModal,
    closeMemoryVideoModal,
    toggleMute,
    setIsPlayingModal,
    setIsPlaying,
    videoInfo,
    isOpenShareModal,
    toggleOpenShareModal,
  };
};
