import { App } from '@screens/app';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom/client';
import 'react-modern-drawer/dist/index.css';
import './styles/css/fonts.css';
import './styles/css/normalize.css';

Sentry.init({
  dsn: 'https://981b6e4729fc49fea9bec9e8acb44773@o1313691.ingest.sentry.io/6575315%22',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);
root.render(<App />);
