import { Helmet } from 'react-helmet-async';

import { CONFIG } from '@constants/config';
import { LINKS } from '@constants/links';
import { IMetaDecoratorProps } from './meta-decorator.typings';

export const MetaDecorator: React.FC<IMetaDecoratorProps> = (props) => {
  const { description, photoAlt, photoUrl, title, url, author } = props;

  const baseUrl = CONFIG.baseUrl;
  return (
    <Helmet>
      <title>{title}</title>
      {/* Global meta */}
      <meta name="description" content={description} />
      <meta name="keywords" content={description} />
      <meta name="summary" content={description} />
      <meta name="author" content={author} />
      <meta name="url" content={url} />
      <meta name="identifier-URL" content={url} />
      <meta name="abstract" content={description} />
      <meta name="classification" content={title} />
      {/* WebApp Information */}
      <meta name="msapplication-starturl" content={baseUrl} />
      {/* Dublin Core */}
      <meta name="dc.title" content={title} />
      <meta name="dc.subject" content={title} />
      <meta name="dc.description" content={description} />
      <meta name="dc.publisher" content={`Self-Published ${baseUrl}`} />
      <meta name="dc.source" content={baseUrl} />
      <meta name="dc.relation" content={baseUrl} />
      {/* Twitter meta */}
      <meta property="twitter:domain" content={baseUrl} />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:image" content={photoUrl || LINKS.logo} />
      <meta name="twitter:image:alt" content={photoAlt || description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:text:app_name" content="Digilooms" />
      <meta name="twitter:text:description" content={description} />
      {/* Open Graph meta properties */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={photoUrl || LINKS.logo} />
      <meta property="og:image:alt" content={photoAlt || description} />
      <meta property="og:url" content={url} />
      <meta property="og:image:type" content="image/jpeg" />
    </Helmet>
  );
};
