import axios from 'axios';

import { CONFIG } from '@constants/config';
import { setToken } from '@store/reducers/auth';
import { AppDispatch } from '@store/store';
import { TFetchRequest, TPostRequest, TPutRequest } from 'typings/api.typings';
import { GET_ENDPOINTS, POST_ENDPOINTS, PUT_ENDPOINTS } from './endpoints';

let isRefreshing = false;
let refreshSubscribers: any[] = [];

const processQueue = (error: null | string, token: string | null = null) => {
  refreshSubscribers.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  refreshSubscribers = [];
};

const getLocalStorageTokens = () => {
  try {
    const { refreshToken, accessToken } = JSON.parse(JSON.parse(localStorage.getItem('persist:root')!).auth);
    return { refreshToken, accessToken };
  } catch {
    return {
      refreshToken: '',
      accessToken: '',
    };
  }
};

const instance = axios.create({
  baseURL: CONFIG.apiUrl,
  timeout: 60000,
});

export const setInterceptor = (dispatch: AppDispatch) => {
  instance.interceptors.request.use(async (config) => {
    const { accessToken } = getLocalStorageTokens();

    if (!accessToken) {
      return config;
    }
    if (config.headers!.Authorization) {
      return config;
    }
    return {
      ...config,
      headers: { Authorization: `Bearer ${accessToken}` },
    };
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            processQueue(error, null);
            axios.CancelToken.source();
            refreshSubscribers.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers['Authorization'] = `Bearer ${token}`;
              return axios(originalRequest);
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const { refreshToken } = getLocalStorageTokens();
        return new Promise(function (resolve) {
          instance
            .post(
              `${CONFIG.apiUrl}${POST_ENDPOINTS.refreshToken}`,
              {},
              { headers: { Authorization: `Bearer ${refreshToken}` } }
            )
            .then(({ data }) => {
              const { refreshToken, accessToken } = data;
              instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
              originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
              dispatch(setToken({ accessToken, refreshToken }));
              processQueue(null, accessToken);
              resolve(axios(originalRequest));
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const postData: TPostRequest = async ({ requestUrl, urlParams, payload, contentType }) => {
  const urlRequest = POST_ENDPOINTS[requestUrl];
  const url =
    typeof urlRequest === 'string' ? urlRequest : (urlRequest as Function)(...(urlParams as Array<typeof urlParams>));

  const { data } = await instance.post(`${CONFIG.apiUrl}${url}`, payload, {
    headers: { 'Content-Type': contentType! },
  });
  return data;
};

export const fetchData: TFetchRequest = async ({ requestUrl, urlParams, payload }) => {
  const urlRequest = GET_ENDPOINTS[requestUrl];
  const url =
    typeof urlRequest === 'string' ? urlRequest : (urlRequest as Function)(...(urlParams as Array<typeof urlParams>));
  const { data } = await instance.get(`${CONFIG.apiUrl}${url}`, {
    params: payload,
  });
  return data;
};

export const changeData: TPutRequest = async ({ requestUrl, urlParams, payload, contentType }) => {
  const urlRequest = PUT_ENDPOINTS[requestUrl];
  const url =
    typeof urlRequest === 'string' ? urlRequest : (urlRequest as Function)(...(urlParams as Array<typeof urlParams>));

  const { data } = await instance.put(`${CONFIG.apiUrl}${url}`, payload, {
    headers: { 'Content-Type': contentType! },
  });
  return data;
};
