import styled from 'styled-components';

export const StyledModal = {
  Backdrop: styled.div<{ isBlackBackdrop?: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
    height: 100vh;
    background-color: ${(props) => (props.isBlackBackdrop ? '#000' : 'rgba(0, 0, 0, 0.8)')};
    display: grid;
    grid-template-rows: 100vh;
    grid-template-columns: 100vw;
  `,

  Modal: styled.div`
    justify-self: center;
    align-self: center;
    z-index: 1001;
    perspective: 1000;
    -webkit-font-smoothing: antialiased;
    -webkit-filter: blur(0px);
    -webkit-perspective: 1000;
    filter: blur(0px);
    backface-visibility: hidden;
  `,
};
