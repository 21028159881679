import styled from 'styled-components';

export const ErrorText = styled.span`
  border-radius: 5px;
  background-color: #005ae0;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 10px;
  border-radius: 5px;
`;
