import { Link } from 'react-router-dom';

import { Logo } from '@components/logo';

import { ROUTES } from '@constants/routes';

import { StyledGoBack, StyledInactiveLink } from './inactive-link.styles';

export const InactiveLink: React.FC = () => {
  return (
    <StyledInactiveLink>
      <Logo />
      <h2>The owner of this content has restricted public access to it.</h2>
      <h3>Please contact the content owner for access.</h3>

      <Link to={ROUTES.lifeScripts} replace>
        <StyledGoBack>Go back to website</StyledGoBack>
      </Link>
    </StyledInactiveLink>
  );
};
