import SuperEllipse from 'react-superellipse';

import { Checkbox } from '@components/checkbox';
import { Icon } from '@components/icon';
import { Modal } from '@components/modal';
import { useRecipientsInformationModal } from './recipients-information-modal.state';
import { StyledRecipientsInformationModal as Styled } from './recipients-information-modal.styles';
import { IRecipientsInformationModalProps } from './recipients-information-modal.typings';

export const RecipientsInformationModal: React.FC<IRecipientsInformationModalProps> = (props) => {
  const { isEveryoneSelected, toggleEveryone, handleCheck, tempRecipients, onApply, isScrollVisible, ref } =
    useRecipientsInformationModal(props);

  return (
    <Modal onClose={props.onBack}>
      <SuperEllipse p1={15} p2={50}>
        <Styled.RecipientsInformationModal>
          <Styled.Header>
            <Styled.Back onClick={props.onBack}>
              <Icon type="arrowGoTo" />
              <span>back</span>
            </Styled.Back>
            <h5>Recipients information</h5>
          </Styled.Header>

          <Styled.RecipientsList ref={ref}>
            {tempRecipients.length > 1 && (
              <Styled.Recipient isFirst>
                <Checkbox
                  value={'Everyone'}
                  label={<div>Everyone</div>}
                  isChecked={isEveryoneSelected}
                  onChange={toggleEveryone}
                />
              </Styled.Recipient>
            )}
            {tempRecipients.map((recipient) => (
              <Styled.Recipient key={recipient.id}>
                <Checkbox
                  value={recipient.id}
                  label={
                    <Styled.RecipientInfo>
                      <div>
                        <p>{recipient.nickname}</p>
                        <p>{recipient.email}</p>
                      </div>
                      <span>{recipient.status}</span>
                    </Styled.RecipientInfo>
                  }
                  isChecked={recipient.isActive}
                  onChange={handleCheck}
                />
              </Styled.Recipient>
            ))}
          </Styled.RecipientsList>

          <Styled.Footer isScrollVisible={isScrollVisible}>
            <Styled.Button onClick={onApply}>Apply</Styled.Button>
          </Styled.Footer>
        </Styled.RecipientsInformationModal>
      </SuperEllipse>
    </Modal>
  );
};
