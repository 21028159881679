import styled, { css } from 'styled-components';

import { StyledButton } from '@styles/components/button';
import { IButtonLargeProps } from './button-large.typings';

export const StyledButtonLarge = {
  ButtonWrapper: styled.div`
    height: 60px;
    width: 324px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Button: styled(StyledButton)<IButtonLargeProps>`
    width: ${(props) => (props.isBordered ? '320px' : '324px')};
    height: ${(props) => (props.isBordered ? '56px' : '60px')};
    border-radius: 14px;
    font-size: 17px;
    font-weight: 700;

    svg {
      margin-right: 10px;
    }

    ${(props) => {
      switch (props.color) {
        case 'blue':
          return css`
            background-color: #3362cc;
            color: #fff;

            svg {
              fill: #fff;
            }
          `;

        case 'white':
          return css`
            background-color: #fff;
            color: #2b3850;
          `;

        default:
          return css`
            background-color: #000;
            color: #fff;
            line-height: 24;
            font-size: 16px;
            svg {
              margin-bottom: 3px;
            }
          `;
      }
    }}
  `,
};
