import SuperEllipse from 'react-superellipse';

import { Icon } from '@components/icon';
import { Modal } from '@components/modal';
import { Switch } from '@components/switch';
import { getSocialUrlShare, getUrlShare, SOCIAL_ITEMS } from './share-video-modal.constants';
import { useShareVideoModal } from './share-video-modal.state';
import { StyledShareVideoModal as Styled } from './share-video-modal.styles';
import { IShareVideoModalProps } from './share-video-modal.typings';

export const ShareVideoModal: React.FC<IShareVideoModalProps> = ({
  onClose,
  id,
  isLifeScript,
  children,
  isLinkActive,
}) => {
  const shareUrl = getUrlShare(id, isLifeScript);
  const { isCopied, isActiveLink, isLoading, copyToClipboard, onChangeActiveLink } = useShareVideoModal(
    id,
    shareUrl,
    isLinkActive,
    isLifeScript
  );

  return (
    <Modal onClose={onClose}>
      <SuperEllipse p1={15} p2={50}>
        <Styled.Inner>
          <Styled.CloseModal icon="cross" onClick={onClose} />

          {children || (
            <Styled.Header>
              <h5>Share Video</h5>
            </Styled.Header>
          )}

          <Styled.Footer>
            <Styled.SwitchActiveLink>
              <Switch isChecked={isActiveLink} onChange={onChangeActiveLink} isLoading={isLoading} />
              <p>{isActiveLink ? 'Active Link' : 'Not active link'}</p>
            </Styled.SwitchActiveLink>

            <Styled.SocialLink>
              <span>{shareUrl}</span>
              <div onClick={copyToClipboard}>{isCopied ? 'Link Copied' : 'Copy Link'}</div>
            </Styled.SocialLink>
          </Styled.Footer>

          <Styled.SocialWrapper>
            <span>On Social Media</span>
            <Styled.SocialButtons>
              {SOCIAL_ITEMS.map((icon) => (
                <a href={getSocialUrlShare(icon, id, isLifeScript)} key={icon} target="_blank" rel="noreferrer">
                  <Styled.SocialButton>
                    <Icon type={icon} />
                  </Styled.SocialButton>
                </a>
              ))}
            </Styled.SocialButtons>
          </Styled.SocialWrapper>
        </Styled.Inner>
      </SuperEllipse>
    </Modal>
  );
};
