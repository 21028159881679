import {
    SOCIAL_AUTH_API_KEY, SOCIAL_AUTH_APP_ID, SOCIAL_AUTH_DATABASE_URL, SOCIAL_AUTH_DOMAIN,
    SOCIAL_AUTH_PROJECT_ID, SOCIAL_AUTH_STORAGE_BUCKET
} from '@constants/config';
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, OAuthProvider } from '@firebase/auth';
import { FirebaseOptions, initializeApp } from 'firebase/app';

const APPLE_PROVIDER = 'apple.com';

const firebaseConfig: FirebaseOptions = {
  apiKey: SOCIAL_AUTH_API_KEY,
  appId: SOCIAL_AUTH_APP_ID,
  databaseURL: SOCIAL_AUTH_DATABASE_URL,
  projectId: SOCIAL_AUTH_PROJECT_ID,
  storageBucket: SOCIAL_AUTH_STORAGE_BUCKET,
  authDomain: SOCIAL_AUTH_DOMAIN,
};  

export const appleProvider = new OAuthProvider(APPLE_PROVIDER);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
