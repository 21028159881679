import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { persistStore } from 'redux-persist';
import { ThemeProvider } from 'styled-components';

import { MetaDecorator } from '@components/meta-decorator';
import { CONFIG } from '@constants/config';
import { LINKS } from '@constants/links';
import { store } from '@store/store';
import { GlobalStyle } from '@styles/theme/globalStyle';
import { theme } from '@styles/theme/theme';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRouter } from './app.router';

const persistor = persistStore(store);

export const App = () => {
  return (
    <>
      <HelmetProvider>
        <MetaDecorator
          description={
            'Digilooms Time-Capsule is a fully featured App that helps you create, curate and save your precious memories as a legacy for your loved ones. Secure and specialised file storage and valuable legacy across generations.'
          }
          photoUrl={LINKS.logo}
          title={'Digilooms'}
          url={CONFIG.baseUrl}
          author={'Digilooms'}
        />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <ToastContainer />
              <BrowserRouter>{<AppRouter />}</BrowserRouter>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </>
  );
};
