import styled from 'styled-components';

export const StyledModeSwitcher = {
  Wrapper: styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 100%;
  `,

  Switch: styled.div`
    background-color: #1e1e1e;
    border: 1px solid #383838;
    border-radius: 12px;
    padding: 8px 10px;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Radio: styled.input`
    display: none;

    &.user + label {
      &:after {
        left: 100%;
      }
    }

    &.executor + label {
      &:after {
        left: -100%;
      }
    }

    &:checked + label {
      cursor: default;
      color: #000;

      &:after {
        left: 0;
      }
    }
  `,

  Button: styled.label<{ isActive: boolean }>`
    cursor: pointer;
    min-width: 95px;
    height: 45px;

    & span::first-letter {
      text-transform: capitalize;
    }

    position: relative;
    text-align: center;
    transition: background-color 600ms ease, color 600ms ease, opacity ${(prop) => prop.theme.transition};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    letter-spacing: 0.2352px;
    font-weight: 500;

    &:after {
      background-color: #fff;
      content: '';
      border-radius: 12px;
      height: 100%;
      position: absolute;
      top: 0;
      transition: left 400ms cubic-bezier(0.77, 0, 0.175, 1);
      width: 100%;
      z-index: -1;
    }
  `,
};
