import styled from 'styled-components';

import { ButtonIcon } from '@components/buttons/button-icon';

export const StyledModalVideo = {
  ModalVideo: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      max-width: 48vw;
      max-height: 48vw;
      object-fit: contain;
    }
  `,

  ZoomOutButton: styled(ButtonIcon)`
    position: absolute;
    right: 30px;
    top: 30px;
    width: 20px;
    height: 20px;
    padding: 0;
    transition: background-color ${(prop) => prop.theme.transition};
    &:hover {
      svg {
        opacity: 1;
      }
      opacity: 1;
    }
  `,
};
