import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import { ILifeScriptFolder } from '@components/life-script-folders/life-script-folders.typings';
import { alertSuccess } from '@constants/alerts';
import { getErrorData } from '@constants/errors';
import { PROMISES_AREA } from '@constants/promises-area';
import { useAuth } from '@hooks/useAuth';
import { shareLifeScript } from './share-life-script-modal.api';
import { TNewRecipient } from './share-life-script-modal.typings';

export const useShareLifeScriptModal = (folder: ILifeScriptFolder) => {
  const { firstName, lastName } = useAuth().user;
  const newRecipients: TNewRecipient[] = folder.recipient.map((recipient) => ({ ...recipient, isActive: true }));
  const [isDetailsPressed, setIsDetailsPressed] = useState(false);
  const [recipients, setRecipients] = useState<TNewRecipient[]>(newRecipients);
  const [isDisabled, setIsDisabled] = useState(false);

  const recipientNames = recipients
    .filter((recipient) => recipient.isActive)
    .map((recipient) => recipient.nickname)
    .join(', ');
  const toggleDetails = () => setIsDetailsPressed(!isDetailsPressed);

  const onSendLifeScriptByEmail = async () => {
    const payload = {
      id: folder.id,
      shareName: `${firstName} ${lastName}`,
      recipients: recipients.map((recipient) => ({ name: recipient.nickname, email: recipient.email })),
    };
    try {
      setIsDisabled(true);
      await trackPromise(shareLifeScript(payload), PROMISES_AREA.sendLifeScriptByEmail);
      alertSuccess('Email sended successfully');
    } catch (error) {
      console.error(getErrorData(error));
    } finally {
      setIsDisabled(false);
    }
  };

  return {
    isDetailsPressed,
    recipientNames,
    onSendLifeScriptByEmail,
    toggleDetails,
    recipients,
    isDisabled,
    setRecipients,
  };
};
