import { useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

import { Icon } from '@components/icon';
import { CenteredLoader, StyledChildren, StyledIcon } from './loader.styles';
import { ILoaderProps } from './loader.typings';

export const Loader: React.FC<ILoaderProps> = (props) => {
  const { isLoading, area, children, isWithoutArea, isNotWrapper, isWithoutMargin, size, className } = props;
  const { promiseInProgress } = usePromiseTracker({ area });
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (!isWithoutArea) {
      const delay = setTimeout(() => setIsShown(true), 200);
      return () => clearTimeout(delay);
    }
  }, []);

  if (!isLoading && !promiseInProgress && !isWithoutArea) {
    return <>{children}</>;
  }

  return (
    <div className={className}>
      {(isShown || isLoading) &&
        (!isNotWrapper ? (
          <>
            <CenteredLoader size={size}>
              <Icon type="loader" />
            </CenteredLoader>
            <StyledChildren>{children}</StyledChildren>
          </>
        ) : (
          <StyledIcon size={size} isWithoutMargin={isWithoutMargin}>
            <Icon type="loader" />
          </StyledIcon>
        ))}
    </div>
  );
};
