import { forwardRef, useState } from 'react';

import { StyledTextField as Styled } from '@styles/components/text-field';
import { TInputBlur, TInputEvent, TInputFocus } from 'typings/common';
import { ITextFieldProps } from '../text-field.typings';

export const TextField: React.FC<ITextFieldProps> = (props) => {
  const { onChange, isAutoFocus, isDisabled, onBlur, onFocus, ...restProps } = props;

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (event: TInputFocus) => {
    onFocus && onFocus(event);
    setIsFocused(true);
  };

  const handleBlur = (event: TInputBlur) => {
    onBlur && onBlur(event);
    setIsFocused(false);
  };

  const handleChange = (event: TInputEvent) => {
    onChange(event);
  };

  return (
    <Styled.TextField
      {...restProps}
      autoFocus={isAutoFocus}
      isFocused={isFocused}
      disabled={isDisabled}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
    />
  );
};

export const TextFieldRef = forwardRef((props: ITextFieldProps, ref) => <TextField innerRef={ref} {...props} />);
TextFieldRef.displayName = 'TextField';
