import { Icon } from '@components/icon';
import { LINKS } from '@constants/links';
import { asideRoutes } from './aside-routes.constants';
import { StyledAsideRoutes as Styled } from './aside-routes.styles';

interface IAsideRoutesProps {
  isMobile?: boolean;
}

export const AsideRoutes: React.FC<IAsideRoutesProps> = ({ isMobile }) => {
  return (
    <Styled.AsideRoutes isMobile={isMobile}>
      {asideRoutes.map((group, index) => (
        <Styled.Group key={index}>
          {group
            .filter((name) => name.route)
            .map(({ route, name }) => (
              <Styled.Route key={name + route}>
                <a href={route} target="_blank" rel="noreferrer">
                  {name}
                </a>
                <Icon type="arrowGoTo" />
              </Styled.Route>
            ))}
        </Styled.Group>
      ))}

      <a href={LINKS.helpCenter} target="_blank" rel="noreferrer">
        <Styled.Button>Get Help</Styled.Button>
      </a>
    </Styled.AsideRoutes>
  );
};
