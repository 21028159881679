import { IMAGES } from '@constants/images';
import { StyledEmptyVideos as Styled } from './empty-videos.styles';

export const EmptyVideos = () => {
  return (
    <Styled.ContentContainer>
      <Styled.EmptyVideos p1={7} p2={50}>
        <div>
          <img src={IMAGES.emptyVideos} alt="empty-videos" />
          <p>There are no videos in here</p>
        </div>
      </Styled.EmptyVideos>
    </Styled.ContentContainer>
  );
};
