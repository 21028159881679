import { LINKS } from '@constants/links';
import { IMobileApp } from './mobile-modal.typings';

export const appLinks: IMobileApp[] = [
  {
    alt: 'App Store',
    btnImg:
      'https://static.wixstatic.com/media/1e8f4f_0e811775c2944775ac83cd88f9f069e1~mv2.png/v1/fill/w_200,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/apple.png',
    link: LINKS.appStore,
  },
  {
    alt: 'Google Play',
    btnImg:
      'https://static.wixstatic.com/media/1e8f4f_d9901bc4b8d34c12a9be2fd2993b3528~mv2.png/v1/fill/w_200,h_59,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/google.png',
    link: LINKS.googlePlay,
  },
];
