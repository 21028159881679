import { IUserInfo } from './auth.typings';

export const initialSignUpSocial = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  avatar: '',
};

export const authInitialState = {
  accessToken: '',
  refreshToken: '',
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: null,
    phoneNumber: '',
    avatar: '',
    videoLength: 0,
    hasNotifications: false,
    isRegistered: false,
    deviceId: '',
    isExecutor: false,
  } as IUserInfo,

  signUpSocial: initialSignUpSocial,

  isAllowEnter: true,
  isSignedInBySocial: false,
};
