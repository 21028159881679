import { useNavigate } from 'react-router-dom';

import { Icon } from '@components/icon';
import { fetchMemories } from '@components/memories/memories.constants';
import { PROMISES_AREA } from '@constants/promises-area';
import { ROUTES } from '@constants/routes';
import { useAppSelector } from '@hooks/redux';
import { setActiveFolder } from '@store/reducers/app';
import { setMemories } from '@store/reducers/life-scripts';
import { useAppDispatch } from '../../hooks/redux';
import { StyledLogo as Styled } from './logo.styles';
import { ILogoProps } from './logo.typings';

export const Logo: React.FC<ILogoProps> = ({ isWithText, ...restProps }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeFolder } = useAppSelector((store) => store.app);
  const onLogoClick = async () => {
    if (restProps.isClickable) {
      navigate(ROUTES.lifeScripts);
      if (activeFolder.id === '') {
        const memoriesContainer = document.querySelector('#scrollable-memories');
        memoriesContainer?.scrollTo({ top: 0 });
      } else {
        dispatch(
          setActiveFolder({
            id: '',
            isAutoScroll: false,
          })
        );
        const res = await fetchMemories({ page: 1 }, PROMISES_AREA.fetchMemories);
        res &&
          dispatch(
            setMemories({
              page: 2, // default is 2, because first page is fetched
              items: res.items,
              meta: res?.meta,
            })
          );
      }
    }
  };

  return (
    <Styled.Wrapper onClick={onLogoClick} {...restProps}>
      <Icon type="logo" />
      {isWithText && <span>Digilooms</span>}
    </Styled.Wrapper>
  );
};
