import { Loader } from '@components/loader';
import { StyledSwitch as Styled } from './switch.styles';
import { ISwitchProps } from './switch.typings';

export const Switch: React.FC<ISwitchProps> = ({ isChecked, onChange, isLoading }) => {
  const onToggle = () => onChange(!isChecked);

  return (
    <Styled.ToggleSwitch>
      <input type="checkbox" checked={isChecked} onChange={onToggle} disabled={isLoading} />
      <Styled.Switch isDisabled={isLoading} />

      <Styled.Circle isChecked={isChecked} isDisabled={isLoading}>
        <Styled.LoaderWrapper>
          <Loader size={12} isLoading={isLoading} isNotWrapper isWithoutMargin />
        </Styled.LoaderWrapper>
      </Styled.Circle>
    </Styled.ToggleSwitch>
  );
};
