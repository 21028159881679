import { toast, ToastOptions } from 'react-toastify';

import { HELP_CENTER } from '@screens/auth/sign-up/sign-up.constants';
import { IError, IResponseError } from 'typings/common';
import { getErrorData } from './errors';

const toastOptions = (options?: ToastOptions): ToastOptions => ({
  position: 'top-right',
  autoClose: 3500,
  hideProgressBar: false,
  theme: 'dark',
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  ...options,
});

export const alertInfo = (message: string, options?: ToastOptions) => {
  toast.info(message, toastOptions(options));
};

export const alertSuccess = (message: string, options?: ToastOptions) => {
  toast.success(message, toastOptions(options));
};

export const alertError = (message: string, options?: ToastOptions) => {
  toast.error(message, toastOptions(options));
};

export const handleRequestError = (error: unknown, options?: ToastOptions) => {
  const e = error as IResponseError | IError;

  let errorMessage = 'Unknown Error';
  if ('response' in e) {
    errorMessage = e.response.data.message!;
  } else if ('message' in e) {
    errorMessage = e.message!;
  }

  alertError(errorMessage, options);
};

export const handleCodeInputRequestError = (error: unknown, options?: ToastOptions) => {
  const { message, code } = getErrorData(error);
  if (code === 403 && message === HELP_CENTER) {
    return alertInfo(message);
  }

  const e = error as IResponseError | IError;

  let errorMessage = 'Unknown Error';
  if ('response' in e) {
    errorMessage = e.response.data.message!;
  } else if ('message' in e) {
    errorMessage = e.message!;
  }

  if (errorMessage === 'Not found') {
    return alertError('Wrong code', options);
  }

  alertError(errorMessage, options);
};
