import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';

import {
    ITextFieldPhoneFormValues
} from '@components/text-fields/text-field-phone-form/text-field-phone-form.typings';
import { handleRequestError } from '@constants/alerts';
import { PROMISES_AREA } from '@constants/promises-area';
import { ROUTES } from '@constants/routes';
import { useAuthContext } from '@hooks/useAuthContext';
import { checkIsUserExist, sendOTP } from '@screens/auth/auth.api';

export const useLogInUser = () => {
  const {
    country: [selectedCountry, setSelectedCountry],
    code: [isWaitingCode, setIsWaitingCode],
  } = useAuthContext();
  const navigate = useNavigate();

  const [userPhoneNumber, setUserPhoneNumber] = useState('');

  const onLogInUser = async ({ phoneNumber }: ITextFieldPhoneFormValues) => {
    try {
      setUserPhoneNumber(phoneNumber);
      const user = await trackPromise(checkIsUserExist({ phoneNumber }), PROMISES_AREA.auth);
      if (user.isExist && user.isRegistered) {
        await trackPromise(sendOTP({ phoneNumber }), PROMISES_AREA.auth);
        setIsWaitingCode(true);
      } else {
        const number = phoneNumber.replace(selectedCountry.label, '');
        navigate(ROUTES.signUp, { state: { number } });
      }
    } catch (error) {
      handleRequestError(error);
    }
  };

  return {
    userPhoneNumber,
    isWaitingCode,
    onLogInUser,
    setSelectedCountry,
    selectedCountry,
    setIsWaitingCode,
  };
};
