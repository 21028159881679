import { EmptyVideos } from '@components/empty-videos';
import { Loader } from '@components/loader';
import { MemoriesListView } from '@components/memories/memories-list-view';
import { MetaDecorator } from '@components/meta-decorator';
import { LINKS } from '@constants/links';
import { PROMISES_AREA } from '@constants/promises-area';
import { useSharedLifeScript } from './shared-life-script.state';
import { StyledSharedLifeScript as Styled } from './shared-life-script.styles';

export const SharedLifeScript: React.FC = () => {
  const { lifeScript, isLoading, name, lifeScriptUrl } = useSharedLifeScript();

  return (
    <>
      <Loader area={PROMISES_AREA.fetchSharedLifeScript} />
      {lifeScript && (
        <MetaDecorator
          description={'Digilooms life script folder'}
          photoUrl={LINKS.logo}
          title={lifeScript!.title}
          url={lifeScriptUrl}
          author={name}
        />
      )}
      {!lifeScript?.memory.length && !isLoading && (
        <Styled.Empty>
          <EmptyVideos />
        </Styled.Empty>
      )}

      {!!lifeScript?.memory.length && <MemoriesListView memories={lifeScript!.memory} isWithoutShare />}
    </>
  );
};
