import styled from 'styled-components';

export const StyledAvatarPicker = {
  AvatarPicker: styled.div`
    display: flex;
    align-items: center;

    input#file-input {
      display: none;
    }
  `,

  Avatar: styled.div`
    border-radius: 50%;
    margin-right: 15px;
    overflow: hidden;

    label {
      display: block;
      height: 77px;
      width: 77px;

      cursor: pointer;
    }

    img,
    svg {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,

  PickText: styled.label`
    color: #30a14e;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    transition: opacity ${(prop) => prop.theme.transition};
    &:hover {
      opacity: 0.8;
    }
  `,
};
