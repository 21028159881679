import { AsideRoutes } from '@components/aside-routes';
import { ButtonIcon } from '@components/buttons/button-icon';
import { useMatchMedia } from '@hooks/useMatchMedia';
import { ProfileSettingsForm } from './profile-settings-form';
import { useProfileSettings } from './profile-settings.state';
import { StyledProfileSettings as Styled } from './profile-settings.styles';

export const ProfileSettings: React.FC = () => {
  const { isExecutor, goToPrevPage, onSaveProfile } = useProfileSettings();
  const { isTablet } = useMatchMedia();

  return (
    <>
      {!isExecutor && (
        <Styled.ProfileSettings>
          <i />

          <Styled.ContentContainer>
            <Styled.Back>
              <ButtonIcon icon="forward" onClick={goToPrevPage} />
              Settings
            </Styled.Back>

            <Styled.FormContainer>
              <ProfileSettingsForm onSubmit={onSaveProfile} />
            </Styled.FormContainer>
          </Styled.ContentContainer>

          {!isTablet && (
            <Styled.AsideColumn>
              <AsideRoutes />
            </Styled.AsideColumn>
          )}
        </Styled.ProfileSettings>
      )}
    </>
  );
};
