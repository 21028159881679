import styled from 'styled-components';

export const StyledTextFieldPhone = {
  PhoneNumber: styled.div<{ isFlat?: boolean }>`
    width: 100%;
    height: 100%;
    position: relative;

    & > div:last-child {
      position: absolute;
    }

    svg {
      fill: #fff;
    }

    input {
      padding-left: 111px;
      font-weight: 500;

      &::placeholder {
        color: ${(props) => (props.isFlat ? props.theme.colors.white : '#C0C0C0')};
      }
    }

    & > div {
      left: 15px;
      top: 15px;

      & > span + div {
        //control top-level
        border: 0;
        box-shadow: none;
        min-width: 77px;
        height: 41px;
        border-radius: 10px;

        background-color: ${(props) => (props.isFlat ? '#1E1E1E' : '#2d2d2d')};
        &:hover {
          background-color: #222;
        }

        cursor: pointer;
        text-align: center;
        font-size: 18px;
        transition: all ${(props) => props.theme.transition};

        & > div > div {
          transition: color ${(props) => props.theme.transition};
          font-weight: 500;
          margin-left: 3px;
          color: ${(props) => props.theme.colors.white};
        }

        & > div {
          padding-right: 0px;
          color: ${(props) => props.theme.colors.white};
        }
      }
    }
  `,

  NoOptionsMessage: styled.div`
    color: ${(props) => props.theme.colors.white};
    padding-right: 4px;
    font-weight: bold;
    font-size: 15px;
    margin-right: 4px;
    left: 0;
    width: 100%;
  `,

  Option: styled.div`
    border-bottom: 1px solid #454545;
    margin: 0;
    letter-spacing: 0.15px;
    line-height: 24px;
    transition: background-color ${(props) => props.theme.transition};
    cursor: pointer;

    color: ${(props) => props.theme.colors.white} !important;
    padding: 16px 25px !important;

    &:hover {
      background-color: #2d2d2d;
    }
  `,

  MenuList: styled.div<{ isNoOptions: boolean }>`
    line-height: 24px;
    padding: ${(props) => (props.isNoOptions ? '10px' : '0px')} !important;
    min-width: ${(props) => (!props.isNoOptions ? '200px' : 'auto')} !important;
    position: relative;

    & > div {
      padding: 0px !important;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  `,
};
