import styled from 'styled-components';

export const StyledSharedLifeScript = {
  Empty: styled.div`
    height: 70vh;
    & > div {
      padding: 0;
    }
  `,
};
