import { Logo } from '@components/logo';
import { StyledPage } from './page-404.styles';

export const Page404: React.FC = () => {
  return (
    <StyledPage>
      <Logo />
      <h1>Page not found</h1>
      <h3>404</h3>
    </StyledPage>
  );
};
