import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from '@constants/routes';

import { TInputEvent } from 'typings/common';

import { StyledModeSwitcher as Styled } from './mode-switcher.styles';

const modes = ['user', 'executor'] as const;
type TMode = typeof modes[number];

export const ModeSwitcher: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedMode, setSelectedMode] = useState<TMode>((pathname.split('/')[3] as TMode) || 'executor');

  const handleRadioCheck = (e: TInputEvent) => {
    const mode = e.target.value as TMode;
    setSelectedMode(mode);
    navigate(mode === 'executor' ? ROUTES.logInExecutor : ROUTES.logInUser, { replace: true });
  };

  return (
    <Styled.Wrapper>
      <Styled.Switch>
        {modes.map((mode) => (
          <div key={mode}>
            <Styled.Radio
              id={mode}
              className={mode}
              name="mode"
              value={mode}
              type="radio"
              checked={selectedMode === mode}
              onChange={handleRadioCheck}
            />
            <Styled.Button htmlFor={mode} isActive={selectedMode === mode}>
              <span>{mode}</span>
            </Styled.Button>
          </div>
        ))}
      </Styled.Switch>
    </Styled.Wrapper>
  );
};
