import styled, { css } from 'styled-components';

import { media } from '@styles/media';

export const StyledFolder = {
  Folder: styled.div<{ isActive: boolean }>`
    width: 100%;
    overflow: hidden;
    padding: 0 27px;
    font-weight: 500;
    font-size: inherit;
    height: 70px;
    line-height: 16px;
    background-color: ${(props) => (props.isActive ? '#383838' : '#161616')};
    color: ${(props) => (props.isActive ? '#fff' : '#989898')};
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100px;

      ${media.md6} {
        // 1500
        max-width: 87px;
      }
      @media (max-width: 1450px) {
        max-width: 80px;
      }
      @media (max-width: 1410px) {
        max-width: 70px;
      }
      @media (max-width: 1360px) {
        max-width: 65px;
      }
      @media (max-width: 1350px) {
        max-width: 75px;
      }
      @media (max-width: 1320px) {
        max-width: 70px;
      }
      ${media.md1} {
        // 1300
        max-width: 100px;
      }
      @media (max-width: 1240px) {
        max-width: 87px;
      }
      ${media.md5} {
        // 1191
        max-width: 120px;
      }
      @media (max-width: 836px) {
        max-width: 100px;
      }
      @media (max-width: 785px) {
        max-width: 90px;
      }
      @media (max-width: 720px) {
        max-width: 70px;
      }
    }
    @media (max-width: 1350px) {
      padding: 0 20px;
    }

    transition: filter ${(prop) => prop.theme.transition};
    ${(props) =>
      !props.isActive &&
      css`
        &:hover {
          filter: brightness(1.45);
        }
      `}

    svg {
      fill: ${(props) => (props.isActive ? '#fff' : '#414141')};
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        svg:first-child {
          margin-right: 15px;
        }
      }

      &:last-child {
        svg {
          &:nth-child(1) {
            fill: ${(props) => (props.isActive ? '#919191' : '#414141')};
            cursor: pointer;
            &:hover {
              filter: brightness(1.45);
            }
          }
          &:nth-child(2) {
            margin-left: 15px;
          }
        }
      }

      button {
        z-index: 500;
        svg {
          margin: 0px;
        }
      }
    }
  `,
};
