import { SetStateAction } from 'react';
import * as Yup from 'yup';

import { alertInfo, handleRequestError } from '@constants/alerts';
import { getErrorData, stringRequired, stringRequiredMatches } from '@constants/errors';
import { IError, IResponseError } from 'typings/common';
import { IUserSignUp, IUserSignUpLocationProps } from './sign-up.typings';

const ImageDataToBlob = (imageData: ImageData): Promise<File> => {
  const w = imageData.width;
  const h = imageData.height;
  const canvas = document.createElement('canvas');
  canvas.width = w;
  canvas.height = h;
  const ctx = canvas.getContext('2d');
  ctx!.putImageData(imageData, 0, 0);

  return new Promise((resolve) => {
    //eslint-disable-next-line
    //@ts-ignore
    canvas.toBlob(resolve);
  });
};

export const convertURIToImageData = (url: string): Promise<ImageData> => {
  return new Promise((resolve, reject) => {
    if (!url) {
      return reject();
    }
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const image = new Image();
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      context!.drawImage(image, 0, 0, canvas.width, canvas.height);
      resolve(context!.getImageData(0, 0, canvas.width, canvas.height));
    };
    image.crossOrigin = 'Anonymous';
    image.src = url;
  });
};

export const getSignUpInitialValues = (
  signUpSocial: IUserSignUp,
  locationState?: IUserSignUpLocationProps
): IUserSignUp => {
  const { state } = locationState!;
  if (signUpSocial.firstName) {
    return {
      avatar: signUpSocial.avatar,
      email: signUpSocial.email,
      firstName: signUpSocial.firstName,
      lastName: signUpSocial.lastName,
      phoneNumber: signUpSocial.phoneNumber || '',
    };
  }

  const numberWithoutCode = state?.number;
  return {
    avatar: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: numberWithoutCode || '',
  };
};

export const signUpFieldPlaceholders: Partial<IUserSignUp> = {
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  phoneNumber: 'Phone number',
};

export const signUpFieldNames = Object.keys(signUpFieldPlaceholders) as (keyof IUserSignUp)[];

export const validationSchema = Yup.object<Record<keyof IUserSignUp, Yup.AnySchema>>({
  phoneNumber: stringRequired('phoneNumber'),
  firstName: stringRequiredMatches('firstName'),
  lastName: stringRequiredMatches('lastName'),
  email: stringRequiredMatches('email'),
  avatar: Yup.string(),
});

export const getPayload = async (signUpInfo: IUserSignUp, avatarFile: File) => {
  let avatarFromUrlFile: File = avatarFile;
  if (signUpInfo?.avatar) {
    const avatarFromUrl = await convertURIToImageData(signUpInfo.avatar);
    avatarFromUrlFile = await ImageDataToBlob(avatarFromUrl);
  }

  const payload = new FormData();
  payload.append('avatar', avatarFromUrlFile || '');
  payload.append('firstName', signUpInfo.firstName);
  payload.append('lastName', signUpInfo.lastName);
  payload.append('email', signUpInfo.email);
  payload.append('phoneNumber', signUpInfo.phoneNumber);
  return payload;
};

export const HELP_CENTER = 'Your account has been deleted to restore it please contact the help center';

export const handleOnSubmitError = (error: unknown, setIsWaitingCode: (value: SetStateAction<boolean>) => void) => {
  const { message, code } = getErrorData(error);
  const e = error as IResponseError | IError;

  let errorMessage = 'Unknown Error';
  if ('response' in e) {
    errorMessage = e.response.data.message!;
  } else if ('message' in e) {
    errorMessage = e.message!;
  }

  if (errorMessage === 'Phone number is not verified') {
    return setIsWaitingCode(true);
  }

  if (code === 403 && message === HELP_CENTER) {
    return alertInfo(message);
  }
  handleRequestError(error);
};
