import SuperEllipse from 'react-superellipse';

import { Modal } from '@components/modal/modal';
import { StyledModalVideo as Styled } from './modal-video.styles';
import { IModalVideoProps } from './modal-video.typings';

export const ModalVideo: React.FC<IModalVideoProps> = ({ closeMemoryVideoModal, children }) => {
  return (
    <Modal onClose={closeMemoryVideoModal} isBlackBackdrop isForbidOutsideClick>
      <SuperEllipse p1={11} p2={61}>
        <Styled.ModalVideo>
          {children}
          <Styled.ZoomOutButton icon="zoomOut" onClick={closeMemoryVideoModal} />
        </Styled.ModalVideo>
      </SuperEllipse>
    </Modal>
  );
};
