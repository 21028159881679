import { changeData } from '@services/api-service';

export interface IToggleActiveLinkPayload {
  id: string;
  isLinkActive: boolean;
}

export const toggleIsActive = (payload: IToggleActiveLinkPayload, isLifeScript?: boolean) => {
  const requestUrl = isLifeScript ? 'updateLifeScript' : 'updateMemory';
  return changeData({ requestUrl, payload });
};
