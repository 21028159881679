import styled from 'styled-components';

export const StyledButton = styled.button`
  transition: opacity ${(prop) => prop.theme.transition};
  font-size: inherit;

  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled,
  &[disabled] {
    background-color: ${(props) => props.theme.colors.disabledBg};
    color: ${(props) => props.theme.colors.disabled};
    border: none;
    cursor: auto;

    path {
      fill: ${(props) => props.theme.colors.disabled};
    }
  }
`;
