import styled from 'styled-components';

import { ILogoWrapperProps } from './logo.typings';

export const StyledLogo = {
  Wrapper: styled.div<ILogoWrapperProps>`
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: ${(props) => (props.isClickable ? 'pointer' : '')};

    svg {
      height: ${(props) => props.height || 150}px;
      width: ${(props) => props.wight || 80}px;
    }

    span {
      font-size: 28px;
      color: #71bf96;
      text-transform: uppercase;
      line-height: 20.5px;
      font-family: 'SF-Pro-Text';
      font-weight: 900;
    }

    span {
      font-size: 28px;
      color: #71bf96;
      text-transform: uppercase;
      line-height: 20.5px;
      font-family: 'SF-Pro-Text';
      font-weight: 900;
    }
  `,
};
