import { Loader } from '@components/loader';
import { TextFieldPhoneForm } from '@components/text-fields/text-field-phone-form';
import { PROMISES_AREA } from '@constants/promises-area';
import {
    CodeInputLogInExecutor
} from '../../../../components/code-input/code-input-log-in-executor';
import { useLogInExecutor } from './log-in-executor.state';
import { StyledLogInExecutor as Styled } from './log-in-executor.styles';

export const LogInExecutor: React.FC = () => {
  const { isWaitingCode, onLogInExecutor, userPhoneNumber, setSelectedCountry, selectedCountry, setIsWaitingCode } =
    useLogInExecutor();

  return (
    <Loader area={PROMISES_AREA.auth}>
      <Styled.Page>
        {!isWaitingCode ? (
          <>
            <Styled.Title>Log In as Executor</Styled.Title>

            <Styled.PhoneNumber>Enter your phone number</Styled.PhoneNumber>

            <TextFieldPhoneForm
              isFlat
              onSubmit={onLogInExecutor}
              onChangeCountry={setSelectedCountry}
              placeholder="Phone number"
              selectedCountry={selectedCountry}
            />
          </>
        ) : (
          <CodeInputLogInExecutor phoneNumber={userPhoneNumber} setIsWaitingCode={setIsWaitingCode} />
        )}
      </Styled.Page>
    </Loader>
  );
};
