import styled from 'styled-components';

import { StyledShareVideoModal } from '../share-video-modal/share-video-modal.styles';

export const StyledShareLifeScriptModal = {
  ShareLifeScript: styled.div`
    hr {
      margin: 21px 0;
      height: 1px;
      background-color: #272727;
      width: 100%;
    }
  `,

  Header: styled(StyledShareVideoModal.Header)`
    margin-bottom: 26px;
  `,

  Body: styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;

    h6 {
      line-height: 150%;
    }

    p {
      margin-top: 5px;
      font-size: 17px;
      line-height: 150%;
    }
  `,

  SendWrapper: styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    & > div {
      height: 45px;
    }
  `,

  Send: styled.button`
    border: 1px solid #7c7c7c;
    border-radius: 12px;
    background-color: #1a1a1a;
    color: #fff;
    width: 110px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.2352px;
    color: #989898;
    transition: background-color ${(prop) => prop.theme.transition};
    &:hover {
      background-color: #fff;
      color: #000;
    }
    &:disabled,
    &[disabled] {
      background-color: ${(props) => props.theme.colors.disabledBg};
      color: ${(props) => props.theme.colors.disabled};
      border: none;
      cursor: auto;

      path {
        fill: ${(props) => props.theme.colors.disabled};
      }
    }
  `,

  Details: styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color ${(prop) => prop.theme.transition};
    margin-top: 5px;
    align-self: end;
    color: ${(props) => props.theme.colors.green};
    svg {
      transition: fill ${(prop) => prop.theme.transition};

      margin-left: 14px;
      fill: ${(props) => props.theme.colors.green};
    }

    &:hover {
      color: green;
      svg {
        fill: green;
      }
    }
  `,
};
