import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';

import { PROMISES_AREA } from '@constants/promises-area';
import { ROUTES } from '@constants/routes';
import { useAppDispatch } from '@hooks/redux';
import { sendCodeOTP } from '@screens/auth/auth.api';
import { saveUser } from '@screens/auth/auth.constants';
import { CodeInput } from './code-input';
import { verifyTemplate } from './code-input.constants';
import { useCodeInputContext } from './code-input.context';
import { ICodeInputLogInUser } from './code-input.typings';

export const CodeInputLogInUser: React.FC<ICodeInputLogInUser> = (props) => {
  const { phoneNumber, setIsWaitingCode, selectedCountry } = props;
  const dispatch = useAppDispatch();
  const { handleError, codeState, disabledState } = useCodeInputContext();

  const navigate = useNavigate();

  const verifyOTP = async (code: string) => {
    verifyTemplate(
      phoneNumber,
      async () => {
        const receivedUserInfo = await trackPromise(
          sendCodeOTP({
            code,
            phoneNumber,
          }),
          PROMISES_AREA.verifyOTP
        );

        if (receivedUserInfo.user.isRegistered) {
          saveUser(receivedUserInfo, dispatch);
          return navigate(ROUTES.lifeScripts);
        }
        navigate(ROUTES.signUp, {
          state: {
            number: phoneNumber.replace(selectedCountry.label, ''),
          },
        });
      },
      handleError
    );
  };

  return (
    <CodeInput
      setIsWaitingCode={setIsWaitingCode}
      verifyOTP={verifyOTP}
      codeState={codeState}
      disabledState={disabledState}
    />
  );
};
