import styled, { css } from 'styled-components';

import { media } from '@styles/media';
import { IProfileDropdownProps } from './profile-dropdown.typings';

export const StyledProfileDropdown = {
  Wrapper: styled.div`
    position: relative;
    color: #fff;
    width: 138px;
    margin-right: 25px;
  `,

  UserInfo: styled.div`
    display: flex;
    align-items: center;

    svg {
      height: 40px;
      width: 40px;
      margin-right: 8px;
    }

    img {
      user-select: none;
      margin-right: 8px;
      height: 40px;
      width: 40px;
      object-fit: cover;
      border-radius: 50%;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      max-width: 80px;
      line-height: 1.5;
      user-select: none;
    }
  `,

  Header: styled.div<{ isOpen: boolean }>`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg#selectPhoneNumberArrow {
      margin-left: 3px;
      transition: transform ${(props) => props.theme.transition}, fill ${(prop) => prop.theme.transition};
      transform: rotate(${(props) => (props.isOpen ? '180deg' : '0deg')});
      fill: #777777;
    }
  `,

  Body: styled.ul<IProfileDropdownProps>`
    position: ${(props) => (props.isMobile ? 'static' : 'absolute')};
    top: 68px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 50px);
    border-radius: 24px;
    background: #1a1a1a;
    padding: 21px 44px;
    z-index: 6000;

    ${media.md3} {
      padding: 9px 20px;
    }

    ${(props) =>
      props.isMobile &&
      css`
        cursor: default;
        transform: translateX(0);
        margin-top: 10px;
        width: 100%;
        padding: 0;
        background: transparent;
        ${media.md3} {
          padding: 0;
        }
      `}
  `,

  Item: styled.li<{ isDanger?: boolean }>`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 11px 0;
    color: ${(props) => (props.isDanger ? '#FF6363' : '#fff')};

    ${(props) =>
      props.isDanger &&
      css`
        cursor: pointer;
        transition: opacity ${(prop) => prop.theme.transition};
        &:hover {
          opacity: 0.3;
        }
      `}

    a {
      transition: opacity ${(prop) => prop.theme.transition};

      &:not(.active) {
        &:hover {
          opacity: 0.3;
        }
      }

      &.active {
        cursor: default;
        pointer-events: none;
        opacity: 0.3;
      }
    }
  `,

  SignOut: styled.div`
    display: flex;
    align-items: center;

    div {
      margin: 0 0 0 10px;
    }
  `,
};
