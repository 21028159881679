import styled from 'styled-components';

export const StyledTableViewMemory = {
  Memory: styled.div`
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    cursor: pointer;

    & > div {
      height: 100% !important;
      width: 100% !important;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    span {
      display: block;
      padding: 7px 10px 6px 11px;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      height: 26px;
      width: 78px;
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(5px);
      border-radius: 8px;
    }
  `,
};
