export const IMAGES = {
  emptyVideos: require('../assets/images/empty-videos.png'),
  emptyFolders: require('../assets/images/empty-folders.png'),
};

export const compressionConfig = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export const ACCEPT_FILE_TYPES = 'image/png, image/jpeg, image/jpg, image/gif';
