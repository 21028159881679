export const COUNT_CODE = 4;
export const codeInitialValues: string[] = Array(COUNT_CODE).fill('');

export const verifyTemplate = async (
  phoneNumber: string,
  cb: () => Promise<void>,
  handleError: (error: unknown) => void
) => {
  if (!phoneNumber) {
    return;
  }

  try {
    await cb();
  } catch (error) {
    handleError(error);
  }
};
