import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useNavigate, useParams } from 'react-router-dom';

import { alertError } from '@constants/alerts';
import { CONFIG } from '@constants/config';
import { getErrorData } from '@constants/errors';
import { PROMISES_AREA } from '@constants/promises-area';
import { ROUTES } from '@constants/routes';
import { ISharedMemory } from '@screens/shared/shared.typings';
import { getSharedMemory } from '../shared.api';

export const useSharedMemory = (setName: Dispatch<SetStateAction<string>>) => {
  const { memoryId } = useParams();
  const navigate = useNavigate();
  const [memory, setMemory] = useState<ISharedMemory>({} as ISharedMemory);
  const [memoryUrl, setMemoryUrl] = useState('');

  const fetchSharedmemory = async (id: string) => {
    try {
      const memory = await trackPromise(
        getSharedMemory({ id, webUrl: CONFIG.baseUrl }),
        PROMISES_AREA.fetchSharedMemory
      );
      if (!memory.isLinkActive) {
        return navigate(ROUTES.inactiveLink);
      }

      setMemory(memory);
      setName(`${memory?.user.firstName} ${memory?.user.lastName}`);
      const url = CONFIG.baseUrl + ROUTES.sharedMemory.replace(':memoryId', memory.id);
      setMemoryUrl(url);
    } catch (error) {
      const err = getErrorData(error);
      if (err.code === 'ERR_BAD_RESPONSE') {
        alertError('Nothing Found');
        navigate(ROUTES.lifeScripts, { replace: true });
      }
      console.error(err);
    }
  };

  useEffect(() => {
    if (!memoryId) {
      return navigate(ROUTES.lifeScripts);
    }
    fetchSharedmemory(memoryId);
  }, [memoryId]);

  return { memory, memoryUrl };
};
