import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { ROUTES } from '@constants/routes';
import { useAuth } from '@hooks/useAuth';

interface IPrivateRouteProps {
  redirectPath?: string;
  children: JSX.Element;
}

export const PrivateRoute: FC<IPrivateRouteProps> = (props) => {
  const { children, redirectPath } = props;
  const location = useLocation();
  const { isAuth } = useAuth();

  return isAuth ? children : <Navigate to={redirectPath || ROUTES.logInUser} state={{ from: location }} />;
};
