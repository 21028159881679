import styled from 'styled-components';

import { Logo } from '@components/logo';

export const StyledModalExecutor = {
  ModalExecutor: styled.div`
  position: relative;
    background-color: #1e1e1e;
    border-radius: 20px;
    padding: 2em;
    margin: 0.7em;
    text-align: center;
    display: flex;
    flex-direction: column;
    max-width: 500px;

    @media (max-height: 464px) {
      padding: 1.5em;
    }
  `,

  Logo: styled(Logo)`
    @media (max-height: 464px) {
      svg {
        height: 90px !important;
      }
    }
  `,

  CloseModal: styled.div`
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;

    &:hover svg {
      fill: #fff;
    }

    svg {
      transition: fill ${(prop) => prop.theme.transition};
      fill: #414141;
    }
  `,

  Text: styled.h3`
    margin-top: 1em;
    font-size: 20px;

    @media (max-height: 464px) {
      font-size: 18px;
    }

    span {
      font-weight: bold;
    }

    p {
      line-height: 1.2;
      margin-bottom: 13px;
    }
  `,
};
