import { useState } from 'react';

import { useAppDispatch } from '@hooks/redux';
import { setIsLifeScriptLinkActive, setIsMemoryLinkActive } from '@store/reducers/life-scripts';

import { IToggleActiveLinkPayload, toggleIsActive } from './share-video-modal.api';

import { handleRequestError } from '@constants/alerts';

export const useShareVideoModal = (id: string, shareUrl: string, isLinkActive: boolean, isLifeScript?: boolean) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isActiveLink, setIsActiveLink] = useState(!!isLinkActive);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 700);
  };

  const onChangeActiveLink = async (isLinkActive: boolean) => {
    const payload: IToggleActiveLinkPayload = {
      id,
      isLinkActive,
    };

    setIsLoading(true);
    try {
      await toggleIsActive(payload, isLifeScript);
      const payloadActive = { id, isLinkActive };
      setIsActiveLink(isLinkActive);
      if (isLifeScript) {
        return dispatch(setIsLifeScriptLinkActive(payloadActive));
      }
      dispatch(setIsMemoryLinkActive(payloadActive));
    } catch (err) {
      handleRequestError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isCopied,
    isActiveLink,
    isLoading,
    copyToClipboard,
    onChangeActiveLink,
  };
};
