import { Icon } from '@components/icon';
import { StyledButtonIcon } from './button-icon.styles';
import { IButtonIconProps } from './button-icon.typings';

export const ButtonIcon: React.FC<IButtonIconProps> = (props) => {
  const { isDisabled, isSubmit, icon, text, ...rest } = props;

  return (
    <StyledButtonIcon disabled={isDisabled} type={isSubmit ? 'submit' : 'button'} {...rest}>
      {text && <span>{text}</span>}
      {<Icon type={icon} />}
    </StyledButtonIcon>
  );
};
