import { NavLink } from 'react-router-dom';

import { Icon } from '@components/icon';
import { Loader } from '@components/loader';
import { PROMISES_AREA } from '@constants/promises-area';
import { useAuth } from '@hooks/useAuth';
import { getProfileRoutes } from './profile-dropdown.constants';
import { useProfileDropdown } from './profile-dropdown.state';
import { StyledProfileDropdown as Styled } from './profile-dropdown.styles';
import { IProfileDropdownProps } from './profile-dropdown.typings';

export const ProfileDropdown: React.FC<IProfileDropdownProps> = ({ isMobile, toggleDrawer }) => {
  const { isOpen, ref, user, toggleOpen, onSignOut, setActiveClassName } = useProfileDropdown({
    isMobile,
    toggleDrawer,
  });
  const { isExecutor } = useAuth().user;

  return (
    <Styled.Wrapper ref={ref}>
      <Styled.Header onClick={toggleOpen} isOpen={isOpen}>
        <Styled.UserInfo>
          {user.avatar && user.avatar !== 'undefined' ? (
            <img src={user.avatar} alt="avatar" />
          ) : (
            <Icon type="userAvatarPlaceholder" />
          )}
          <span>
            {user.firstName} {user.lastName}
          </span>
        </Styled.UserInfo>

        {!isMobile && <Icon type="selectPhoneNumberArrow" />}
      </Styled.Header>

      {isOpen && (
        <Styled.Body isMobile={isMobile}>
          {getProfileRoutes(isExecutor).map(({ route, label }) => (
            <Styled.Item key={route}>
              <NavLink to={route} className={setActiveClassName} onClick={toggleOpen}>
                {label}
              </NavLink>
            </Styled.Item>
          ))}

          <Styled.Item isDanger onClick={onSignOut}>
            <Styled.SignOut>
              Sign Out
              <Loader area={PROMISES_AREA.logOut} isNotWrapper size={16} />
            </Styled.SignOut>
          </Styled.Item>
        </Styled.Body>
      )}
    </Styled.Wrapper>
  );
};
