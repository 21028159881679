import SuperEllipse from 'react-superellipse';
import styled from 'styled-components';

import { ContentContainer } from '@styles/components/container';

export const StyledEmptyVideos = {
  ContentContainer: styled(ContentContainer)`
    height: 100%;
  `,

  EmptyVideos: styled(SuperEllipse)`
    background-color: #161616;
    border-radius: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      margin: 0 auto;
      text-align: center;
    }

    p {
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.01em;
      color: #565656;
      margin-top: 36px;
    }

    img {
      width: 83%;
    }
  `,
};
