import { Link } from 'react-router-dom';

import { AuthSocialButtons } from '@components/auth-social-buttons';
import { AvatarPicker } from '@components/avatar-picker';
import { Checkbox } from '@components/checkbox';
import { Loader } from '@components/loader';
import { TextFieldForm } from '@components/text-fields/text-field-form';
import { LINKS } from '@constants/links';
import { PROMISES_AREA } from '@constants/promises-area';
import { ROUTES } from '@constants/routes';
import { ErrorText } from '@styles/components/error-text';
import { Space } from '@styles/components/space';
import { CodeInputSignUp } from '../../../components/code-input/code-input-sign-up';
import { StyledAuth } from '../auth.styles';
import { signUpFieldNames, signUpFieldPlaceholders } from './sign-up.constants';
import { useSignUp } from './sign-up.state';
import { StyledSignUp as Styled } from './sign-up.styles';

export const SignUp: React.FC = () => {
  const {
    form: { values, errors, touched, isValid, getFieldProps, handleChange, handleBlur, handleSubmit },
    isPolicyError,
    isCheckBoxChecked,
    selectedCountry,
    setSelectedCountry,
    onAvatarChange,
    onCheck,
    isWaitingCode,
    userPhoneNumber,
    setIsWaitingCode,
    getFormDataForSocialSignUp,
    isSocialSignUp,
    isUpdate,
  } = useSignUp();

  return (
    <Loader area={PROMISES_AREA.auth}>
      {!isWaitingCode && (
        <Styled.SignUp>
          <div>
            <Styled.Header>
              <h2>Sign Up</h2>
              <Link to={ROUTES.logInUser}>
                <StyledAuth.RedirectButton>Log in</StyledAuth.RedirectButton>
              </Link>
            </Styled.Header>

            <form onSubmit={handleSubmit}>
              <Space gap={40} isAlignStart>
                <AvatarPicker {...getFieldProps('avatar')} onChange={onAvatarChange} />

                <Space gap={15}>
                  {signUpFieldNames.map((name) => (
                    <TextFieldForm
                      key={name}
                      maxMenuHeight={150}
                      value={values[name] as string}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      field={{ touched: touched[name], errorMessage: errors[name] }}
                      name={name}
                      placeholder={signUpFieldPlaceholders[name] as string}
                      isPhoneNumber={name === 'phoneNumber'}
                      onChangeCountry={setSelectedCountry}
                      selectedCountry={selectedCountry}
                    />
                  ))}
                </Space>
              </Space>

              <Space gap={22} mt={25}>
                <Styled.PrivatePolicy>
                  <Checkbox
                    value="I agree with Digilooms Terms of Service and Privacy Policy"
                    label={
                      <>
                        I agree with{' '}
                        <a href={LINKS.termsOfUse} target="_blank" rel="noreferrer">
                          Digilooms Terms of Service{' '}
                        </a>
                        and{' '}
                        <a href={LINKS.privacyPolicy} target="_blank" rel="noreferrer">
                          Privacy Policy
                        </a>
                      </>
                    }
                    isChecked={isCheckBoxChecked}
                    onChange={onCheck}
                  />
                  {isPolicyError && (
                    <ErrorText>
                      You have to agree with Digilooms Terms of Service and Privacy Policy to create an account
                    </ErrorText>
                  )}
                </Styled.PrivatePolicy>
                <Styled.Submit isSubmit isDisabled={!isValid || isPolicyError}>
                  Next
                </Styled.Submit>
              </Space>
            </form>
          </div>

          <AuthSocialButtons title="Sign Up with social media" />
        </Styled.SignUp>
      )}
      {isWaitingCode && (
        <CodeInputSignUp
          isUpdate={isUpdate}
          getFormDataForSocialSignUp={getFormDataForSocialSignUp}
          phoneNumber={userPhoneNumber}
          setIsWaitingCode={setIsWaitingCode}
          isSocialSignUp={isSocialSignUp}
        />
      )}
    </Loader>
  );
};
