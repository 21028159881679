import { Outlet, useLocation } from 'react-router-dom';

import { Header } from '@components/header';
import { ROUTES, TRoutes } from '@constants/routes';
import { Container } from '@styles/components/container';
import { ILayoutProps } from './layout.typings';

export const Layout: React.FC<ILayoutProps> = ({ hideHeaderPaths = [] }) => {
  const { pathname } = useLocation();

  const isRouteExist = Object.values(ROUTES).includes(pathname as TRoutes);
  return (
    <Container>
      {!hideHeaderPaths.includes(pathname as TRoutes) && isRouteExist && <Header />}
      <Outlet />
    </Container>
  );
};
