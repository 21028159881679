import { Icon } from '@components/icon';
import { ACCEPT_FILE_TYPES } from '@constants/images';
import { useAvatarPicker } from './avatar-picker.constants';
import { StyledAvatarPicker as Styled } from './avatar-picker.styles';
import { IAvatarPickerProps } from './avatar-picker.typings';

export const AvatarPicker: React.FC<IAvatarPickerProps> = ({ name, ...restProps }) => {
  const { selectedImg, isLoaded, handleImgError, onLoadImage, inputRef, onUpload, avatar } = useAvatarPicker(restProps);

  return (
    <Styled.AvatarPicker>
      <Styled.Avatar>
        <label htmlFor="file-input">
          {selectedImg !== 'undefined' && selectedImg && isLoaded ? (
            <img src={selectedImg} alt="avatar" onError={handleImgError} onLoad={onLoadImage} />
          ) : (
            <Icon type="userAvatarPlaceholder" />
          )}
        </label>

        <input ref={inputRef} type="file" id="file-input" accept={ACCEPT_FILE_TYPES} onChange={onUpload} name={name} />
      </Styled.Avatar>

      <Styled.PickText htmlFor="file-input">{avatar ? 'Change photo' : 'Upload photo'}</Styled.PickText>
    </Styled.AvatarPicker>
  );
};
