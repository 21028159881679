import SuperEllipse from 'react-superellipse';
import styled, { css } from 'styled-components';

import { ButtonIcon } from '@components/buttons/button-icon';
import {
    IStyledPhotoProps
} from '@components/memories/memories-list-view/memories-list-view.typings';
import { media } from '@styles/media';

export const StyledListViewMemory = {
  Memory: styled.div`
    display: flex;
    column-gap: 40px;
    height: calc(100vh - 195px);
    max-height: 700px;

    & > div {
      flex-basis: 55%;

      height: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }

    ${media.md0} {
      column-gap: 20px;
    }

    ${media.md5} {
      height: auto;
      max-height: none;
      flex-direction: column;
      column-gap: 0px;
      row-gap: 16px;
    }
  `,

  PlayerWrapper: styled.div`
    height: 100%;
    position: relative;

    & > div {
      height: 100%;
      ${media.md5} {
        height: calc(100vh - 195px) !important;
      }
    }

    button {
      position: absolute;
      right: 20px;
      top: 20px;
      height: 25px;

      svg {
        fill: transparent !important;
      }
    }
  `,

  DescriptionWrapper: styled.div`
    height: 60%;
    & > div {
      height: 100%;
    }
  `,

  DescriptionHeader: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding-right: 23px;

    svg {
      fill: #676767;
      cursor: pointer;

      transition: filter ${(prop) => prop.theme.transition};
      &:hover {
        filter: brightness(1.45);
      }
    }

    span {
      font-weight: 500;
      font-size: 14.9005px;
      line-height: 15px;
      color: #676767;
    }
  `,

  Description: styled.div`
    height: 100%;
    background-color: #1a1a1a;
    padding: 32px 10px 32px 32px;
    white-space: normal;
    word-break: break-all;

    h2 {
      max-height: 45px;
      font-weight: 700;
      overflow-y: auto;
      font-size: 30px;
      line-height: 150%;
      margin-bottom: 10px;
      &::-webkit-scrollbar-track {
        background: #1a1a1a;
      }
    }
  `,

  DescriptionText: styled.div`
    padding-right: 16px;
    height: calc(100% - 41px - 45px);
    overflow-y: auto;
    ${media.md5} {
      max-height: 40vh;
    }
    &::-webkit-scrollbar-track {
      background: #1a1a1a;
    }
    p {
      display: block;
      font-size: 16px;
      line-height: 170%;
    }
  `,

  MemoryPhotosWrapper: styled(SuperEllipse)`
    height: 40%;
    & > div {
      height: 100%;
    }
  `,

  MemoryPhotos: styled.div<{ isWithScrollbar: boolean; isMoreThanThree: boolean }>`
    height: calc(100%);
    width: 100%;
    overflow-y: ${(props) => (props.isWithScrollbar ? 'auto' : 'hidden')};
    background-color: #1a1a1a;
    padding: 7% 10%;
    gap: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    ${(props) =>
      props.isMoreThanThree &&
      css`
        ${media.md5} {
          align-items: baseline;
          & > div:first-child {
            margin-bottom: 20px;
          }
        }
      `}

    &::-webkit-scrollbar {
      display: none;
    }
  `,

  Photo: styled(SuperEllipse)<IStyledPhotoProps>`
    height: ${(props) => (props.$isPseudo ? '0' : '50%')};
    ${media.md5} {
      min-height: ${(props) => (props.$isPseudo ? '0' : '100px')};
      height: ${(props) => (props.$isPseudo ? '0' : 'calc(50%)')};
    }
    cursor: pointer;
    width: 29%;
    max-width: 100px;
    max-height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url(${(props) => props.url});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  `,

  EmptyMemoryPhotos: styled.div`
    background-color: #1a1a1a;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;

    ${media.md5} {
      padding: 20px;
    }

    p {
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #565656;
    }

    img {
      width: 40%;
    }
  `,

  ModalPhoto: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    img {
      max-width: 45vw;
      max-height: 45vw;
      object-fit: contain;
    }

    button {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 41px;
      height: 41px;
    }
  `,

  CloseButton: styled(ButtonIcon)`
    transition: background-color ${(prop) => prop.theme.transition};
    &:hover {
      svg {
        opacity: 1;
      }
      opacity: 1;
      background-color: #eee;
    }
  `,
};
