import { IGetAllLifeAndMemories } from 'typings/api.typings';
import { ISearchOption } from './search-bar.typings';

export const LIFE_SCRIPTS_PER_PAGE = 20;
export const MEMORIES_PER_PAGE = 21;

export const getPages = (allLifeScriptsAndMemories: IGetAllLifeAndMemories[], option: ISearchOption) => {
  const folderIndex = allLifeScriptsAndMemories.findIndex((item) => item.lifeScriptId === option.lifeScriptId);
  const folder = allLifeScriptsAndMemories[folderIndex];
  const memoryIndex = folder.memoryIds.findIndex((memoryId) => memoryId === option.value);
  const calculatedMemoryPage = Math.ceil((memoryIndex + 1) / MEMORIES_PER_PAGE);
  const isFirstItem = memoryIndex === 0;

  return { calculatedMemoryPage, isFirstItem };
};
