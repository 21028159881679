import styled from 'styled-components';

import { Loader } from '@components/loader';

export const StyledSearchBar = {
  SearchBar: styled.div<{ isFocus: boolean; isWithResult: boolean; isWithText: boolean }>`
    position: relative;
    width: 100%;

    input {
      font-weight: 400;
      font-size: 15px;
      border-radius: ${(props) => (props.isWithResult ? '15px 15px 0px 0px' : '15px')};
      &::placeholder {
        transition: color ${(prop) => prop.theme.transition};
        color: ${(props) => (props.isWithResult || props.isFocus ? '#fff' : '#696969')};
      }
    }

    svg {
      transition: fill ${(prop) => prop.theme.transition};
      fill: ${(props) => (props.isWithText || props.isFocus ? '#fff' : '#696969')};
      position: absolute;
      left: 20px;
      top: 17px;
      z-index: 20;
    }

    svg + div > div {
      &::after {
        content: '';
        display: none;
      }
    }

    svg + div {
      width: 100%;
    }

    li {
      border-top: none !important;
      padding: 0 13px;
      background-color: #252525;
    }

    div.select-search__select {
      padding: ${(props) => (props.isWithResult ? '10px 0' : '0')};
      width: 100%;
      top: 47px !important;
      overflow: auto !important;
      border-radius: 0px 0px 20px 20px !important;
      background-color: #252525;

      & > ul > li.select-search__not-found {
        padding: 20px;
        cursor: default;
        font-weight: 400;
        font-size: 15px;
        color: #fff;
        text-transform: capitalize;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  `,

  Input: styled.input`
    height: 48px;
    width: 100%;
    cursor: text !important;
    background-color: #252525;
    padding: 15px 15px 15px 42px;
    color: #fff;
    border: none;
    transition: background-color ${(prop) => prop.theme.transition};
    &:focus {
      background-color: #383838;
    }
  `,

  Loader: styled(Loader)`
    position: absolute;
    right: 40px;
    top: 0;
  `,

  Item: styled.button`
    font-family: ${(props) => props.theme.font};
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    width: 100%;
    height: 100%;
    padding: 11px 20px;
    background-color: #252525;
    color: #fff;
    height: 60px;

    transition: background-color ${(prop) => prop.theme.transition};
    &:hover {
      background-color: #383838;
    }

    img {
      margin-right: 12px;
      width: 38px;
      height: 38px;
      object-fit: cover;
      border-radius: 7px;
    }
  `,
};
