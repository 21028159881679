import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useNavigate, useParams } from 'react-router-dom';

import { alertError } from '@constants/alerts';
import { CONFIG } from '@constants/config';
import { getErrorData } from '@constants/errors';
import { PROMISES_AREA } from '@constants/promises-area';
import { ROUTES } from '@constants/routes';
import { useShareContext } from '@hooks/useShareContext';
import { ISharedLifeScript } from '@screens/shared/shared.typings';
import { getSharedLifeScript } from '../shared.api';

export const useSharedLifeScript = () => {
  const { lifeScriptId } = useParams();
  const navigate = useNavigate();
  const [lifeScript, setLifeScript] = useState<ISharedLifeScript>();
  const { name, setName } = useShareContext();
  const [isLoading, setIsLoading] = useState(false);
  const [lifeScriptUrl, setLifeScriptUrl] = useState('');

  const fetchSharedLifeScript = async (id: string) => {
    try {
      setIsLoading(true);
      const lifeScript = await trackPromise(
        getSharedLifeScript({ id, webUrl: CONFIG.baseUrl }),
        PROMISES_AREA.fetchSharedLifeScript
      );
      if (!lifeScript.isLinkActive) {
        return navigate(ROUTES.inactiveLink);
      }
      setLifeScript(lifeScript);
      setName(`${lifeScript?.user.firstName} ${lifeScript?.user.lastName}`);
      const url = CONFIG.baseUrl + ROUTES.sharedLifeScript.replace(':lifeScriptId', lifeScript.id);
      setLifeScriptUrl(url);
    } catch (error) {
      const err = getErrorData(error);
      if (err.code === 'ERR_BAD_RESPONSE') {
        alertError('Nothing Found');
        navigate(ROUTES.lifeScripts, { replace: true });
      }
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!lifeScriptId) {
      return navigate(ROUTES.lifeScripts);
    }
    fetchSharedLifeScript(lifeScriptId);
  }, [lifeScriptId]);

  return { lifeScript, isLoading, name, lifeScriptUrl };
};
