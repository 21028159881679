import styled from 'styled-components';

import { media } from '@styles/media';
import { StyledAuth } from '../../auth.styles';

export const StyledLogInUser = {
  Wrapper: styled.div`
    overflow: hidden;
    ${media.mmd3} {
      height: 70vh;
    }
  `,

  LogInUser: styled(StyledAuth.Section)`
    margin: 52px auto 0;
    max-width: 983px;

    ${media.md1} {
      align-items: flex-start;
      margin: 32px auto 0;
    }
    ${media.md3} {
      align-items: center;
    }
  `,

  PhoneNumberLogIn: styled.div`
    width: 324px;
    ${media.mmd3} {
      margin-top: 57px;
      padding-left: 2px;
    }
    ${media.md1} {
      margin-top: 28px;
    }
  `,

  PhoneNumber: styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.28px;
    color: #9a9a9a;
    margin-bottom: 14px;
  `,
};
