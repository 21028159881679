import styled, { css } from 'styled-components';

export const StyledRecipientsInformationModal = {
  RecipientsInformationModal: styled.div`
    padding: 36px 16px 36px 36px;
    width: 427px;
    max-height: 546px;
    background-color: #1a1a1a;

    h5 {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #989898;
      white-space: nowrap;
    }
  `,

  Header: styled.div`
    position: relative;
    margin-bottom: 33px;
  `,

  Back: styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    display: flex;
    align-items: center;
    svg {
      transform: rotate(-90deg);
      fill: #fff;
      margin-right: 14px;
    }
  `,

  RecipientsList: styled.ul`
    display: flex;
    flex-direction: column;
    max-height: 360px;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      background: #1a1a1a;
    }
    scrollbar-color: #383838 #1a1a1a !important;
    scrollbar-width: thin !important;
    gap: 5px;
    padding-right: 20px;
  `,

  Recipient: styled.li<{ isFirst?: boolean }>`
    width: 100%;
    padding: 15px;
    background: #262626;
    border-radius: 10px;
    & > div {
      justify-content: flex-start;
    }

    ${(props) =>
      !props.isFirst &&
      css`
        & > div {
          width: 100%;

          align-items: center;

          label {
            width: 100%;
          }

          svg {
            top: 18px;
          }
        }
      `}
  `,

  RecipientInfo: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: left;
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      color: #838282;
    }
  `,

  Footer: styled.div<{ isScrollVisible: boolean }>`
    padding-top: 10px;
    position: relative;
    ${(props) =>
      props.isScrollVisible &&
      css`
        &::after {
          content: '';
          position: absolute;
          height: 50px;
          width: 97%;
          left: 0;
          top: -45px;
          background: rgb(26, 26, 26);
          background: linear-gradient(
            0deg,
            rgba(26, 26, 26, 1) 7%,
            rgba(26, 26, 26, 0.3566468823857668) 64%,
            rgba(26, 26, 26, 0) 80%
          );
        }
      `}
  `,

  Button: styled.button`
    width: 110px;
    border: 1px solid #7c7c7c;
    background-color: transparent;
    color: #fff;
    border-radius: 12px;
    height: 45px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2352px;
    color: #989898;
    transition: all ${(prop) => prop.theme.transition};
    &:hover {
      color: #000;
      background-color: #fff;
    }
  `,
};
