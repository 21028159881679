import styled from 'styled-components';

import { ButtonSmall } from '@components/buttons/button-small';
import { Logo } from '@components/logo';
import { media } from '@styles/media';

export const StyledAuth = {
  Logo: styled(Logo)`
    margin: 20px 0 40px;

    ${media.mmd0} {
      margin: 51px 0 104px;
    }

    ${media.md0} {
      margin: 30px 0 50px;
    }

    @media (max-height: 905px) {
      margin-bottom: 30px;
    }
  `,

  RedirectButton: styled(ButtonSmall)`
    color: #bcbcbc;
    background-color: #1e1e1e;
    transition: background-color ${(props) => props.theme.transition}, color ${(props) => props.theme.transition};

    &:hover {
      opacity: 1 !important;
      color: #000;
      background-color: #fff;
    }
  `,

  Header: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    ${media.mmd3} {
      margin-bottom: 57px;
    }

    h2 {
      font-weight: 700;
      font-size: 36px;
      line-height: 17px;
      letter-spacing: 0.28px;
    }
  `,

  Section: styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.md3} {
      flex-direction: column;
    }

    & > div {
      &:first-child {
        order: -1;
      }
      max-width: 324px;
    }

    &::before {
      content: '';
      background-color: #454545;
      height: 316px;
      width: 1px;

      ${media.md3} {
        margin-top: 20px;
        height: 1px;
        width: 316px;
      }
    }
  `,
};
