import styled, { css } from 'styled-components';

import { StyledButton } from '@styles/components/button';
import { StyledButtonIconProps } from './button-icon.typings';

export const StyledButtonIcon = styled(StyledButton)<StyledButtonIconProps>`
  height: 100%;
  background-color: transparent;

  svg {
    fill: #fff;
  }

  span {
    letter-spacing: 0.01em;
    margin-right: 14px;
  }

  padding: 8px;

  ${(props) =>
    props.isReversed &&
    css`
      transform: rotateY(180deg);
    `}

  ${(props) =>
    props.isWithBg &&
    css`
      background-color: #fff;
      border-radius: 50%;
      svg {
        fill: #000;
      }
    `}
`;
