import SuperEllipse from 'react-superellipse';

import { StyledButtonSmall } from './button-small.styles';
import { IButtonSmallProps } from './button-small.typings';

export const ButtonSmall: React.FC<IButtonSmallProps> = (props) => {
  const { isDisabled, children, isBordered, isSubmit, ...restProps } = props;

  return (
    <SuperEllipse p1={1} p2={24}>
      <StyledButtonSmall
        disabled={isDisabled}
        isBordered={isBordered}
        type={isSubmit ? 'submit' : 'button'}
        {...restProps}
      >
        {children}
      </StyledButtonSmall>
    </SuperEllipse>
  );
};
