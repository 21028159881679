export type ThemeType = typeof theme;

export const theme = {
  maxWidthContentContainer: 850,
  font: 'Roboto',
  fontSize: '14px',
  fontWeight: 400,
  transition: '.3s ease',

  colors: {
    danger: '#C33535',
    green: '#30A24E',
    body: '#000',
    white: '#fff',
    border: '#383838',
    disabled: '#656565',
    disabledBg: '#1E1E1E',
  },
};
