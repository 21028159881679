import { ListViewMemory } from '@components/list-view-memory';
import { Loader } from '@components/loader';
import { MetaDecorator } from '@components/meta-decorator';
import { PROMISES_AREA } from '@constants/promises-area';
import { useShareContext } from '@hooks/useShareContext';
import { useSharedMemory } from './shared-memory.state';

export const SharedMemory: React.FC = () => {
  const { name, setName } = useShareContext();
  const { memory, memoryUrl } = useSharedMemory(setName);

  return (
    <>
      {memory && (
        <Loader area={PROMISES_AREA.fetchSharedMemory}>
          <MetaDecorator
            description={memory.description}
            photoUrl={memory.posterUrl}
            title={memory.title}
            url={memoryUrl}
            author={name}
          />

          <ListViewMemory memory={memory} isWithoutShare />
        </Loader>
      )}
    </>
  );
};
