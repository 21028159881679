import { ReactComponent as arrowBack } from '@assets/icons/arrow-back.svg';
import { ReactComponent as arrowGoTo } from '@assets/icons/arrow-go-to.svg';
import { ReactComponent as burger } from '@assets/icons/burger-menu.svg';
import { ReactComponent as checkMark } from '@assets/icons/check-mark.svg';
import { ReactComponent as crossFilled } from '@assets/icons/close.svg';
import { ReactComponent as cross } from '@assets/icons/cross.svg';
import { ReactComponent as folder } from '@assets/icons/folder.svg';
import { ReactComponent as forward } from '@assets/icons/forward.svg';
import { ReactComponent as info } from '@assets/icons/info.svg';
import { ReactComponent as loader } from '@assets/icons/loader.svg';
import { ReactComponent as logo } from '@assets/icons/logo.svg';
import { ReactComponent as muted } from '@assets/icons/muted.svg';
import { ReactComponent as pause } from '@assets/icons/pause.svg';
import { ReactComponent as play } from '@assets/icons/play.svg';
import { ReactComponent as search } from '@assets/icons/search.svg';
import {
    ReactComponent as selectPhoneNumberArrow
} from '@assets/icons/select-phone-number-arrow.svg';
import { ReactComponent as share } from '@assets/icons/share.svg';
import { ReactComponent as apple } from '@assets/icons/social-media/apple.svg';
import { ReactComponent as facebook } from '@assets/icons/social-media/facebook.svg';
import { ReactComponent as google } from '@assets/icons/social-media/google.svg';
import { ReactComponent as instagram } from '@assets/icons/social-media/instagram.svg';
import { ReactComponent as linkedIn } from '@assets/icons/social-media/linkedin.svg';
import { ReactComponent as tikTok } from '@assets/icons/social-media/tik-tok.svg';
import { ReactComponent as twitter } from '@assets/icons/social-media/twitter.svg';
import { ReactComponent as youTube } from '@assets/icons/social-media/you-tube.svg';
import { ReactComponent as sound } from '@assets/icons/sound.svg';
import { ReactComponent as appStore } from '@assets/icons/store/app-store.svg';
import { ReactComponent as googlePlay } from '@assets/icons/store/google-play.svg';
import { ReactComponent as userAvatarPlaceholder } from '@assets/icons/user-avatar-placeholder.svg';
import { ReactComponent as viewList } from '@assets/icons/view-list.svg';
import { ReactComponent as viewTable } from '@assets/icons/view-table.svg';
import { ReactComponent as zoomIn } from '@assets/icons/zoom-in.svg';
import { ReactComponent as zoomOut } from '@assets/icons/zoom-out.svg';

const ICONS = {
  logo,
  search,
  facebook,
  apple,
  google,
  loader,
  viewList,
  viewTable,
  arrowBack,
  arrowGoTo,
  checkMark,
  cross,
  forward,
  appStore,
  googlePlay,
  share,
  zoomIn,
  zoomOut,
  selectPhoneNumberArrow,
  userAvatarPlaceholder,
  folder,
  play,
  muted,
  sound,
  pause,
  instagram,
  tikTok,
  linkedIn,
  twitter,
  youTube,
  info,
  burger,
  crossFilled,
};

export type TIcon = keyof typeof ICONS;

export const Icon = (props: { type: TIcon }) => {
  const NewIcon = ICONS[props.type];
  return <NewIcon />;
};
