import { fetchData } from '@services/api-service';
import { IMemoriesPayload } from './memories.typings';

export const getMemories = async (payload: IMemoriesPayload) => {
  const params: IMemoriesPayload = { ...payload, sortField: 'created' };
  return fetchData({ requestUrl: 'fetchMemories', payload: params });
};

export const getFolders = () => {
  return fetchData({ requestUrl: 'fetchLifeScriptFolders' });
};
