import { Icon } from '@components/icon';
import { StyledCheckbox as Styled } from './checkbox.styles';
import { ICheckboxProps } from './checkbox.typings';

export const Checkbox: React.FC<ICheckboxProps> = (props) => {
  const { value, isChecked, onChange, isDisabled, label } = props;
  return (
    <Styled.Wrapper>
      <Styled.Checkbox
        id={value}
        type="checkbox"
        value={value}
        checked={isChecked}
        onChange={onChange}
        disabled={isDisabled}
      />

      <label htmlFor={value}>
        <Icon type="checkMark" />
        {label || value}
      </label>
    </Styled.Wrapper>
  );
};
