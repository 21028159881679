import styled from 'styled-components';

export const StyledCheckbox = {
  Wrapper: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
  `,

  Checkbox: styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    min-height: 17px;
    min-width: 17px;
    margin-top: 3px;
    border: 1px solid #fff;
    transition: background 0.3s, border-color 0.3s;
    border-radius: 50%;
    cursor: pointer;

    &:checked {
      background: #23bc64;
      border: 1px solid #23bc64;

      & + label svg {
        opacity: 1;
      }
    }

    & + label {
      position: relative;
      text-align: center;
      cursor: pointer;

      a {
        font-weight: 700;
        color: ${(props) => props.theme.colors.green};
        transition: opacity ${(prop) => prop.theme.transition};
        &:hover {
          opacity: 0.8;
        }
      }

      svg {
        transition: opacity ${(props) => props.theme.transition};
        position: absolute;
        opacity: 0;
        left: -14px;
        top: 4px;
        margin-top: 3px;
      }

      font-size: 14px;
      line-height: 23px;
      font-weight: 400;
      display: inline-block;
      vertical-align: top;
      padding-left: 12px;
    }

    &:disabled {
      border-color: ${(props) => props.theme.colors.disabled};
      cursor: auto;

      & + label {
        cursor: default;
        opacity: 0.5;
        a {
          pointer-events: none;
          cursor: default;
          opacity: 0.8;
        }
      }

      &:checked {
        border-color: ${(props) => props.theme.colors.disabled};
        background: ${(props) => props.theme.colors.disabledBg};
      }
    }
  `,
};
