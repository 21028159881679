import { IGetAllLifeAndMemories } from 'typings/api.typings';
import { IActiveFolder, TSelectedView } from './app.typings';

export const appInitialState = {
  selectedView: 'list' as TSelectedView,
  activeFolder: {
    id: '',
    isAutoScroll: false,
  } as IActiveFolder,
  memoryIdScroll: '',
  searchTerm: '',
  allLifeScriptsAndMemories: [] as IGetAllLifeAndMemories[],
};
