import { useEffect } from 'react';

import { IUseModalProps, TDivEventFn } from './modal.typings';

export const useModal = ({ onClose, isForbidOutsideClick }: IUseModalProps) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (onClose) {
      event.code === 'Escape' && onClose();
    }
  };

  const handleBackdropClick: TDivEventFn = (event) => {
    if (onClose && !isForbidOutsideClick) {
      event.currentTarget === event.target && onClose();
    }
  };

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      document.documentElement.style.overflow = '';
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return {
    handleBackdropClick,
  };
};
