import styled from 'styled-components';

import { Logo } from '@components/logo';

export const StyledMobileModal = {
  MobileModal: styled.div`
    position: relative;
    background-color: #1e1e1e;
    border-radius: 20px;
    padding: 2em;
    margin: 0.7em;
    text-align: center;
    display: flex;
    flex-direction: column;
    max-width: 500px;

    @media (max-height: 464px) {
      padding: 1.5em;
    }
  `,

  CloseModal: styled.div`
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;

    &:hover svg {
      fill: #fff;
    }

    svg {
      transition: fill ${(prop) => prop.theme.transition};
      fill: #414141;
    }
  `,

  Logo: styled(Logo)`
    @media (max-height: 464px) {
      svg {
        height: 90px !important;
      }
    }
  `,

  Title: styled.h1`
    margin-top: 1em;
    font-size: 2em;
    font-weight: 500;

    @media (max-height: 464px) {
      font-size: 1.6em;
    }
  `,

  AppLinks: styled.div`
    display: flex;
    gap: 10px;
    margin-top: 2em;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @media (max-height: 464px) {
      flex-direction: column;
      margin-top: 10px;
    }
    & > div {
      width: auto;
      height: 100%;
    }
  `,

  Link: styled.a`
    width: 200px;
    background-color: #fff;
    padding: 20px;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
      min-width: 30px;
      width: 30px;
    }

    @media (max-height: 464px) {
      padding: 15px;
      svg {
        margin-right: 10px;
        min-width: 20px;
        width: 20px;
      }
    }

    p {
      text-align: center;
      white-space: nowrap;
      color: #000;
    }
  `,
};
