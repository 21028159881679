import SuperEllipse from 'react-superellipse';

import { Icon } from '@components/icon';

import { IButtonLargeProps } from './button-large.typings';

import { StyledButtonLarge as Styled } from './button-large.styles';

export const ButtonLarge: React.FC<IButtonLargeProps> = (props) => {
  const { onClick, isDisabled, children, isBordered, color, icon, isSubmit, className } = props;

  return (
    <SuperEllipse p1={1} p2={36}>
      <Styled.ButtonWrapper className={className}>
        <SuperEllipse p1={1} p2={33}>
          <Styled.Button
            onClick={onClick}
            disabled={isDisabled}
            isBordered={isBordered}
            color={color}
            type={isSubmit ? 'submit' : 'button'}
          >
            {icon && <Icon type={icon} />}
            {children}
          </Styled.Button>
        </SuperEllipse>
      </Styled.ButtonWrapper>
    </SuperEllipse>
  );
};
