import { Navigate, Outlet } from 'react-router-dom';

import { Loader } from '@components/loader';
import { PROMISES_AREA } from '@constants/promises-area';
import { checkIsRouteInvalid, ROUTES } from '@constants/routes';
import { useAuth } from './auth.state';
import { StyledAuth as Styled } from './auth.styles';

export const Auth = () => {
  const { pathname, selectedCountry, setSelectedCountry } = useAuth();

  return (
    <>
      {checkIsRouteInvalid(pathname, ROUTES.auth) && <Navigate to={ROUTES.logInExecutor} replace />}
      <Loader area={PROMISES_AREA.fetchCountry}>
        <Styled.Logo wight={40} height={50} isWithText />

        {selectedCountry && <Outlet context={{ country: [selectedCountry, setSelectedCountry] }} />}
      </Loader>
    </>
  );
};
