import styled from 'styled-components';

interface ISpaceProps {
  gap?: number;
  direction?: 'vertical' | 'horizontal';
  isAlignStart?: boolean;
  mt?: number;
}

export const Space = styled.div<ISpaceProps>`
  width: 100%;
  display: flex;
  margin-top: ${(props) => props.mt || 0}px;
  align-items: ${(props) => (props.isAlignStart ? 'flex-start' : 'center')};
  justify-content: ${(props) => (props.gap ? 'none' : 'flex-between')};
  flex-direction: ${(props) => (props.direction === 'horizontal' ? 'row' : 'column')};
  gap: ${(props) => (props.gap ? props.gap + 'px' : '20px')};
`;
