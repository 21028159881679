export const GET_ENDPOINTS = {
  fetchUser: '/user/get-user',
  fetchMemories: '/memory/get-all-web',
  searchMemories: '/memory/search-web',
  getAllLifeAndMemories: '/memory/get-all-life-and-memories',
  fetchLifeScriptFolders: '/life-script/get-all-web',
  getSharedLifeScript: '/life-script/get-shared',
  getSharedMemory: '/memory/get-shared',
};

export const POST_ENDPOINTS = {
  signOut: '/user/sign-out',
  sendOTP: '/user/send-otp',
  checkIsUserExist: '/user/check-is-user-exist',
  verifyOTP: '/user/verify-otp',
  socialUserSignUp: '/user/social-sign-up',
  refreshToken: '/user/refresh-token',
  webAuth: '/user/web-auth',
  verifyExecutorOTP: '/manager/verify-manager-otp',
  sendExecutorOTP: '/manager/send-manager-otp',
  sendLifeScriptByEmail: '/life-script/send-email',
  logInExecutor: '/manager/login-manager',
};

export const PUT_ENDPOINTS = {
  updateUser: '/user/update',
  updateUserForRegister: '/user/update-register',
  updateWebUser: '/user/update-web-auth',
  updateLifeScript: '/life-script/update',
  updateMemory: '/memory/update-web',
};
