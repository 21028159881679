import { AxiosError } from 'axios';
import * as Yup from 'yup';

import { IResponseError } from 'typings/common';
import { REGEXPS } from './reg-exp';

export const ERROR = {
  phoneNumber: 'Please, enter your phone number to log in',
  firstName: 'Please, enter your name',
  lastName: 'Please, enter your last name',
  email: 'Please, enter your email',
  termsAndPrivacy: 'You have to agree with Digilooms Terms of Service and Privacy Policy to create an account',
};

type TError = keyof typeof ERROR;

export const stringRequired = (msg: string) => Yup.string().required(msg);

export const stringRequiredMatches = (field: keyof typeof REGEXPS) =>
  stringRequired(ERROR[field as TError]).matches(REGEXPS[field], ERROR[field as TError]);

export const getErrorData = (error: unknown) => {
  const e = error as IResponseError | AxiosError;
  let response;
  if ('message' in e) {
    response = {
      message: e.message,
      code: e.code,
    };
  } else {
    response = {
      message: e.response.data.message,
      code: e.response.data.code,
    };
  }
  return response;
};
