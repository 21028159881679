import { Icon } from '@components/icon';
import { Loader } from '@components/loader';
import { RecipientsInformationModal } from '@components/recipients-information-modal';
import { ShareVideoModal } from '@components/share/share-video-modal';

import { useShareLifeScriptModal } from './share-life-script-modal.state';

import { PROMISES_AREA } from '@constants/promises-area';

import { IShareLifeScriptModalProps } from './share-life-script-modal.typings';

import { StyledShareLifeScriptModal as Styled } from './share-life-script-modal.styles';

export const ShareLifeScriptModal: React.FC<IShareLifeScriptModalProps> = ({ onClose, folder }) => {
  const {
    isDetailsPressed,
    recipientNames,
    onSendLifeScriptByEmail,
    toggleDetails,
    recipients,
    setRecipients,
    isDisabled,
  } = useShareLifeScriptModal(folder);

  return (
    <>
      {!isDetailsPressed ? (
        <ShareVideoModal onClose={onClose} id={folder.id} isLinkActive={folder.isLinkActive} isLifeScript>
          <Styled.ShareLifeScript>
            <Styled.Header>
              <h5>Share Life Script</h5>
            </Styled.Header>
            {!!recipients.length && (
              <>
                <Styled.Body>
                  <h6>Share with Recipients</h6>
                  <p>{recipientNames}</p>
                  <Styled.Details onClick={toggleDetails}>
                    <span>details</span>
                    <Icon type="arrowGoTo" />
                  </Styled.Details>

                  {recipientNames && (
                    <Styled.SendWrapper>
                      <Styled.Send disabled={isDisabled} onClick={onSendLifeScriptByEmail}>
                        Send
                      </Styled.Send>
                      <Loader area={PROMISES_AREA.sendLifeScriptByEmail} isNotWrapper size={16} />
                    </Styled.SendWrapper>
                  )}
                </Styled.Body>
                <hr />
              </>
            )}
          </Styled.ShareLifeScript>
        </ShareVideoModal>
      ) : (
        <RecipientsInformationModal onBack={toggleDetails} recipients={recipients} setRecipients={setRecipients} />
      )}
    </>
  );
};
