import axios from 'axios';

import { changeData, postData } from '@services/api-service';
import { ICountryResponse } from 'typings/api.typings';
import { GET_COUNTRY_ENDPOINT } from './auth.constants';
import { ISendOTP, IVerifyOTP } from './auth.typings';

export const getCountry = async () => {
  const result = await axios.get<ICountryResponse>(GET_COUNTRY_ENDPOINT);
  return result.data;
};

export const sendOTP = (payload: ISendOTP) => {
  return postData({ requestUrl: 'sendOTP', payload });
};

export const sendExecutorOTP = (payload: ISendOTP) => {
  return postData({ requestUrl: 'sendExecutorOTP', payload });
};

export const sendCodeOTP = (payload: IVerifyOTP) => {
  return postData({ requestUrl: 'verifyOTP', payload });
};

export const checkIsUserExist = (payload: ISendOTP) => {
  return postData({ requestUrl: 'checkIsUserExist', payload });
};

export const sendExecutorCodeOTP = (payload: IVerifyOTP) => {
  return postData({ requestUrl: 'verifyExecutorOTP', payload });
};

export const updateUser = () => {
  return changeData({ requestUrl: 'updateWebUser' });
};

export const updateUserForRegister = (payload: FormData) => {
  return changeData({ requestUrl: 'updateUserForRegister', payload });
};

export const registerUser = (payload: FormData) => {
  return postData({ requestUrl: 'webAuth', payload });
};
