import { FC, memo, useRef } from 'react';

import { TableViewMemory } from '@components/table-view-memory';
import { StyledMemoriesTableView as Styled } from './memories-table-view.styles';
import { IMemoriesTableViewProps } from './memories-table-view.typings';

export const MemoriesTableView: FC<IMemoriesTableViewProps> = memo(({ memories }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Styled.MemoriesTableView ref={containerRef}>
      {memories.map((memory) => (
        <TableViewMemory key={memory.id} memory={memory} />
      ))}
      {memories.length === 1 && (
        <>
          <i />
          <i />
        </>
      )}
      {memories.length === 2 && <i />}
    </Styled.MemoriesTableView>
  );
});

MemoriesTableView.displayName = 'MemoriesTableView';
