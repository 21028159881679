import imageCompression from 'browser-image-compression';
import { useEffect, useRef, useState } from 'react';

import { compressionConfig } from '@constants/images';
import { TInputEvent } from 'typings/common';
import { IUseAvatarPickerProps } from './avatar-picker.typings';

export const useAvatarPicker = ({ value, onChange }: IUseAvatarPickerProps) => {
  const avatar = value;
  const [selectedImg, setSelectedImg] = useState(avatar);
  const [isLoaded, setIsLoaded] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (avatar) {
      inputRef.current!.value = '';
      setSelectedImg(avatar);
      setIsLoaded(true);
    }
  }, [avatar]);

  const onUpload = async (event: TInputEvent) => {
    if (!event.target.files) {
      return;
    }
    setIsLoaded(false);

    const [imageUploaded] = event.target.files;

    const compressedFile = await imageCompression(imageUploaded, compressionConfig);
    const image = URL.createObjectURL(compressedFile);
    setSelectedImg(image);
    setIsLoaded(true);
    onChange(image, compressedFile);
  };

  const onLoadImage = () => setIsLoaded(true);
  const handleImgError = () => setIsLoaded(false);

  return { selectedImg, isLoaded, handleImgError, onLoadImage, inputRef, onUpload, avatar };
};
