import SuperEllipse from 'react-superellipse';

import { Icon } from '@components/icon';
import { ModalVideo } from '@components/modal-video';
import { VideoPlayer } from '@components/video-player';
import { useTableViewMemory } from './table-view-memory.state';
import { StyledTableViewMemory as Styled } from './table-view-memory.styles';
import { ITableViewMemoryProps } from './table-view-memory.typings';

export const TableViewMemory: React.FC<ITableViewMemoryProps> = ({ memory }) => {
  const { isOpen, videoInfo, openPlayer, colsePlayer, toggleMute, setIsPlayingModal } = useTableViewMemory();

  return (
    <>
      {isOpen && (
        <ModalVideo closeMemoryVideoModal={colsePlayer}>
          <VideoPlayer
            isMuted={videoInfo.isMuted}
            onMuteChange={toggleMute}
            videoRef={videoInfo.ref}
            setIsPlaying={setIsPlayingModal}
            isPlaying={videoInfo.isPlaying}
            videoUrl={memory.videoUrl!}
            posterUrl={memory.posterUrl}
          />
        </ModalVideo>
      )}
      <Styled.Memory onClick={openPlayer}>
        <SuperEllipse p1={7} p2={50} key={memory.id}>
          <img src={memory.posterUrl} alt="memory" />
          {<Icon type="play" />}
        </SuperEllipse>
        <span>{memory.date}</span>
      </Styled.Memory>
    </>
  );
};
