export const ROUTES = {
  root: '/',

  auth: '/auth',
  logIn: '/auth/log-in',
  logInExecutor: '/auth/log-in/executor',
  logInExecutorChooseAccount: '/auth/log-in/executor/choose-account',
  logInUser: '/auth/log-in/user',
  signUp: '/auth/sign-up',

  lifeScripts: '/life-scripts',
  profileSettings: '/profile-settings',

  shared: '/shared',
  sharedLifeScript: '/shared/lifeScriptId=:lifeScriptId',
  sharedMemory: '/shared/memoryId=:memoryId',
  inactiveLink: '/shared/inactive-link',

  default: '*',
} as const;

export const checkIsRouteInvalid = (pathname: string, route: string) => pathname === route || pathname === `${route}/`;

export const hideHeaderPaths = [
  ROUTES.auth,
  ROUTES.logIn,
  ROUTES.logInExecutor,
  ROUTES.logInExecutorChooseAccount,
  ROUTES.logInUser,
  ROUTES.signUp,
  ROUTES.inactiveLink,
];

export type TKeysRoutes = keyof typeof ROUTES;
export type TRoutes = typeof ROUTES[TKeysRoutes];
