import { DeviceUUID } from 'device-uuid';
import { trackPromise } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';

import { handleRequestError } from '@constants/alerts';
import { PROMISES_AREA } from '@constants/promises-area';
import { ROUTES } from '@constants/routes';
import { signInWithPopup, UserCredential } from '@firebase/auth';
import { useAppDispatch } from '@hooks/redux';
import { auth } from '@screens/auth/auth-config';
import { saveUser } from '@screens/auth/auth.constants';
import { IUserSignUp } from '@screens/auth/sign-up/sign-up.typings';
import { setIsSignedInBySocial, setSignUpSocial, setToken } from '@store/reducers/auth';
import { FirebaseError } from 'firebase/app';
import { socialSignUp } from './auth-social-buttons.api';
import {
    CANCELLED_POPUP_REQUEST, linkUserByErrorToOtherSocial, POPUP_CLOSED, provider, socialType
} from './auth-social-buttons.constants';
import { TLogIn, TSocialType } from './auth-social-buttons.typings';

export const useAuthSocialButtons = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const signInUser = async (userCredential: UserCredential) => {
    const deviceId = new DeviceUUID().get();

    const [firstName, lastName] = userCredential.user.displayName?.split(' ') || '';

    const userInfo = await trackPromise(
      socialSignUp({
        email: userCredential.user.email,
        firstName,
        lastName,
        type: `${socialType[userCredential.providerId as TSocialType]}Id`,
        socialId: userCredential.user.uid,
        deviceId,
      }),
      PROMISES_AREA.auth
    );

    if (userInfo.user.isRegistered) {
      dispatch(setIsSignedInBySocial());
      saveUser(userInfo, dispatch);
      return navigate(ROUTES.lifeScripts);
    }

    const accessToken = userInfo.accessToken;
    const refreshToken = userInfo.refreshToken;
    dispatch(setToken({ accessToken, refreshToken }));

    const avatar = userCredential.user.photoURL || '';
    const newUserInfo: IUserSignUp = {
      email: userCredential.user.email || '',
      firstName,
      lastName,
      phoneNumber: userInfo.user.phoneNumber || '',
      avatar,
    };

    dispatch(setSignUpSocial(newUserInfo));
    navigate(ROUTES.signUp);
  };

  const onSocialLogIn = (type: TLogIn) => async () => {
    try {
      const userCredential = await signInWithPopup(auth, provider[type]);
      signInUser(userCredential);
    } catch (e) {
      const error = e as FirebaseError;

      const userCredential = await linkUserByErrorToOtherSocial(error);
      if (userCredential) {
        return signInUser(userCredential);
      }

      if (error.code !== POPUP_CLOSED && error.code !== CANCELLED_POPUP_REQUEST) {
        handleRequestError(error);
      }
    }
  };

  return { onSocialLogIn };
};
