import { CONFIG } from '@constants/config';
import { TKeysTypes, TKeysWithoutShareTypes, TSocial } from './share-video-modal.typings';

export const SOCIAL_ITEMS = ['twitter', 'facebook', 'instagram', 'tikTok'] as const;
export const SOCIAL_ITEMS_WITHOUT_SHARE = ['instagram', 'tikTok'] as const;

export const SOCIAL_SHARE_URL: TSocial = {
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  twitter: 'https://twitter.com/intent/tweet?url=',
  instagram: 'https://www.instagram.com/',
  tikTok: 'https://www.tiktok.com/',
};

export const getUrlShare = (id: string, isLifeScript?: boolean, isSocialShare?: boolean) => {
  const { baseUrl: webUrl, apiUrl } = CONFIG;

  const lifeScriptUrl = `${apiUrl}/life-script/redirect-lifescript?id=${id}&webUrl=${webUrl}`;
  const memoryUrl = `${apiUrl}/memory/redirect-memory?id=${id}&webUrl=${webUrl}`;

  if (isLifeScript) {
    if (isSocialShare) {
      return encodeURIComponent(lifeScriptUrl);
    }
    return lifeScriptUrl;
  }
  if (isSocialShare) {
    return encodeURIComponent(memoryUrl);
  }
  return memoryUrl;
};

export const getSocialUrlShare = (item: TKeysTypes, id: string, isLifeScript?: boolean) => {
  if (SOCIAL_ITEMS_WITHOUT_SHARE.includes(item as TKeysWithoutShareTypes)) {
    return SOCIAL_SHARE_URL[item];
  }
  return SOCIAL_SHARE_URL[item] + getUrlShare(id, isLifeScript, true);
};
