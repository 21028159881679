import styled from 'styled-components';

import { media } from '@styles/media';

export const StyledExecutorAccounts = {
  AccountsHeader: styled.h3`
    text-align: center;
    max-width: 272px;
    font-weight: 700;
    font-size: 30px;
    line-height: 140%;
    margin: 90px auto 35px;

    ${media.mmd3} {
      margin: 110px auto 40px;
    }
    ${media.mmd0} {
      margin: 126px auto 45px;
    }
  `,

  AccountsList: styled.ul`
    max-width: 320px;
    margin: 0 auto;

    hr {
      background-color: #2b2b2b;
      height: 1px;
      max-width: 270px;
      margin: 6px auto;
    }

    li {
      cursor: pointer;
      position: relative;
      display: flex;
      font-size: 18px;
      line-height: 21px;
      color: #989898;
      border-radius: 12.0377px;
      transition: background-color ${(prop) => prop.theme.transition}, color ${(props) => props.theme.transition};
      padding: 20px 25px;
      height: 100%;
      width: 100%;

      svg {
        position: absolute;
        top: 50%;
        right: 26px;
        transform: translateY(-50%);
        transition: fill ${(prop) => prop.theme.transition};
        fill: #989898;
      }

      &:hover {
        background-color: #1e1e1e;
        color: #fff;
        svg {
          fill: #fff;
        }
      }
    }
  `,
};
