import styled from 'styled-components';

import { media } from '@styles/media';

export const LifeScriptsLayout = styled.div`
  display: grid;
  grid-template-columns: 3fr 9fr 1.5fr;
  grid-template-rows: 1fr;
  gap: 0px 8%;

  ${media.md1} {
    grid-template-columns: 0.7fr 2fr 0.5fr !important;
    gap: 0px 2%;
  }

  ${media.md5} {
    grid-template-columns: 1.2fr 2fr 0.5fr !important;
    gap: 0px 2%;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1.2fr 2fr 30px !important;
  }
`;
