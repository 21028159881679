import Drawer from 'react-modern-drawer';
import styled from 'styled-components';

import { Logo } from '@components/logo';
import { ContentContainer } from '@styles/components/container';
import { LifeScriptsLayout } from '@styles/components/life-scripts-layout';
import { media } from '@styles/media';

export const StyledHeader = {
  Drawer: styled(Drawer)`
    background-color: ${(props) => props.theme.colors.body} !important;
    padding: 22px 20px 0px 20px;
    width: 180px !important;
    overflow: hidden !important;
    & > div:first-child {
      margin-bottom: 10px;
    }
  `,

  Layout: styled(LifeScriptsLayout)`
    align-items: center;
    grid-template-rows: 86px;
    justify-items: self-end;
  `,

  Logo: styled(Logo)`
    justify-self: start;
    margin: 0;
    margin-left: 20px;
    span {
      font-size: 20px;
      line-height: 15.5px;
    }
  `,

  ContentContainer: styled(ContentContainer)`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 10px;
    align-items: center;
    justify-items: end;

    ${media.md1} {
      display: flex;
    }
  `,

  SwitchView: styled.div`
    min-width: 68px;
    display: flex;

    svg {
      max-width: 18px;
    }

    button {
      border-radius: 7px;
    }
  `,

  BurgerMenu: styled.div`
    height: 20px;
    width: 20px;
    cursor: pointer;
    svg {
      transition: filter ${(props) => props.theme.transition};
      fill: ${(props) => props.theme.colors.white};
      &:hover {
        filter: brightness(0.7);
      }
    }
  `,
};
