import styled from 'styled-components';

import { ButtonIcon } from '@components/buttons/button-icon';

export const StyledTextFieldPhoneForm = {
  Form: styled.form`
    width: 100%;
    height: 70px;
  `,

  SubmitField: styled.div`
    position: relative;
  `,

  Button: styled(ButtonIcon)<{ isFocused: boolean }>`
    z-index: 2;
    position: absolute;
    right: 13px;
    top: 0;

    &:disabled,
    &[disabled] {
      background-color: inherit;
      color: inherit;
      border: inherit;
      cursor: auto;
    }

    &:hover:enabled {
      svg {
        opacity: 0.4;
      }
    }

    svg {
      transition: all ${(props) => props.theme.transition};
      fill: ${(props) => (props.isFocused ? '#000' : '#fff')};
    }
  `,
};
