import { useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';

import { IOption } from 'typings/common';
import { IUseDropdown } from './dropdown.typings';

export const useDropdown = ({ value, onChange, options }: IUseDropdown) => {
  const [inputValue, setInputValue] = useState('');

  const onInputValueChange = (value: string) => {
    value.length < 6 && setInputValue(value);
  };

  const handleChange = (newValue: SingleValue<string | IOption> | MultiValue<string | IOption>) => {
    onChange(newValue as IOption);
  };

  const getValue = () => {
    if (typeof value === 'string' || value instanceof String) {
      return options.find((currentOption) => currentOption.value === value) || '';
    }
    return value;
  };

  return {
    inputValue,
    onInputValueChange,
    handleChange,
    getValue,
  };
};
