import styled from 'styled-components';

import { LoaderWithoutArea } from '@components/loader/loader-without-area';

export const StyledVideoPlayer = {
  Player: styled.div`
    position: relative;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    svg {
      filter: drop-shadow(0px 1px 14px rgba(0, 0, 0, 0.35));
    }
  `,

  Loader: styled(LoaderWithoutArea)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `,

  Controls: styled.div`
    width: 100%;
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;

    left: 0;
    bottom: 30px;

    span {
      margin-top: 10px;
    }
  `,

  ActionIcon: styled.div`
    height: 30px !important;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 22px;
      width: 22px;
    }
  `,

  ControlsTop: styled.div`
    width: 82%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;

    svg {
      cursor: pointer;
    }
  `,

  RangeWrapper: styled.div<{ isVisible: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 82%;

    div {
      transition: opacity ${(props) => props.theme.transition};
      opacity: ${(props) => (props.isVisible ? '1' : '0')};
      position: absolute;
      top: -25px;
    }

    span {
      color: #fff;
      margin-top: -20px;
      font-weight: 500;
      font-size: 15px;
    }
  `,

  Rage: styled.input`
    -webkit-appearance: none !important;
    background: #fff;
    border-radius: 20px;
    height: 3px;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-thumb {
      position: relative;
      -webkit-appearance: none;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      z-index: 10000000;
      background: #ffffff;
      margin-top: -6px;
      cursor: pointer;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 3px;
      cursor: pointer;
      background: #fff;
      border-radius: 20px;
    }

    &::-moz-range-progress {
      background: white;
    }

    &::-moz-range-thumb {
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background: #ffffff;
      cursor: pointer;
    }

    &::-moz-range-track {
      width: 100%;
      height: 3px;
      cursor: pointer;
      background: #fff;
      border-radius: 20px;
    }
  `,
};
