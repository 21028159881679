import styled from 'styled-components';

import { media } from '@styles/media';

export const StyledCodeInput = {
  Header: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;

    margin: 80px 0 40px;

    ${media.mmd3} {
      margin: 100px 0 50px;
    }

    ${media.mmd0} {
      margin: 125px 0 59px;
    }

    h3 {
      font-weight: 700;
      font-size: 26px;
      line-height: 17px;
      letter-spacing: 0.28px;
    }
  `,

  Wrapper: styled.div`
    max-width: 310px;
    margin: 0 auto;

    & > div {
      gap: 10px;
    }

    input {
      border-radius: 16px;
      width: 100%;
      margin: 0;
      height: 70px;
      background-color: #1e1e1e;
      box-shadow: none;
      border: none;
      color: #fff;

      &:valid {
        background-color: #fff;
        color: #000;
      }

      transition: background-color ${(props) => props.theme.transition};
    }
  `,
};
