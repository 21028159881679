import { ILifeScriptFolder } from '@components/life-script-folders/life-script-folders.typings';
import { IMemoriesPreview } from '@components/memories/memories.typings';
import { IMemoriesMeta } from 'typings/api.typings';
import { IState } from './life-scripts.typings';

export const lifeScriptsInitialState = {
  memories: {
    items: [],
    meta: {} as IMemoriesMeta,
    page: 1,
  } as IState<IMemoriesPreview, IMemoriesMeta>,
  lifeScripts: [] as ILifeScriptFolder[],
  isNeedToReverseScroll: false,
  searchedMemoryPage: 1,
};
