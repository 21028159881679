import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserSignUp } from '@screens/auth/sign-up/sign-up.typings';
import { authInitialState, initialSignUpSocial } from './auth.constants';
import { ITokensInfo, IUserInfo } from './auth.typings';

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<Partial<IUserInfo>>) => {
      state.user = { ...state.user, ...action.payload };
    },
    setToken: (state, action: PayloadAction<ITokensInfo>) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    setSignUpSocial: (state, action: PayloadAction<IUserSignUp>) => {
      state.signUpSocial = action.payload;
    },
    setIsAllowEnter: (state, action: PayloadAction<boolean>) => {
      state.isAllowEnter = action.payload;
    },
    clearSignUpSocial: (state) => {
      state.signUpSocial = initialSignUpSocial;
    },
    setIsSignedInBySocial: (state) => {
      state.isSignedInBySocial = true;
    },
    logOut: () => {
      return authInitialState;
    },
  },
});

const { actions, reducer } = authSlice;
export const {
  setUserInfo,
  setIsSignedInBySocial,
  setToken,
  logOut,
  setSignUpSocial,
  clearSignUpSocial,
  setIsAllowEnter,
} = actions;
export { reducer as authReducer };
