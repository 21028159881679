import { ROUTES } from '@constants/routes';

export const userRoutes = [
  {
    route: ROUTES.profileSettings,
    label: 'Profile Settings',
  },
];

export const getProfileRoutes = (isExecutor: boolean) => {
  if (isExecutor) {
    return [];
  }
  return userRoutes;
};
