import { useMemo } from 'react';
import NumberFormat from 'react-number-format';

import { Dropdown } from '@components/dropdown';
import { TextFieldRef } from '../text-field/text-field';
import {
    CustomControl, CustomMenuList, CustomNoOptionsMessage, CustomOption, dropdownStyles,
    dropdownTheme, getCountryCodes
} from './text-field-phone.constants';
import { StyledTextFieldPhone as Styled } from './text-field-phone.styles';
import { ITextFieldPhoneProps } from './text-field-phone.typings';

export const TextFieldPhone: React.FC<ITextFieldPhoneProps> = (props) => {
  const { isDisabled, isAutoFocus, selectedCountry, maxMenuHeight, onChangeCountry, isFlat, ...restProps } = props;

  const countries = useMemo(() => getCountryCodes(), []);

  return (
    <Styled.PhoneNumber isFlat={isFlat}>
      <NumberFormat
        isDisabled={isDisabled}
        autoFocus={isAutoFocus}
        customInput={TextFieldRef}
        max={13}
        min={1}
        format="## ### ## ## ###"
        {...restProps}
      />

      <Dropdown
        icon={'selectPhoneNumberArrow'}
        maxMenuHeight={maxMenuHeight || 200}
        stylesConfig={dropdownStyles()}
        onChange={onChangeCountry}
        value={selectedCountry}
        isDisabled={isDisabled}
        options={countries}
        components={{
          Option: CustomOption,
          MenuList: CustomMenuList,
          NoOptionsMessage: CustomNoOptionsMessage,
          Control: CustomControl,
        }}
        theme={dropdownTheme}
      />
    </Styled.PhoneNumber>
  );
};
