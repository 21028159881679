import { useFormik } from 'formik';
import { useMemo, useState } from 'react';

import countries from '@constants/countries.json';
import { useAuth } from '@hooks/useAuth';
import { IOption } from 'typings/common';
import {
    getPayload, getProfileSettingsInitialValues, profileSettingsValidationSchema
} from './profile-settings-form.constants';
import {
    IProfileSettingsForm, IProfileSettingsFormProps, TProfileSettingsSubmit
} from './profile-settings-form.typings';

export const useProfileSettingsForm = ({ onSubmit }: IProfileSettingsFormProps) => {
  const { user } = useAuth();
  const [avatarFile, setAvatarFile] = useState<File>();
  const [countryCode, phoneNumberWithoutCode] = user.phoneNumber?.split(' ') || '';
  const [isSubmitting, setIsSubmitting] = useState(false);
  

  const beforeSubmit: TProfileSettingsSubmit = async (values) => {
    const payload = getPayload(values, avatarFile);
    setIsSubmitting(true)
    await onSubmit(payload, values);
    setIsSubmitting(false)
  };

  const form = useFormik<IProfileSettingsForm>({
    initialValues: getProfileSettingsInitialValues(user, phoneNumberWithoutCode),
    validationSchema: profileSettingsValidationSchema,
    onSubmit: beforeSubmit,
  });

  const onAvatarChange = (image: string, imageFile: File) => {
    form.setFieldValue('avatar', image);
    setAvatarFile(imageFile);
  };

  const option = useMemo(() => countries.find((country) => country.key === countryCode)!, [countryCode]);

  const countryOption: IOption = {
    label: option?.key,
    value: option?.text,
  };

  return {
    countryOption,
    countryCode,
    form,
    user,
    onAvatarChange,
    isSubmitting,
  };
};
