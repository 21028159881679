import { useAppSelector } from './redux';

export const useAuth = () => {
  const { user, accessToken, refreshToken } = useAppSelector((store) => store.auth);
  const isAuth = accessToken && refreshToken && user?.firstName;

  return {
    isAuth,
    user,
  };
};
