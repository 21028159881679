import { useEffect } from 'react';

import { codeInitialValues, COUNT_CODE } from './code-input.constants';
import { ICodeInputProps } from './code-input.typings';

export const useCodeInput = (props: ICodeInputProps) => {
  const { setIsWaitingCode, verifyOTP, codeState, disabledState } = props;
  const [code, setCode] = codeState;
  const [isDisabled, setIsDisabled] = disabledState;

  const onGoBack = () => {
    setIsWaitingCode(false);
    setCode(codeInitialValues);
  };

  useEffect(() => {
    return () => {
      setIsWaitingCode(false);
    };
  }, []);

  useEffect(() => {
    if (code[COUNT_CODE - 1]) {
      setIsDisabled(true);
      const newCode = code.join('');
      verifyOTP(newCode);
    }
  }, [code]);

  const onChange = (value: string | string[], index: number, values: string[]) => {
    setCode(values);
  };

  return {
    onGoBack,
    isDisabled,
    code,
    onChange,
  };
};
