import styled from 'styled-components';

import { ButtonSmall } from '@components/buttons/button-small';
import { media } from '@styles/media';
import { StyledAuth } from '../auth.styles';

export const StyledSignUp = {
  SignUp: styled(StyledAuth.Section)`
    max-width: 964px;
    margin: 57px auto 30px !important;
    
    ${media.mmd1} {
      margin: -47px auto 30px;
    }

    ${media.md3} {
      overflow: hidden;
    }
  `,

  Header: styled(StyledAuth.Header)`
    position: relative !important;
    opacity: 1;
    visibility: visible;
    margin-bottom: 45px;
  `,

  Submit: styled(ButtonSmall)`
    font-size: 16px;
    line-height: 20px;
    width: 115px !important;
    height: 55px !important;
  `,

  PrivatePolicy: styled.div`
    position: relative;
    span {
      z-index: 1000;
      position: absolute;
      left: 0;
      top: 50px;
    }
  `,
};
