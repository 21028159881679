import styled from 'styled-components';

export const StyledTextField = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
  `,

  TextField: styled.input<{ isFocused?: boolean }>`
    transition: background-color ${(props) => props.theme.transition}, color ${(props) => props.theme.transition};
    width: 100%;
    padding: 26px 26px 24px;
    background-color: ${(props) => (props.isFocused ? '#fff' : '#1e1e1e')};
    color: ${(props) => (props.isFocused ? '#000' : '#fff')};
    height: 100%;
    font-size: 16.6px;
    border-radius: 16px;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }

    &::placeholder {
      color: #c0c0c0;
    }
  `,
};
