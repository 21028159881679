import { persistReducer } from 'redux-persist';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { appReducer } from './reducers/app';
import { authReducer } from './reducers/auth';
import { lifeScriptsReducer } from './reducers/life-scripts';

const config = {
  key: 'root',
  storage,
  blacklist: ['lifeScripts'],
};

const combinedReducer = combineReducers({
  lifeScripts: lifeScriptsReducer,
  auth: authReducer,
  app: appReducer,
});

const reducer = (state: any, action: any) => {
  if (action.type === 'auth/logOut') {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export const persistedReducer = persistReducer(config, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
