import * as Yup from 'yup';

import { stringRequired } from '@constants/errors';
import { ITextFieldPhoneFormValues } from './text-field-phone-form.typings';

export const initialValues: ITextFieldPhoneFormValues = {
  phoneNumber: '',
};

export const validationSchema = Yup.object<Record<keyof ITextFieldPhoneFormValues, Yup.AnySchema>>({
  phoneNumber: stringRequired('Please, enter your phone number'),
});
