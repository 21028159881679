import styled from 'styled-components';

export const StyledInactiveLink = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: -20px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-weight: 700;

  h3 {
    margin-top: 15px;
    font-size: 30px;
  }

  h2 {
    margin-top: 15px;
    font-size: 35px;
  }
`;

export const StyledGoBack = styled.div`
  margin-top: 15px;
  background-color: #fff;
  color: #000;
  padding: 20px;
  border-radius: 14px;
  transition: filter ${(props) => props.theme.transition};

  &:hover {
    filter: brightness(0.7);
  }
`;
