import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ILocationState } from './life-scripts.typings';

export const useLifeScriptsLocationState = () => {
  const { state } = useLocation();
  const locationState = state as ILocationState;
  const isNewUser = !!locationState?.isNewUser;
  const isExecutor = !!locationState?.isExecutor;
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(isNewUser);
  const [isExecutorModalOpen, setIsExecutorModalOpen] = useState(isExecutor);

  return {
    isNewUserModalOpen,
    setIsNewUserModalOpen,
    isExecutorModalOpen,
    setIsExecutorModalOpen,
  };
};
