import { ButtonLarge } from '@components/buttons/button-large';
import { socialButtons } from './auth-social-buttons.constants';
import { useAuthSocialButtons } from './auth-social-buttons.state';
import { StyledAuthSocialButtons as Styled } from './auth-social-buttons.styles';
import { IAuthSocialButtonsProps } from './auth-social-buttons.typings';

export const AuthSocialButtons: React.FC<IAuthSocialButtonsProps> = ({ title }) => {
  const { onSocialLogIn } = useAuthSocialButtons();
  return (
    <Styled.Social>
      <h4>{title}</h4>

      <Styled.SocialWrapper>
        {socialButtons.map((button) => (
          <ButtonLarge
            key={button.type}
            icon={button.type}
            color={button.color}
            isBordered={button.type === 'apple'}
            onClick={onSocialLogIn(button.type)}
          >
            {button.type}
          </ButtonLarge>
        ))}
      </Styled.SocialWrapper>
    </Styled.Social>
  );
};
