import InfiniteScroll from 'react-infinite-scroll-component';

import { AsideRoutes } from '@components/aside-routes';
import { EmptyVideos } from '@components/empty-videos';
import { LifeScriptFolders } from '@components/life-script-folders';
import { Loader } from '@components/loader';
import { LoaderWithoutArea } from '@components/loader/loader-without-area';
import { MemoriesListView } from '@components/memories/memories-list-view';
import { MemoriesTableView } from '@components/memories/memories-table-view';
import { MobileModal } from '@components/mobile-modal';
import { ModalExecutor } from '@components/modal-executor';
import { IMAGES } from '@constants/images';
import { PROMISES_AREA } from '@constants/promises-area';
import { MEMORIES_LOADER_SIZE, NEW_USER_MODAL_TEXT } from './life-scripts.constants';
import { useLifeScripts } from './life-scripts.state';
import { StyledLifeScripts as Styled } from './life-scripts.styles';

export const LifeScripts: React.FC = () => {
  const { constants, func } = useLifeScripts();

  return (
    <>
      <LoaderWithoutArea isLoading={constants.isFetching} />
      <Loader area={PROMISES_AREA.fetchMemories} />

      {constants.isOpenNewUserModal && <MobileModal onClose={func.onCloseNewUserModal} text={NEW_USER_MODAL_TEXT} />}
      {constants.isOpenExecutorModal && <ModalExecutor onClose={func.onCloseExecutorModal} />}

      <Styled.Page>
        <Styled.ColumnFolders isFoldersReceived={constants.isFoldersReceived} isTablet={constants.isTablet}>
          <h4>Life Scripts</h4>
          {constants.isFoldersReceived ? (
            <LifeScriptFolders folders={constants.lifeScriptsFolders} />
          ) : (
            <Styled.EmptyFolders p1={7} p2={50}>
              <img src={IMAGES.emptyFolders} alt="empty-folders" />
              <p>
                You have no life scripts <br />
                Record your first video in the app
              </p>
            </Styled.EmptyFolders>
          )}
        </Styled.ColumnFolders>

        <Styled.ColumnMemories isMemoriesReceived={constants.isMemoriesReceived} isTablet={constants.isTablet}>
          <Styled.LoaderScrollTop
            isNotWrapper
            area={PROMISES_AREA.fetchMemoriesScrollTop}
            size={MEMORIES_LOADER_SIZE}
          />

          {constants.isMemoriesReceived ? (
            <Styled.ContentContainer
              ref={constants.containerRef}
              scrollbarWidth={constants.scrollbarWidth}
              id="scrollable-memories"
            >
              <InfiniteScroll
                dataLength={constants.memories.items.length}
                style={{ overflow: 'initial' }}
                onScroll={func.handleScrollMemoriesTop}
                next={func.fetchMoreMemories}
                scrollableTarget="scrollable-memories"
                hasMore={constants.memories.meta.hasNextPage}
                loader={<Loader isWithoutArea isNotWrapper size={MEMORIES_LOADER_SIZE} />}
              >
                {constants.selectedView === 'table' ? (
                  <MemoriesTableView memories={constants.memories.items} />
                ) : (
                  <MemoriesListView memories={constants.memories.items} containerRef={constants.containerRef} />
                )}
              </InfiniteScroll>
            </Styled.ContentContainer>
          ) : (
            <EmptyVideos />
          )}
        </Styled.ColumnMemories>

        {!constants.isTablet && <AsideRoutes />}
      </Styled.Page>
    </>
  );
};
