import { useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useLocation, useNavigate } from 'react-router-dom';

import { alertError, handleRequestError } from '@constants/alerts';
import { PROMISES_AREA } from '@constants/promises-area';
import { ROUTES } from '@constants/routes';
import { useAppDispatch } from '@hooks/redux';
import { setToken, setUserInfo } from '@store/reducers/auth';
import {
    IExecutorSendOTPResponse, ILogInExecutorPayload, ILogInExecutorResponse
} from '../log-in-executor.typings';
import { logInExecutor } from './executor-accounts.api';

export const useExecutorAccounts = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const state = location.state as IExecutorSendOTPResponse;
  const isDataReceived = !!state?.managerPhoneNumber;

  useEffect(() => {
    !isDataReceived && navigate(ROUTES.logInExecutor, { replace: true });
  }, [state]);

  const saveExecutor = ({ accessToken, refreshToken, user }: ILogInExecutorResponse) => {
    if (!accessToken || !refreshToken) {
      return alertError('Something wrong');
    }
    dispatch(setUserInfo({ ...user, isExecutor: true }));
    dispatch(setToken({ accessToken, refreshToken }));
  };

  const chooseUser = (id: string) => async () => {
    const payload: ILogInExecutorPayload = { chosenUserId: id, phoneNumber: state.managerPhoneNumber };
    try {
      const res = await trackPromise(logInExecutor(payload), PROMISES_AREA.logInExecutor);
      saveExecutor(res);
    } catch (error) {
      handleRequestError(error);
    }
  };

  return {
    isDataReceived,
    state,
    chooseUser,
  };
};
