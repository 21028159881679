import { useFormik } from 'formik';
import { useState } from 'react';

import { getPhoneNumberWithSpace } from '@screens/auth/auth.constants';
import { TInputBlur } from 'typings/common';
import { initialValues, validationSchema } from './text-field-phone-form.constants';
import {
    ITextFieldPhoneFormValues, TTextFieldPhoneFormSubmit
} from './text-field-phone-form.typings';

export const useTextFieldPhoneForm = (onSubmit: TTextFieldPhoneFormSubmit, countryCode: string) => {
  const [isFocused, setIsFocused] = useState(false);

  const beforeSubmit = (values: ITextFieldPhoneFormValues) => {
    onSubmit({ phoneNumber: getPhoneNumberWithSpace(countryCode, values.phoneNumber) });
  };

  const form = useFormik<ITextFieldPhoneFormValues>({
    initialValues,
    validationSchema,
    onSubmit: beforeSubmit,
  });

  const onFocus = () => setIsFocused(true);

  const onBlur = (event: TInputBlur) => {
    form.handleBlur(event);
    setIsFocused(false);
  };

  return {
    form,
    isFocused,
    onFocus,
    onBlur,
  };
};
