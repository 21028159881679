import SuperEllipse from 'react-superellipse';

import { ButtonIcon } from '@components/buttons/button-icon';
import { Modal } from '@components/modal';
import { ModalVideo } from '@components/modal-video';
import { ShareVideoModal } from '@components/share/share-video-modal';
import { VideoPlayer } from '@components/video-player';
import { IMAGES } from '@constants/images';
import { useListViewMemory } from './list-view-memory.state';
import { StyledListViewMemory as Styled } from './list-view-memory.styles';
import { IListViewMemoryProps, IVideoInfo } from './list-view-memory.typings';

export const ListViewMemory: React.FC<IListViewMemoryProps> = (props) => {
  const { memory, isFirst, isWithoutShare, containerRef } = props;
  const {
    memoryRef,
    closeMemoryPhotoModal,
    getMemoryPhotoURL,
    onMemoryPhotoClick,
    openMemoryPhoto,
    openMemoryVideoModal,
    closeMemoryVideoModal,
    videoInfo,
    setIsPlaying,
    setIsPlayingModal,
    toggleMute,
    isOpenShareModal,
    toggleOpenShareModal,
  } = useListViewMemory({
    memory,
    isWithoutShare,
    containerRef,
  });
  const { video, modal: modalVideo } = videoInfo;

  const videoPlayer = (currentVideo: IVideoInfo, executePlaying: (isPlaying: boolean) => void) => (
    <VideoPlayer
      isMuted={videoInfo.isMuted}
      onMuteChange={toggleMute}
      videoRef={currentVideo.ref}
      setIsPlaying={executePlaying}
      isPlaying={currentVideo.isPlaying}
      videoUrl={memory.videoUrl!}
      posterUrl={memory.posterUrl}
      startTime={videoInfo.currentTime}
    />
  );

  return (
    <Styled.Memory ref={memoryRef} id={isFirst ? 'firstMemory' : ''}>
      <Styled.PlayerWrapper>
        {modalVideo.isOpen && (
          <ModalVideo closeMemoryVideoModal={closeMemoryVideoModal}>
            {videoPlayer(modalVideo, setIsPlayingModal)}
          </ModalVideo>
        )}

        <SuperEllipse p1={7} p2={50}>
          {videoPlayer(video, setIsPlaying)}
        </SuperEllipse>
        <ButtonIcon icon="zoomIn" onClick={openMemoryVideoModal} />
      </Styled.PlayerWrapper>

      <div>
        {isOpenShareModal && (
          <ShareVideoModal onClose={toggleOpenShareModal} id={memory.id} isLinkActive={memory.isLinkActive} />
        )}
        <Styled.DescriptionWrapper>
          <SuperEllipse p1={7} p2={50}>
            <Styled.Description>
              <Styled.DescriptionHeader>
                <span>{memory.date}</span>
                {!isWithoutShare && <ButtonIcon icon="share" onClick={toggleOpenShareModal} />}
              </Styled.DescriptionHeader>

              <h2>{memory.title}</h2>
              <Styled.DescriptionText>
                <p>{memory.description}</p>
              </Styled.DescriptionText>
            </Styled.Description>
          </SuperEllipse>
        </Styled.DescriptionWrapper>

        <Styled.MemoryPhotosWrapper p1={7} p2={50}>
          {openMemoryPhoto && (
            <Modal onClose={closeMemoryPhotoModal} isBlackBackdrop isForbidOutsideClick>
              <SuperEllipse p1={11} p2={61}>
                <Styled.ModalPhoto>
                  <img src={getMemoryPhotoURL(openMemoryPhoto)} alt="memory-photo" />
                  <Styled.CloseButton isWithBg icon="cross" onClick={closeMemoryPhotoModal} />
                </Styled.ModalPhoto>
              </SuperEllipse>
            </Modal>
          )}

          {memory.memoryPhoto?.length ? (
            <Styled.MemoryPhotos
              isWithScrollbar={memory.memoryPhoto.length > 6}
              isMoreThanThree={memory.memoryPhoto.length > 3}
            >
              {memory.memoryPhoto.map((photo) => (
                <Styled.Photo key={photo.id} url={photo.url!} p1={1} p2={32} onClick={onMemoryPhotoClick(photo.id)} />
              ))}
              <Styled.Photo $isPseudo p1={1} p2={32} />
              <Styled.Photo $isPseudo p1={1} p2={32} />
            </Styled.MemoryPhotos>
          ) : (
            <Styled.EmptyMemoryPhotos>
              <div>
                <img src={IMAGES.emptyVideos} alt="no-photos" />
                <p>There are no photos in here</p>
              </div>
            </Styled.EmptyMemoryPhotos>
          )}
        </Styled.MemoryPhotosWrapper>
      </div>
    </Styled.Memory>
  );
};
