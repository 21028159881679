import { useEffect, useRef, useState } from 'react';

import { TInputEvent } from 'typings/common';
import { time_convert } from './video-player.constants';
import { IUseVideoPlayerProps } from './video-player.typings';

export const useVideoPlayer = (props: IUseVideoPlayerProps) => {
  const { isPlaying, videoRef, setIsPlaying, startTime } = props;

  const tooltipRef = useRef<HTMLDivElement>(null);
  const [progress, setProgress] = useState<number>(startTime?.progress || 0);
  const [isVideoLoading, setIsVideoLoading] = useState(isPlaying);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isPlaying) {
      return playVideo();
    }
    stopVideo();
  }, [isPlaying]);

  useEffect(() => {
    if (videoRef.current && startTime?.time) {
      videoRef.current.currentTime = startTime?.time;
    }
  }, [startTime]);

  const playVideo = () => {
    videoRef.current?.play();
    setIsPlaying(true);
  };

  const stopVideo = () => {
    setIsPlaying(false);
    videoRef.current?.pause();
  };

  const handleVideoEnd = () => {
    stopVideo();
    setIsVideoLoading(false);
  };

  const handleAction = () => {
    if (isPlaying) {
      return stopVideo();
    }
    playVideo();
  };

  const onPause = () => {
    setIsPlaying(false);
  };

  const handleOnTimeUpdate = () => {
    const progress = (videoRef.current!.currentTime / videoRef.current!.duration) * 100;
    setProgress(progress);
  };

  const handleVideoProgress = (event: TInputEvent) => {
    const manualChange = Number(event.target.value);
    const toolTip = tooltipRef.current;
    if (toolTip) {
      toolTip.innerHTML = `<span>${time_convert(Math.round(videoRef.current!.currentTime))}</span>`;
      toolTip.style.left = `calc(${manualChange}% + (${-12 - manualChange * 0.15}px))`;
    }
    const time = (videoRef.current!.duration / 100) * manualChange;
    if (time) {
      videoRef.current!.currentTime = time;
    }
    setProgress(manualChange);
  };

  const handleRangeMouseDown = () => {
    setIsVisible(true);
  };

  const handleRangeMouseUp = () => {
    setIsVisible(false);
  };

  const handleLoadStart = () => {
    setIsVideoLoading(true);
  };

  const handleLoadEnd = () => {
    setIsVideoLoading(false);
  };

  return {
    handlers: {
      handleOnTimeUpdate,
      handleVideoProgress,
      handleAction,
      handleRangeMouseDown,
      handleRangeMouseUp,
      handleLoadStart,
      handleLoadEnd,
      handleVideoEnd,
      onPause,
    },
    constants: {
      videoRef,
      tooltipRef,
      isPlaying,
      progress,
      isVisible,
      isVideoLoading,
    },
  };
};
