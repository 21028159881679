import { ComponentType, JSXElementConstructor, ReactElement } from 'react';
import {
    components, ControlProps, GroupBase, MenuListProps, NoticeProps, OptionProps, StylesConfig,
    Theme
} from 'react-select';

import countries from '@constants/countries.json';
import { IOption } from 'typings/common';
import { StyledTextFieldPhone as Styled } from './text-field-phone.styles';

export const dropdownStyles = (): StylesConfig<string | IOption, boolean, GroupBase<IOption>> => ({
  menuList: (base) => ({
    ...base,
    borderRadius: '16px',
    scrollbarWidth: 'none',

    '::-webkit-scrollbar': {
      width: '0px',
    },
  }),

  menu: (base) => ({
    ...base,
    borderRadius: '16px',
    left: '-15px',
    width: '324px',
    padding: '0px',
    height: 'auto',

    '& > div > div': {
      color: '#red !important',
      padding: '0px',

      fontWeight: 500,
    },
    '& > div': {
      color: '#red !important',
      padding: '0px',
      fontWeight: 500,
    },
    marginTop: '25px',
    backgroundColor: '#1e1e1e',
  }),

  option: (base) => ({
    ...base,
    backgroundColor: base.backgroundColor === 'transparent' ? '#1e1e1e' : base.backgroundColor,
  }),

  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'transform .3s ease !important',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    padding: 0,
    margin: '0 12px 0 0',
  }),
});

export const getCountryCodes = () =>
  countries.map((country) => ({
    value: `${country.text}`,
    label: country.key,
  }));

export const CustomOption: ComponentType<OptionProps<string | IOption, boolean, GroupBase<IOption>>> | undefined = ({
  ...props
}) => {
  const { onMouseMove, onMouseOver, ...restProps } = props.innerProps;
  const newProps = Object.assign(props, { innerProps: restProps });
  const data = newProps.data as IOption;

  return (
    <components.Option {...newProps}>
      <Styled.Option>
        {data.label} {data.value}
      </Styled.Option>
    </components.Option>
  );
};

export const CustomControl: ComponentType<ControlProps<string | IOption, boolean, GroupBase<IOption>>> | undefined = ({
  children,
  ...props
}) => {
  return <components.Control {...props}>{children}</components.Control>;
};

export const CustomMenuList:
  | ComponentType<MenuListProps<string | IOption, boolean, GroupBase<IOption>>>
  | undefined = ({ children, ...props }) => {
  const child = children as ReactElement<any, string | JSXElementConstructor<any>>;

  return (
    <components.MenuList {...props}>
      <Styled.MenuList isNoOptions={child?.props?.children === 'No options'}>{children}</Styled.MenuList>
    </components.MenuList>
  );
};

export const CustomNoOptionsMessage:
  | ComponentType<NoticeProps<string | IOption, boolean, GroupBase<IOption>>>
  | undefined = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <Styled.NoOptionsMessage>Nothing Found</Styled.NoOptionsMessage>
    </components.NoOptionsMessage>
  );
};

export const dropdownTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#30A14E',
    primary25: '#1e1e1e',
  },
});
