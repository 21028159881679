import { FC } from 'react';
import { createPortal } from 'react-dom';

import { useModal } from './modal.state';
import { StyledModal as Styled } from './modal.styles';
import { IModalProps } from './modal.typings';

const modalRoot = document.querySelector('#modal-root')!;

export const Modal: FC<IModalProps> = (props) => {
  const { children, onClose, isBlackBackdrop, isForbidOutsideClick } = props;
  const { handleBackdropClick } = useModal({ onClose, isForbidOutsideClick });

  return createPortal(
    <Styled.Backdrop onClick={handleBackdropClick} isBlackBackdrop={isBlackBackdrop}>
      <Styled.Modal>{children}</Styled.Modal>
    </Styled.Backdrop>,
    modalRoot
  );
};
