import { TextFieldForm } from '../text-field-form';
import { useTextFieldPhoneForm } from './text-field-phone-form.state';
import { StyledTextFieldPhoneForm as Styled } from './text-field-phone-form.styles';
import { ITextFieldPhoneFormProps } from './text-field-phone-form.typings';

export const TextFieldPhoneForm: React.FC<ITextFieldPhoneFormProps> = (props) => {
  const {
    form: { handleSubmit, errors, touched, isValid, getFieldProps },
    onBlur,
    onFocus,
    isFocused,
  } = useTextFieldPhoneForm(props.onSubmit, props.selectedCountry.label);

  return (
    <Styled.Form onSubmit={handleSubmit}>
      <Styled.SubmitField>
        <Styled.Button icon="forward" isSubmit isFocused={isFocused} isDisabled={!isValid} />

        <TextFieldForm
          {...props}
          {...getFieldProps('phoneNumber')}
          field={{ touched: touched.phoneNumber, errorMessage: errors.phoneNumber }}
          isPhoneNumber
          maxMenuHeight={props.maxMenuHeight}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </Styled.SubmitField>
    </Styled.Form>
  );
};
