import { FC, memo } from 'react';

import { ListViewMemory } from '@components/list-view-memory';
import { StyledMemoriesListView as Styled } from './memories-list-view.styles';
import { IMemoriesListViewProps } from './memories-list-view.typings';

export const MemoriesListView: FC<IMemoriesListViewProps> = memo(({ memories, isWithoutShare, containerRef }) => {
  return (
    <Styled.MemoriesListView>
      {memories.map((memory, index) => (
        <ListViewMemory
          memory={memory}
          key={memory.id}
          isFirst={index === 0}
          isWithoutShare={isWithoutShare}
          containerRef={containerRef}
        />
      ))}
    </Styled.MemoriesListView>
  );
});
MemoriesListView.displayName = 'MemoriesListView';
