import SuperEllipse from 'react-superellipse';
import styled, { css } from 'styled-components';

import { Loader } from '@components/loader';
import { ContentContainer } from '@styles/components/container';
import { LifeScriptsLayout } from '@styles/components/life-scripts-layout';

export const StyledLifeScripts = {
  Page: styled(LifeScriptsLayout)`
    align-items: start;
    justify-items: start;
    min-height: calc(100vh - 145px);

    h4 {
      font-weight: 400;
      font-size: inherit;
      line-height: 16px;
      color: #7b7b7b;
      margin: 0 0 9px 19px;
    }
  `,

  ColumnFolders: styled.section<{ isFoldersReceived: boolean; isTablet: boolean }>`
    overflow: hidden;
    padding-bottom: ${(props) => (props.isFoldersReceived ? '0px' : '90px')};
    max-width: 279px;
    width: 100%;
    height: 100%;

    ${(props) =>
      props.isTablet &&
      props.isFoldersReceived &&
      css`
        padding-bottom: 25px;
      `}
    ${(props) =>
      props.isTablet &&
      !props.isFoldersReceived &&
      css`
        padding-bottom: 50px;
      `}
  `,

  EmptyFolders: styled(SuperEllipse)`
    border-radius: 30px;
    background-color: #161616;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;

    p {
      text-align: center;
      padding: 0 14%;
      font-size: 16px;
      color: #565656;
      font-weight: 500;
      line-height: 150%;
    }

    img {
      margin: 33px 0;
      width: calc(100% - 20%);
    }
  `,

  ColumnMemories: styled.section<{ isMemoriesReceived: boolean; isTablet: boolean }>`
    position: relative;
    padding: ${(props) => (props.isMemoriesReceived || props.isTablet ? '25px 0 0 0' : '25px 0 65px')};
    height: 100%;
    width: 100%;

    ${(props) =>
      !props.isMemoriesReceived &&
      props.isTablet &&
      css`
        padding: 25px 0 25px;
      `}
  `,

  ContentContainer: styled(ContentContainer)<{ scrollbarWidth: number }>`
    overflow-y: scroll;
    scrollbar-width: thin !important;

    height: calc(100vh - 111px);
    padding-right: calc(20px - ${(props) => props.scrollbarWidth}px);
    padding-bottom: 20px;
  `,

  LoaderScrollTop: styled(Loader)`
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translateX(-50%);
  `,
};
