export const PROMISES_AREA = {
  fetchCountry: 'fetch-country',
  fetchLifeScriptsData: 'fetch-life-scripts',
  fetchSharedLifeScript: 'fetch-shared-life-script',
  fetchSharedMemory: 'fetch-shared-memory',
  fetchFolders: 'fetch-folders',
  auth: 'auth',
  changeProfile: 'change-profile',
  verifyOTP: 'verify-OTP',
  fetchMemories: 'fetch-memories',
  fetchMemoriesScrollTop: 'fetch-memories-scroll-top',
  sendLifeScriptByEmail: 'send-life-script-by-email',
  logInExecutor: 'log-in-executor',
  logOut: 'log-out',
} as const;

type TKeys = keyof typeof PROMISES_AREA;
export type TPromisesArea = typeof PROMISES_AREA[TKeys];
