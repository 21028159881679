const mediaDisplay = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;
const mediaMobileDisplay = (minWidth: number) => `@media (min-width: ${minWidth}px)`;

const SIZE = {
  mobile: 480,
  tablet: 767,
  laptop: 991,
  smallDesktop: 1191,
  desktop: 1300,
  largeDesktop: 1400,
  bigDesktop: 1500,
};

export const media = {
  md: mediaDisplay,
  mmd: mediaMobileDisplay,

  md0: mediaDisplay(SIZE.largeDesktop),
  mmd0: mediaMobileDisplay(SIZE.largeDesktop),

  md1: mediaDisplay(SIZE.desktop),
  mmd1: mediaMobileDisplay(SIZE.desktop),

  md2: mediaDisplay(SIZE.laptop),
  mmd2: mediaMobileDisplay(SIZE.laptop),

  md3: mediaDisplay(SIZE.tablet),
  mmd3: mediaMobileDisplay(SIZE.tablet),

  md4: mediaDisplay(SIZE.mobile),
  mmd4: mediaMobileDisplay(SIZE.mobile),

  md5: mediaDisplay(SIZE.smallDesktop),
  mmd5: mediaMobileDisplay(SIZE.smallDesktop),

  md6: mediaDisplay(SIZE.bigDesktop),
  mmd6: mediaMobileDisplay(SIZE.bigDesktop),
};
