import { useEffect, useState } from 'react';
import { fuzzySearch, SelectSearchOption } from 'react-select-search';

import { fetchMemories } from '@components/memories/memories.constants';
import { IMemoriesPreview } from '@components/memories/memories.typings';
import { getErrorData } from '@constants/errors';
import { PROMISES_AREA } from '@constants/promises-area';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useDebounce } from '@hooks/useDebounce';
import { setActiveFolder, setMemoryIdScroll, setView } from '@store/reducers/app';
import {
    setIsNeedToReverseScroll, setMemories, setSearchedMemoryPage
} from '@store/reducers/life-scripts';
import { IMemoriesWithPagination } from 'typings/api.typings';
import { searchMemories } from './search-bar.api';
import { getPages } from './search-bar.constants';
import { ISearchOption } from './search-bar.typings';

export const useSearchBar = () => {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [isWithResult, setIsWithResult] = useState(false);
  const [isWithText, setIsWithText] = useState(false);
  const [optionsMemories, setOptionsMemories] = useState<SelectSearchOption[]>([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const { activeFolder, allLifeScriptsAndMemories, selectedView } = useAppSelector((store) => store.app);
  const { memories } = useAppSelector((store) => store.lifeScripts);
  const [isSearching, setIsSearching] = useState(false);
  const [isBlockedForNothingFound, setIsBlockedForNothingFound] = useState(true);

  const resetSearchBarState = () => {
    setIsFocus(false);
    setIsWithResult(false);
  };

  const fetchSearchMemories = async () => {
    setIsSearching(() => true);
    try {
      const res = await searchMemories({ searchKey: debouncedSearchTerm });
      const options = res.map((memory) => ({
        name: memory.title,
        value: memory.id,
        posterUrl: memory.posterUrl,
        lifeScriptId: memory.lifeScript.id,
      }));
      setOptionsMemories(options);
    } catch (error) {
      console.error(getErrorData(error));
    } finally {
      setIsSearching(() => false);
      setIsBlockedForNothingFound(() => false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsBlockedForNothingFound(() => true);
      fetchSearchMemories();
    }
  }, [debouncedSearchTerm]);

  const onSearchClick = () => setIsFocus(true);

  const onSearchedMemoryClick = (selectedMemory: ISearchOption) => async () => {
    resetSearchBarState();
    selectedView === 'table' && dispatch(setView('list'));
    const { calculatedMemoryPage, isFirstItem } = getPages(allLifeScriptsAndMemories, selectedMemory);

    dispatch(setSearchedMemoryPage(calculatedMemoryPage));

    const isCurrentSelectedFolder = selectedMemory.lifeScriptId === activeFolder.id;
    const isThisMemoryPageLoadedEarlier = calculatedMemoryPage <= +memories.meta.currentPage;

    if (isCurrentSelectedFolder && isThisMemoryPageLoadedEarlier) {
      return dispatch(setMemoryIdScroll(selectedMemory.value));
    }

    const receivedMemories = await fetchMemories(
      { page: calculatedMemoryPage, lifeScriptId: selectedMemory.lifeScriptId },
      PROMISES_AREA.fetchMemories
    );

    dispatch(
      setActiveFolder({
        id: selectedMemory.lifeScriptId,
        isAutoScroll: true,
      })
    );

    if (receivedMemories?.items.length) {
      setMemoriesToView(isFirstItem, calculatedMemoryPage, selectedMemory, receivedMemories);
    }
  };

  const setMemoriesToView = (
    isFirstItem: boolean,
    calculatedMemoryPage: number,
    selectedMemory: ISearchOption,
    receivedMemories: IMemoriesWithPagination<IMemoriesPreview>
  ) => {
    const memoriesInfo = {
      page: calculatedMemoryPage + 1,
      meta: receivedMemories.meta,
    };
    if (selectedMemory.lifeScriptId === activeFolder.id && calculatedMemoryPage - 1 === +memories.meta.currentPage) {
      dispatch(setIsNeedToReverseScroll(false));
      dispatch(
        setMemories({
          ...memoriesInfo,
          items: [...memories.items, ...receivedMemories.items],
        })
      );
    } else {
      dispatch(setIsNeedToReverseScroll(true));
      dispatch(
        setMemories({
          ...memoriesInfo,
          items: receivedMemories.items,
        })
      );
    }

    !isFirstItem && dispatch(setMemoryIdScroll(selectedMemory.value));
  };

  const filterByQuery = (options: SelectSearchOption[]) => {
    const filter = fuzzySearch(options);
    return (query: string) => {
      if (query && isFocus) {
        setSearchTerm(query);
        const filteredData = filter(query);
        setIsWithResult(!!filteredData.length);
        return filteredData;
      }
      setSearchTerm('');
      setIsWithText(!!query);
      setIsWithResult(false);
      return [];
    };
  };

  return {
    isBlockedForNothingFound,
    debouncedSearchTerm,
    isSearching,
    onSearchedMemoryClick,
    isFocus,
    isWithResult,
    isWithText,
    resetSearchBarState,
    onSearchClick,
    filterByQuery,
    optionsMemories,
    searchTerm,
  };
};
