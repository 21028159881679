import { Icon } from '@components/icon';
import { Modal } from '@components/modal';
import { appLinks } from './mobile-modal.constants';
import { StyledMobileModal as Styled } from './mobile-modal.styles';
import { IMobileModalProps } from './mobile-modal.typings';

export const MobileModal: React.FC<IMobileModalProps> = ({ onClose, text }) => {
  return (
    <Modal isBlackBackdrop={!onClose} onClose={onClose}>
      <Styled.MobileModal>
        {onClose && (
          <Styled.CloseModal onClick={onClose}>
            <Icon type="cross" />
          </Styled.CloseModal>
        )}
        {!onClose && <Styled.Title>Please visit us through the mobile app</Styled.Title>}
        <Styled.Logo />
        {text && <Styled.Title>{text}</Styled.Title>}

        <Styled.AppLinks>
          {appLinks.map((item) => (
            <a href={item.link} key={item.btnImg} target="_blank" rel="noreferrer">
              <img src={item.btnImg} alt={item.alt} />
            </a>
          ))}
        </Styled.AppLinks>
      </Styled.MobileModal>
    </Modal>
  );
};
