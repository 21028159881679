import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';

import { alertError, handleRequestError } from '@constants/alerts';
import { PROMISES_AREA } from '@constants/promises-area';
import { ROUTES } from '@constants/routes';
import { useAppDispatch } from '@hooks/redux';
import { sendExecutorCodeOTP } from '@screens/auth/auth.api';
import { getPhoneNumberWithoutSpace } from '@screens/auth/auth.constants';
import {
    logInExecutor
} from '@screens/auth/log-in/log-in-executor/executor-accounts/executor-accounts.api';
import {
    ILogInExecutorPayload, ILogInExecutorResponse
} from '@screens/auth/log-in/log-in-executor/log-in-executor.typings';
import { setToken, setUserInfo } from '@store/reducers/auth';
import { CodeInput } from './code-input';
import { verifyTemplate } from './code-input.constants';
import { useCodeInputContext } from './code-input.context';
import { ICodeInputLogIn } from './code-input.typings';

export const CodeInputLogInExecutor: React.FC<ICodeInputLogIn> = (props) => {
  const { setIsWaitingCode, phoneNumber } = props;
  const { handleError, codeState, disabledState } = useCodeInputContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const saveExecutor = ({ accessToken, refreshToken, user }: ILogInExecutorResponse) => {
    if (!accessToken || !refreshToken) {
      return alertError('Something wrong');
    }
    dispatch(setUserInfo({ ...user, isExecutor: true }));
    dispatch(setToken({ accessToken, refreshToken }));
  };

  const verifyOTP = async (code: string) => {
    if (!phoneNumber) {
      return;
    }

    verifyTemplate(
      phoneNumber,
      async () => {
        const phoneNumberWithoutSpace = getPhoneNumberWithoutSpace(phoneNumber);
        const receivedExecutorInfo = await trackPromise(
          sendExecutorCodeOTP({
            code,
            phoneNumber: phoneNumberWithoutSpace,
          }),
          PROMISES_AREA.verifyOTP
        );
        const usersListLength = receivedExecutorInfo?.allUsers.length;
        if (!usersListLength) {
          navigate(ROUTES.logInUser);
        } else if (usersListLength === 1) {
          const payload: ILogInExecutorPayload = {
            chosenUserId: receivedExecutorInfo.allUsers[0].id,
            phoneNumber: phoneNumberWithoutSpace,
          };
          try {
            const res = await trackPromise(logInExecutor(payload), PROMISES_AREA.verifyOTP);
            saveExecutor(res);
          } catch (error) {
            handleRequestError(error);
          }
        } else if (usersListLength > 1) {
          return navigate(ROUTES.logInExecutorChooseAccount, { state: receivedExecutorInfo });
        }
      },
      handleError
    );
  };

  return (
    <CodeInput
      setIsWaitingCode={setIsWaitingCode}
      verifyOTP={verifyOTP}
      codeState={codeState}
      disabledState={disabledState}
    />
  );
};
