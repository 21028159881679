import { useRef } from 'react';

import { Folder } from './folder';
import { StyledLifeScriptFolders as Styled } from './life-script-folders.styles';
import { ILifeScriptFoldersProps } from './life-script-folders.typings';

export const LifeScriptFolders: React.FC<ILifeScriptFoldersProps> = (props) => {
  const { folders } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Styled.LifeScriptFolders ref={containerRef}>
      <Styled.List>
        {folders.map((folder) => (
          <Folder key={folder.id} folder={folder} containerRef={containerRef} />
        ))}
      </Styled.List>
    </Styled.LifeScriptFolders>
  );
};
