import Select, { components, createFilter } from 'react-select';

import { Icon } from '@components/icon';
import { useDropdown } from './dropdown.state';
import { IDropdownProps } from './dropdown.typings';

export const Dropdown: React.FC<IDropdownProps> = (props) => {
  const { placeholder, value, onChange, icon, stylesConfig, ...restProps } = props;

  const { inputValue, onInputValueChange, handleChange, getValue } = useDropdown({
    value,
    onChange,
    options: props.options,
  });

  return (
    <Select
      {...restProps}
      onChange={handleChange}
      value={getValue()}
      filterOption={createFilter({ ignoreAccents: false })}
      styles={stylesConfig}
      components={{
        DropdownIndicator: (props) => (
          <components.DropdownIndicator {...props}>{icon && <Icon type={icon} />}</components.DropdownIndicator>
        ),
        IndicatorSeparator: () => null,
        ...props.components,
      }}
      placeholder={placeholder || ''}
      inputValue={inputValue}
      onInputChange={onInputValueChange}
      controlShouldRenderValue={true}
      closeMenuOnSelect={true}
      captureMenuScroll={false}
      isClearable={false}
    />
  );
};
