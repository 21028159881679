import styled, { css } from 'styled-components';

import { ErrorText } from '@styles/components/error-text';
import { media } from '@styles/media';
import { ITextFieldErrorProps, ITextFieldWrapperProps } from './text-field-form.typings';

export const StyledTextFieldForm = {
  FieldWrapper: styled.div<ITextFieldWrapperProps>`
    position: relative;
    width: 100%;
    height: 100%;
    text-align: left;

    & > input,
    & > div > input {
      transition: background-color ${(props) => props.theme.transition}, color ${(props) => props.theme.transition} !important;
      ${(props) =>
        props.isError &&
        css`
          color: ${(props) => props.theme.colors.danger};
          box-shadow: 0 0 0 2px ${(props) => props.theme.colors.danger};
        `}
    }
  `,

  Error: styled(ErrorText)<ITextFieldErrorProps>`
    opacity: ${(props) => (props.isFocus ? 1 : 0)};
    transition: opacity ${(props) => props.theme.transition}, visibility ${(props) => props.theme.transition};
    visibility: ${(props) => (props.isFocus ? 'visible' : 'hidden')};
    position: absolute;
    right: -25px;
    bottom: -8px;
    white-space: nowrap;

    ${media.md3} {
      right: 50%;
      transform: translateX(50%);
    }
  `,
};
