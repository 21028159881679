import styled from 'styled-components';

import { media } from '@styles/media';

export const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -20px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-weight: 700;

  h3 {
    margin-top: 10px;
    font-size: 35px;
  }

  h1 {
    margin-top: 10px;
    font-size: 50px;
    ${media.md4} {
      font-size: 35px;
    }
  }
`;
