import styled from 'styled-components';

import { media } from '@styles/media';

export const StyledMemoriesTableView = {
  MemoriesTableView: styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 20px;
    grid-auto-rows: minmax(150px, 270px);

    ${media.md1} {
      gap: 10px;
      grid-auto-rows: minmax(200px, 200px);
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  `,
};
