import { LINKS } from '@constants/links';

export const asideRoutes = [
  [
    {
      name: 'GDPR',
      route: LINKS.GDPR,
    },
    {
      name: 'Terms of Use',
      route: LINKS.termsOfUse,
    },
    {
      name: 'Privacy Policy',
      route: LINKS.privacyPolicy,
    },
    {
      name: 'Cookies',
      route: LINKS.cookies,
    },
  ],
  [
    {
      name: 'Timecapsule',
      route: LINKS.timecapsule,
    },
    {
      name: 'Functionality',
      route: LINKS.functionality,
    },
    {
      name: 'About Digilooms',
      route: LINKS.aboutDigilooms,
    },
    {
      name: 'Stories',
      route: LINKS.stories,
    },
    {
      name: 'Digi-know',
      route: LINKS.digiKnow,
    },
  ],
];
