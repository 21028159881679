import { Link } from 'react-router-dom';

import { AuthSocialButtons } from '@components/auth-social-buttons';
import { CodeInputLogInUser } from '@components/code-input/code-input-log-in-user';
import { Loader } from '@components/loader';
import { TextFieldPhoneForm } from '@components/text-fields/text-field-phone-form';
import { PROMISES_AREA } from '@constants/promises-area';
import { ROUTES } from '@constants/routes';
import { StyledAuth } from '@screens/auth/auth.styles';
import { useLogInUser } from './log-in-user.state';
import { StyledLogInUser as Styled } from './log-in-user.styles';

export const LogInUser: React.FC = () => {
  const { isWaitingCode, onLogInUser, setSelectedCountry, selectedCountry, userPhoneNumber, setIsWaitingCode } =
    useLogInUser();

  return (
    <Loader area={PROMISES_AREA.auth}>
      <Styled.Wrapper>
        {!isWaitingCode ? (
          <Styled.LogInUser>
            <Styled.PhoneNumberLogIn>
              <StyledAuth.Header>
                <h2>Log in</h2>
                <Link to={ROUTES.signUp}>
                  <StyledAuth.RedirectButton>Sign Up</StyledAuth.RedirectButton>
                </Link>
              </StyledAuth.Header>

              <Styled.PhoneNumber>Enter your phone number</Styled.PhoneNumber>
              <TextFieldPhoneForm
                isFlat
                maxMenuHeight={150}
                onSubmit={onLogInUser}
                onChangeCountry={setSelectedCountry}
                placeholder="Phone number"
                selectedCountry={selectedCountry}
              />
            </Styled.PhoneNumberLogIn>

            <AuthSocialButtons title="Sign In with social media" />
          </Styled.LogInUser>
        ) : (
          <CodeInputLogInUser
            phoneNumber={userPhoneNumber}
            setIsWaitingCode={setIsWaitingCode}
            selectedCountry={selectedCountry}
          />
        )}
      </Styled.Wrapper>
    </Loader>
  );
};
