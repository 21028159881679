import { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { ModeSwitcher } from '@components/mode-switcher';
import { checkIsRouteInvalid, ROUTES } from '@constants/routes';
import { useAuthContext } from '@hooks/useAuthContext';

const hideModeSwitcherPaths = [ROUTES.logInExecutorChooseAccount];

export const LogIn: React.FC = () => {
  const { pathname } = useLocation();
  const [isWaitingCode, setIsWaitingCode] = useState(false);
  const {
    country: [selectedCountry, setSelectedCountry],
  } = useAuthContext();

  const isHideModeSwitcher =
    !hideModeSwitcherPaths.includes(pathname as typeof hideModeSwitcherPaths[number]) && !isWaitingCode;

  return (
    <>
      {checkIsRouteInvalid(pathname, ROUTES.logIn) && <Navigate to={ROUTES.logInExecutor} replace />}
      {isHideModeSwitcher && <ModeSwitcher />}
      <Outlet context={{ country: [selectedCountry, setSelectedCountry], code: [isWaitingCode, setIsWaitingCode] }} />
    </>
  );
};
