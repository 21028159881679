import styled from 'styled-components';

export const StyledLifeScriptFolders = {
  LifeScriptFolders: styled.aside`
    overflow: hidden;
    max-width: 279px;
    height: calc(100vh - 195px);
    overflow: auto;
    scrollbar-width: thin !important;
  `,

  List: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 10px;
  `,
};
