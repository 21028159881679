import { useEffect, useRef, useState } from 'react';

import { fetchMemories } from '@components/memories/memories.constants';
import { PROMISES_AREA } from '@constants/promises-area';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { setActiveFolder } from '@store/reducers/app';
import { setMemories } from '@store/reducers/life-scripts';
import { TButtonEvent } from 'typings/common';
import { IFolderProps } from './folder.typings';

export const useFolder = ({ folder, containerRef }: IFolderProps) => {
  const { activeFolder } = useAppSelector((store) => store.app);
  const dispatch = useAppDispatch();
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const folderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    activeFolder.id === folder.id && executeScroll();
  }, []);

  useEffect(() => {
    if (activeFolder.isAutoScroll && activeFolder.id === folder.id) {
      executeScroll();
      dispatch(setActiveFolder({ id: folder.id, isAutoScroll: false }));
    }
  }, [activeFolder.isAutoScroll]);

  const executeScroll = () => {
    const container = containerRef.current!;
    const top = folderRef.current!.offsetTop - 111;
    container.scrollTo({ top });
  };

  const openShareModal = (e: TButtonEvent) => {
    e.stopPropagation();
    setIsOpenShareModal(true);
  };

  const closeShareModal = () => {
    setIsOpenShareModal(false);
  };

  const onFolderClick = (id: string) => async () => {
    let currentPayload;
    if (id === activeFolder.id) {
      currentPayload = { page: 1 };
      dispatch(setActiveFolder({ id: '', isAutoScroll: false }));
    } else {
      currentPayload = { page: 1, lifeScriptId: id };
      dispatch(setActiveFolder({ id, isAutoScroll: false }));
    }
    const res = await fetchMemories(currentPayload, PROMISES_AREA.fetchMemories);
    res &&
      dispatch(
        setMemories({
          page: 2, // default is 2, because first page is fetched
          items: res.items,
          meta: res?.meta,
        })
      );
  };

  return {
    folderRef,
    onFolderClick,
    activeFolder,
    isOpenShareModal,
    openShareModal,
    closeShareModal,
  };
};
