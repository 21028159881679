import styled from 'styled-components';

import { ContentContainer } from '@styles/components/container';
import { LifeScriptsLayout } from '@styles/components/life-scripts-layout';
import { media } from '@styles/media';

export const StyledProfileSettings = {
  ProfileSettings: styled(LifeScriptsLayout)`
    margin-top: 20px;
  `,

  ContentContainer: styled(ContentContainer)`
    display: flex;
    flex-direction: column;

    gap: 70px;

    ${media.md1} {
      gap: 40px;
    }
  `,

  FormContainer: styled.div`
    max-width: 324px;
  `,

  AsideColumn: styled.div`
    display: flex;
    justify-content: flex-end;
    button {
      margin-bottom: 20px;
    }
  `,

  Back: styled.div`
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;

    position: relative;

    button {
      fill: #fff;
      position: absolute;
      left: -35px;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
    }
  `,
};
