import styled from 'styled-components';

import { media } from '@styles/media';

export const ContainerWrapper = styled.div`
  height: 100%;
  display: flex;
`;

export const ContentContainer = styled.div`
  max-width: ${(props) => `${props.theme.maxWidthContentContainer + 40}px`};
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  ${media.md1} {
    max-width: 750px;
  }
  ${media.md5} {
    max-width: 550px;
  }
  ${media.md3} {
    max-width: none;
    padding: 0 10px;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 28px;

  ${media.md3} {
    padding: 0 10px;
  }
`;
