import { PinInput } from 'react-input-pin-code';

import { ButtonIcon } from '@components/buttons/button-icon';
import { Loader } from '@components/loader';
import { PROMISES_AREA } from '@constants/promises-area';
import { useCodeInput } from './code-input.state';
import { StyledCodeInput as Styled } from './code-input.styles';
import { ICodeInputProps } from './code-input.typings';

export const CodeInput: React.FC<ICodeInputProps> = (props) => {
  const { onGoBack, isDisabled, code, onChange } = useCodeInput(props);

  return (
    <Loader area={PROMISES_AREA.verifyOTP}>
      <Styled.Header>
        <ButtonIcon icon="arrowBack" onClick={onGoBack} />
        <h3>Enter Pin code</h3>
      </Styled.Header>
      <Styled.Wrapper>
        <PinInput
          showState={false}
          placeholder=""
          autoFocus
          disabled={isDisabled}
          required
          focusBorderColor="none"
          values={code}
          onChange={onChange}
        />
      </Styled.Wrapper>
    </Loader>
  );
};
