import styled from 'styled-components';

export const StyledAsideRoutes = {
  AsideRoutes: styled.aside<{ isMobile?: boolean }>`
    justify-self: end;
    margin-right: 25px;

    display: ${(props) => (props.isMobile ? 'block' : 'flex')};
    flex-direction: column;
    gap: 24px;
    max-width: 138px;
    align-items: flex-start;
    justify-content: flex-end;
    height: calc(100vh - 106px);
    width: 100%;
  `,

  Group: styled.div`
    justify-self: flex-end;
    align-self: flex-start;
    margin-bottom: 30px;
  `,

  Route: styled.div`
    cursor: pointer;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    color: #565656;
    text-align: left;
    transition: color ${(props) => props.theme.transition};

    &:hover {
      color: #fff;
      svg {
        fill: #fff;
      }
    }

    svg {
      transition: fill ${(props) => props.theme.transition};
      margin-left: 12px;
      fill: #565656;
    }
  `,

  Button: styled.button`
    font-size: 15px;
    letter-spacing: 0.01em;
    width: 138px;
    height: 65px;
    border-radius: 20px;
    background-color: #1e1e1e;
    color: #656565;
    transition: filter ${(props) => props.theme.transition};

    &:hover {
      filter: brightness(1.45);
    }
  `,
};
