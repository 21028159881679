import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useLocation } from 'react-router-dom';

import { PROMISES_AREA } from '@constants/promises-area';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useAuthContext } from '@hooks/useAuthContext';
import {
    checkIsUserExist, registerUser, sendOTP, updateUserForRegister
} from '@screens/auth/auth.api';
import { getPhoneNumberWithSpace, saveUser } from '@screens/auth/auth.constants';
import { clearSignUpSocial, logOut, setIsAllowEnter } from '@store/reducers/auth';
import { IUserInfo } from '@store/reducers/auth/auth.typings';
import { IUserResponse } from 'typings/api.typings';
import {
    getPayload, getSignUpInitialValues, handleOnSubmitError, validationSchema
} from './sign-up.constants';
import { IUserSignUp, IUserSignUpLocationProps } from './sign-up.typings';

export const useSignUp = () => {
  const locationState = useLocation() as IUserSignUpLocationProps;
  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
  const [isPolicyError, setIsPolicyError] = useState(false);
  const {
    country: [selectedCountry, setSelectedCountry],
  } = useAuthContext();
  const dispatch = useAppDispatch();
  const [isWaitingCode, setIsWaitingCode] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [avatarFile, setAvatarFile] = useState<File>();
  const { accessToken, refreshToken, signUpSocial } = useAppSelector((store) => store.auth);
  const [values, setValues] = useState<IUserSignUp>();
  const [isSocialSignUp, setIsSocialSignUp] = useState(false);
  const { isAllowEnter } = useAppSelector((store) => store.auth);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    return () => {
      if (!isAllowEnter) {
        dispatch(logOut());
      }
      dispatch(setIsAllowEnter(true));
    };
  }, []);

  const getFormDataForSocialSignUp = async (code?: string) => {
    const fd = await getPayload(values!, avatarFile!);
    if (code) {
      fd.append('code', code);
    }
    return fd;
  };

  const onSubmit = async (values: IUserSignUp) => {
    if (!isCheckBoxChecked) {
      return setIsPolicyError(true);
    }

    const phoneNumberWithCode = getPhoneNumberWithSpace(selectedCountry.label, values.phoneNumber);
    const signUpInfo: IUserSignUp = { ...values, phoneNumber: phoneNumberWithCode };
    setUserPhoneNumber(phoneNumberWithCode);
    setValues(signUpInfo);

    const area = PROMISES_AREA.auth;
    try {
      let user: IUserResponse;
      const payload = await getPayload(signUpInfo, avatarFile!);
      dispatch(setIsAllowEnter(false));
      if (!isSocialSignUp) {
        const rawUser = await trackPromise(checkIsUserExist({ phoneNumber: phoneNumberWithCode }), area);

        if (rawUser.isExist && !rawUser.isRegistered) {
          await trackPromise(sendOTP({ phoneNumber: phoneNumberWithCode }), area);
          setIsUpdate(true);
          return setIsWaitingCode(true);
        }

        user = await trackPromise(registerUser(payload), area);
        saveUser(user, dispatch);
        await trackPromise(sendOTP({ phoneNumber: phoneNumberWithCode }), area);
      } else {
        const userInfo: IUserInfo = await trackPromise(updateUserForRegister(payload), area);
        user = {
          accessToken,
          refreshToken,
          user: { ...userInfo, avatar: userInfo.avatar === 'undefined' ? '' : userInfo.avatar },
        };
        saveUser(user, dispatch);
      }
      setIsWaitingCode(true);
    } catch (error) {
      handleOnSubmitError(error, setIsWaitingCode);
    }
  };

  const form = useFormik<IUserSignUp>({
    initialValues: getSignUpInitialValues(signUpSocial, locationState),
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (signUpSocial?.firstName) {
      form.resetForm();
      const newInitialValues = getSignUpInitialValues(signUpSocial, locationState);
      Object.keys(newInitialValues).forEach((key) => {
        form.setFieldValue(key, newInitialValues[key as keyof IUserSignUp]);
      });
      setIsSocialSignUp(true);
      dispatch(clearSignUpSocial());
    }
  }, [signUpSocial]);

  const onAvatarChange = (image: string, imageFile: File) => {
    form.setFieldValue('avatar', image);
    setAvatarFile(imageFile);
  };

  const onCheck = () => {
    if (!isCheckBoxChecked) {
      setIsPolicyError(false);
    }
    setIsCheckBoxChecked(!isCheckBoxChecked);
  };

  return {
    form,
    isPolicyError,
    isCheckBoxChecked,
    selectedCountry,
    setSelectedCountry,
    onAvatarChange,
    onCheck,
    isWaitingCode,
    setIsWaitingCode,
    userPhoneNumber,
    getFormDataForSocialSignUp,
    isSocialSignUp,
    isUpdate,
  };
};
