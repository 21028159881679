import SuperEllipse from 'react-superellipse';

import { Icon } from '@components/icon';
import { Loader } from '@components/loader';
import { PROMISES_AREA } from '@constants/promises-area';
import { useExecutorAccounts } from './executor-accounts.state';
import { StyledExecutorAccounts as Styled } from './executor-accounts.styles';

export const ExecutorAccounts: React.FC = () => {
  const { isDataReceived, state, chooseUser } = useExecutorAccounts();

  return (
    <>
      {isDataReceived && (
        <>
          <Loader area={PROMISES_AREA.logInExecutor} />
          <Styled.AccountsHeader>Choose account you want to log in to</Styled.AccountsHeader>

          <Styled.AccountsList>
            {state.allUsers.map(({ firstName, lastName, id }) => (
              <SuperEllipse p1={1} p2={25} key={id} onClick={chooseUser(id)}>
                <li>
                  <span>
                    {firstName} {lastName}
                  </span>
                  <Icon type="forward" />
                </li>
                <hr />
              </SuperEllipse>
            ))}
          </Styled.AccountsList>
        </>
      )}
    </>
  );
};
