import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useLocation, useNavigate } from 'react-router-dom';

import countries from '@constants/countries.json';
import { PROMISES_AREA } from '@constants/promises-area';
import { ROUTES } from '@constants/routes';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useAuth as useAuthentication } from '@hooks/useAuth';
import { logOut } from '@store/reducers/auth';
import { IOption } from 'typings/common';
import { getCountry } from './auth.api';

export const useAuth = () => {
  const [selectedCountry, setSelectedCountry] = useState<IOption>();
  const { pathname } = useLocation();
  const { isAllowEnter } = useAppSelector((store) => store.auth);
  const { isAuth } = useAuthentication();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logOut());
  }, []);

  useEffect(() => {
    if (isAuth && isAllowEnter) {
      const isNewUser = pathname === ROUTES.signUp;
      const isExecutor = pathname === ROUTES.logInExecutor || pathname === ROUTES.logInExecutorChooseAccount;

      const state =
        isNewUser || isExecutor
          ? {
              isNewUser,
              isExecutor,
            }
          : null;

      navigate(ROUTES.lifeScripts, { state });
    }
  }, [isAuth, isAllowEnter]);

  useEffect(() => {
    const getDefaultCountry = async () => {
      let option;
      try {
        const res = await trackPromise(getCountry(), PROMISES_AREA.fetchCountry);
        option = countries.find((country) => country.code === (res?.country_code || 'S'))!;
      } catch (error) {
        console.error(error);
        option = countries.find((country) => country.code === 'S')!;
      }
      setSelectedCountry({
        label: option?.key,
        value: option?.text,
      });
    };
    getDefaultCountry();
  }, []);

  return {
    pathname,
    selectedCountry,
    setSelectedCountry,
  };
};
