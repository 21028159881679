import { Icon } from '@components/icon';
import { Modal } from '@components/modal/modal';
import { useAppSelector } from '@hooks/redux';
import { StyledModalExecutor as Styled } from './modal-executor.styles';
import { IModalExecutorProps } from './modal-executor.typings';

export const ModalExecutor: React.FC<IModalExecutorProps> = ({ onClose }) => {
  const { firstName, lastName } = useAppSelector((store) => store.auth.user);

  const name = `${firstName} ${lastName}`;
  return (
    <Modal onClose={onClose}>
      <Styled.ModalExecutor>
        <Styled.CloseModal onClick={onClose}>
          <Icon type="cross" />
        </Styled.CloseModal>
        <Styled.Logo />
        <Styled.Text>
          <p>
            You are now signed in as the Executor / TimeCapsule Manager for <span>{name}</span>
          </p>
          <p>Please share the LifeScripts to the various recipients as per your instructions and responsibilities.</p>
          <p>Thank you for helping Digilooms create a great legacy for future generations.</p>
        </Styled.Text>
      </Styled.ModalExecutor>
    </Modal>
  );
};
