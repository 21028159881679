import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';

import { alertInfo, handleRequestError } from '@constants/alerts';
import { getErrorData } from '@constants/errors';
import { PROMISES_AREA } from '@constants/promises-area';
import { ROUTES } from '@constants/routes';
import { useAppDispatch } from '@hooks/redux';
import { sendCodeOTP, updateUser, updateUserForRegister } from '@screens/auth/auth.api';
import { saveUser } from '@screens/auth/auth.constants';
import { HELP_CENTER } from '@screens/auth/sign-up/sign-up.constants';
import { setIsAllowEnter, setToken, setUserInfo } from '@store/reducers/auth';
import { IUserResponse } from 'typings/api.typings';
import { CodeInput } from './code-input';
import { verifyTemplate } from './code-input.constants';
import { useCodeInputContext } from './code-input.context';
import { ICodeInputSignUpProps } from './code-input.typings';

export const CodeInputSignUp: React.FC<ICodeInputSignUpProps> = (props) => {
  const { setIsWaitingCode, phoneNumber, isSocialSignUp, isUpdate, getFormDataForSocialSignUp } = props;
  const { handleError, codeState, disabledState } = useCodeInputContext();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onComplete = async () => {
    try {
      const user = await updateUser();
      dispatch(setUserInfo(user));
      dispatch(setIsAllowEnter(true)); //trigger navigate to ROUTES.lifeScripts
    } catch (error) {
      const { message, code } = getErrorData(error);
      if (code === 403 && message === HELP_CENTER) {
        return alertInfo(message);
      }
      handleRequestError(error);
    }
  };

  const verifyForSocialSignUp = async (code: string) => {
    const fd = await getFormDataForSocialSignUp(code);
    const userInfo = await updateUserForRegister(fd);
    const newUser = {
      ...userInfo,
      avatar: userInfo.avatar === 'undefined' ? '' : userInfo.avatar,
    };
    dispatch(setIsAllowEnter(true));
    dispatch(setUserInfo(newUser));
    navigate(ROUTES.lifeScripts, { state: { isNewUser: true } });
  };

  const verifyForUpdate = async (user: IUserResponse) => {
    dispatch(
      setToken({
        accessToken: user.accessToken,
        refreshToken: user.refreshToken,
      })
    );
    const fd = await getFormDataForSocialSignUp();
    fd.delete('phoneNumber');
    const userInfo = await updateUserForRegister(fd);
    const newUser = {
      ...userInfo,
      avatar: userInfo.avatar === 'undefined' ? '' : userInfo.avatar,
    };
    const userWithTokens: IUserResponse = {
      accessToken: user.accessToken,
      refreshToken: user.refreshToken,
      user: newUser,
    };
    saveUser(userWithTokens, dispatch);
    navigate(ROUTES.lifeScripts, { state: { isNewUser: true } });
  };

  const verifyOTP = async (fullCode: string) => {
    verifyTemplate(
      phoneNumber,
      async () => {
        let receivedUserInfo: IUserResponse;
        if (isSocialSignUp) {
          await trackPromise(verifyForSocialSignUp(fullCode), PROMISES_AREA.verifyOTP);
        } else if (isUpdate) {
          receivedUserInfo = await trackPromise(
            sendCodeOTP({
              code: fullCode,
              phoneNumber,
            }),
            PROMISES_AREA.verifyOTP
          );

          await trackPromise(verifyForUpdate(receivedUserInfo), PROMISES_AREA.verifyOTP);
        } else {
          receivedUserInfo = await trackPromise(
            sendCodeOTP({
              code: fullCode,
              phoneNumber,
            }),
            PROMISES_AREA.verifyOTP
          );

          const isRegistered = receivedUserInfo.user.isRegistered;

          if (!isRegistered) {
            return onComplete();
          }
          saveUser(receivedUserInfo, dispatch);
          onComplete();
        }
      },
      handleError
    );
  };

  return (
    <CodeInput
      setIsWaitingCode={setIsWaitingCode}
      verifyOTP={verifyOTP}
      codeState={codeState}
      disabledState={disabledState}
    />
  );
};
