// firebase
export const SOCIAL_AUTH_API_KEY = process.env.REACT_APP_SOCIAL_AUTH_API_KEY;
export const SOCIAL_AUTH_APP_ID = process.env.REACT_APP_SOCIAL_AUTH_APP_ID;
export const SOCIAL_AUTH_DATABASE_URL = process.env.REACT_APP_SOCIAL_AUTH_DATABASE_URL;
export const SOCIAL_AUTH_PROJECT_ID = process.env.REACT_APP_SOCIAL_AUTH_PROJECT_ID;
export const SOCIAL_AUTH_STORAGE_BUCKET = process.env.REACT_APP_SOCIAL_AUTH_STORAGE_BUCKET;
export const SOCIAL_AUTH_DOMAIN = process.env.REACT_APP_SOCIAL_AUTH_DOMAIN;

const API_HASH_MAP: Record<string, string> = {
  prod: process.env.REACT_APP_API_URL_PROD!,
  stage: process.env.REACT_APP_API_URL_STAGE!,
  dev: process.env.REACT_APP_API_URL_STAGE!,
};

const ENV = process.env.REACT_APP_ENV || 'dev';

export const CONFIG = {
  apiUrl: API_HASH_MAP[ENV],
  baseUrl: location.protocol + '//' + location.host,
};
