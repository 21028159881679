import styled, { css } from 'styled-components';

import { ILoaderContainerProps } from './loader.typings';

export const CenteredLoader = styled.div<ILoaderContainerProps>`
  position: fixed;
  z-index: 5000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);

  svg {
    z-index: 15001;
    opacity: 1;
    width: ${(props) => props.size || 50}px;
    height: ${(props) => props.size || 50}px;
  }
`;

export const StyledChildren = styled.div`
  user-select: none;
`;

export const StyledIcon = styled.div<ILoaderContainerProps>`
  ${(props) =>
    !props.isWithoutMargin &&
    css`
      margin: 15px auto;
    `}
  &,
  svg {
    width: ${(props) => props.size || 50}px;
    height: ${(props) => props.size || 50}px;
  }
`;
