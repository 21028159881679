import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Icon } from '@components/icon';
import { MobileModal } from '@components/mobile-modal';
import { LINKS } from '@constants/links';
import { checkIsRouteInvalid, ROUTES } from '@constants/routes';
import { ContentContainer } from '@styles/components/container';
import { StyledShared as Styled } from './shared.styles';

export const Shared: React.FC = () => {
  const [name, setName] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { pathname } = useLocation();

  const onModalClose = () => {
    setIsOpenModal(false);
  };

  const onModalOpen = () => {
    setIsOpenModal(true);
  };

  useEffect(() => {
    document.body.style.minWidth = 'auto';
    return () => {
      document.body.style.minWidth = '650px';
    };
  }, []);

  return (
    <>
      {checkIsRouteInvalid(pathname, ROUTES.shared) && <Navigate to={ROUTES.lifeScripts} replace />}

      {isOpenModal && <MobileModal onClose={onModalClose} />}
      <ContentContainer>
        {name && (
          <Styled.Header>
            <Styled.Logo isClickable isWithText wight={30} height={35} />

            <Styled.ButtonsWrapper>
              <Styled.Link href={LINKS.aboutDigilooms} target="_blank">
                <Icon type="info" />
                Learn About Digilooms
              </Styled.Link>
              <Styled.Button onClick={onModalOpen}>Get Digilooms app</Styled.Button>
            </Styled.ButtonsWrapper>
          </Styled.Header>
        )}

        <Outlet context={{ name, setName }} />

        {name && (
          <Styled.SharedBy>
            Shared by <span>{name}</span>
          </Styled.SharedBy>
        )}
      </ContentContainer>
    </>
  );
};
