import { useEffect, useRef, useState } from 'react';

import {
    TNewRecipient
} from '@components/share/share-life-script-modal/share-life-script-modal.typings';
import { TInputEvent } from 'typings/common';
import { IRecipientsInformationModalProps } from './recipients-information-modal.typings';

export const useRecipientsInformationModal = ({
  recipients,
  setRecipients,
  onBack,
}: IRecipientsInformationModalProps) => {
  const [tempRecipients, setTempRecipients] = useState<TNewRecipient[]>(recipients);
  const checkEverySelected = () => tempRecipients.every((recipient) => recipient.isActive);
  const [isEveryoneSelected, setIsEveryoneSelected] = useState(checkEverySelected());
  const [isScrollVisible, setIsScrollVisible] = useState(false);
  const ref = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const container = ref.current;
    if (container!.scrollHeight! > container!.clientHeight!) {
      setIsScrollVisible(true);
    }
  }, [ref]);

  useEffect(() => {
    setIsEveryoneSelected(checkEverySelected());
  }, [tempRecipients]);

  const toggleEveryone = () => {
    setIsEveryoneSelected(!isEveryoneSelected);
    setTempRecipients(recipients.map((recipient) => ({ ...recipient, isActive: !isEveryoneSelected })));
  };

  const handleCheck = (e: TInputEvent) => {
    setTempRecipients((s) => [
      ...s.map((option) => {
        if (option.id === e.target.value) {
          return {
            ...option,
            isActive: !option.isActive,
          };
        }
        return option;
      }),
    ]);
  };

  const onApply = () => {
    setRecipients(tempRecipients);
    onBack();
  };

  return {
    tempRecipients,
    isEveryoneSelected,
    toggleEveryone,
    handleCheck,
    onApply,
    ref,
    isScrollVisible,
  };
};
