import { useState } from 'react';

import { handleCodeInputRequestError } from '@constants/alerts';
import { codeInitialValues } from './code-input.constants';

export const useCodeInputContext = () => {
  const codeState = useState(codeInitialValues);
  const disabledState = useState(false);

  const handleError = (error: unknown) => {
    handleCodeInputRequestError(error, { autoClose: 2000 });
    disabledState[1](false);
    codeState[1](codeInitialValues);
  };

  return { handleError, codeState, disabledState };
};
